import { Button, Divider, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import UpgradeBusinessDialog from "../../Components/Dialogs/UpgradeBusinessDialog";
import { useUiContext } from "../../Contexts/UiContext";
import { getUserRemainingBalance } from "../../store/actions/bankingActions";
import { getAllPackages } from "../../store/actions/businessActions";
import { API_BASE } from "../../store/env";
import PaymentResult from "../../util/banking/PaymentResult";
import MakePaymentDialog from "../Invoice/MakePaymentDialog";
import AgreementDialog from "./AgreementDialog";
import "./plans.css";

const PlansCardContainer = ({ business }) => {
  let location = useLocation();
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { setErrors, errors, clearErrors } = useUiContext();

  const allPackages = useSelector((state) => state.business.packages);

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [makePaymentDialogOpen, setMakePaymentDialogOpen] = useState(false);
  const [openUpgradeBusiness, setOpenUpgradeBusinessDialog] = useState(false);
  const [upgradeLink, setUpgradeLink] = useState("");

  const [agreementDialogOpen, setAgreementDialogOpen] = useState(false);
  const [conditionsAccepted, setConditionsAccepted] = useState(false);


  const [isLoading, setIsLoading] = useState(false);
  // const [dashboardType, setDashboardType] = useState(null);
  let dashboardType = localStorage.getItem("dashboardType");



  useEffect(() => {
    if (true) {
      dispatch(getAllPackages(dashboardType));
    }
    // eslint-disable-next-line
  }, [dashboardType]);


  const [packId, setPackId] = useState("");
  const [packType, setPackType] = useState("");

  const handleBuyPackage = async (id, pkType) => {
    if (pkType === "business_membership" || pkType === "transfer_money_membership") {
      setAgreementDialogOpen(true);
      setPackId(id);
      setPackType(pkType);
    } else {
      makePayment(id, pkType);
    }
  };

  useEffect(() => {
    if (conditionsAccepted) {
      setAgreementDialogOpen(false);
      makePayment(packId, packType);
    }
    // eslint-disable-next-line
  }, [conditionsAccepted]);


  const makePayment = async (id, pkType) => {
    let accountType = business.account_type;

    let isPaymentReceivedVerified = business.canReceivePayment;
    let isTransferMoneyVerified = business.canTransferMoney;

    let isAwaitingReceivePayment = business.awaitingReceivePaymentApproval;
    let isAwaitingTransferMoney = business.awaitingTransferMoneyApproval;

    if (pkType === "business_membership") {
      if (accountType === "Business" && !isPaymentReceivedVerified && !isAwaitingReceivePayment) {
        setOpenUpgradeBusinessDialog(true);
        setUpgradeLink("/dashboard/update-business?type=receive_payment");
        return false;
      }
    }

    if (pkType === "transfer_money_membership") {
      if (accountType === "Personal" && !isTransferMoneyVerified && !isAwaitingTransferMoney) {
        setOpenUpgradeBusinessDialog(true);
        setUpgradeLink("/dashboard/update-business?type=transfer_money");
        return false;
      }
    }

    if (conditionsAccepted || pkType === "payment_token" || pkType === "transfer_money_token") {
      try {
        setIsLoading(true);
        let response = await axios({
          method: "post",
          url: `${API_BASE}/v1/package`,
          data: { package_id: id },
        });
        if (response.data.payment_uri) {
          window.location.href = response.data.payment_uri;

          setIsLoading(false);

          setPackId(id);
        }
      } catch (error) {
        setIsLoading(false);

        setErrors({ general: error?.response?.data?.message });
      }
    }
  };


  const renderMembership = (pk, i) => {
    return (
      <div>
        {i === 0 && (
          <Grid item xs={12} lg={3} md={3} style={{ padding: "40px" }} class="pricingGrid">
            <ul class="pricing p-green">
              <li>
                <big>{pk.package_name}</big>
              </li>
              {pk.package_features && (
                <>
                  {pk.package_features.map((p) => {
                    return (
                      <>
                        <li>{p}</li>
                      </>
                    );
                  })}
                </>
              )}
              <li>
                <strong>UNLIMITED</strong> Invoices upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Customer upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Suppliers upload
              </li>

              <li>
                <h3>GBP {pk.package_fee}</h3>
                <span>per 90 days</span>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleBuyPackage(pk.id, pk.package_type);
                  }}
                >
                  Get Now
                </button>
              </li>
            </ul>
          </Grid>
        )}
        {i === 1 && (
          <Grid item xs={12} lg={3} md={3} style={{ padding: "40px" }} class="pricingGrid">
            <ul class="pricing p-yel">
              <li>
                <big>{pk.package_name}</big>
              </li>
              {pk.package_features && (
                <>
                  {pk.package_features.map((p) => {
                    return (
                      <>
                        <li>{p}</li>
                      </>
                    );
                  })}
                </>
              )}
              <li>
                <strong>UNLIMITED</strong> Invoices upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Customer upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Suppliers upload
              </li>

              <li>
                <h3>GBP {pk.package_fee}</h3>
                <span>per 90 days</span>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleBuyPackage(pk.id, pk.package_type);
                  }}
                >
                  Get Now
                </button>
              </li>
            </ul>
          </Grid>
        )}
        {i === 2 && (
          <Grid item xs={12} lg={3} md={3} style={{ padding: "40px" }} class="pricingGrid">
            <ul class="pricing p-red">
              <li>
                <big>{pk.package_name}</big>
              </li>
              {pk.package_features && (
                <>
                  {pk.package_features.map((p) => {
                    return (
                      <>
                        <li>{p}</li>
                      </>
                    );
                  })}
                </>
              )}
              <li>
                <strong>UNLIMITED</strong> Invoices upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Customer upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Suppliers upload
              </li>

              <li>
                <h3>GBP {pk.package_fee}</h3>
                <span>per 90 days</span>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleBuyPackage(pk.id, pk.package_type);
                  }}
                >
                  Get Now
                </button>
              </li>
            </ul>
          </Grid>
        )}
      </div>
    );
  };

  return (
    <div>
      {isLoading ? (
        "Loading"
      ) : (
        <>
          {allPackages?.data && allPackages.data.length === 0 && (
            <>
              <Typography>No suitable package found</Typography>
            </>
          )}
        </>
      )}
      <Grid container spacing={3} style={{ justifyContent: "center" }}>
        {allPackages?.data && allPackages.data.length > 0 && (
          <>
            {allPackages.data.map((pk, i) => {
              return renderMembership(pk, i);
            })}
          </>
        )}
      </Grid>
      <AgreementDialog open={agreementDialogOpen} setOpen={setAgreementDialogOpen} conditionsAccepted={conditionsAccepted} setConditionsAccepted={setConditionsAccepted} />
      {/* {selectedInvoices.length > 0 && makePaymentDialogOpen && (
        <MakePaymentDialog
          open={makePaymentDialogOpen}
          setOpen={setMakePaymentDialogOpen}
          selectedInvoices={selectedInvoices}
          errors={errors}
          setErrors={setErrors}
          clearErrors={clearErrors}
          redirectURI="payment-result/plans"
          payeda_package_id={packId}
        />
      )} */}

      <UpgradeBusinessDialog open={openUpgradeBusiness} setOpen={setOpenUpgradeBusinessDialog} upgradeLink={upgradeLink} />
    </div>
  );
};

export default PlansCardContainer;
