import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../Components/BasePage";
import ConfirmDeleteDialog from "../../Components/Dialogs/ConfirmDeleteDialog";
import { createMember, editMember, getMembers, removeMembers } from "../../store/actions/memberActions";
import { useUiContext } from "./../../Contexts/UiContext";
import MembersDialog from "./MembersDialog";
import MembersTable from "./MembersTable";

const Members = ({ business }) => {
  const dispatch = useDispatch();
  const members = useSelector((state) => state.member);
  console.log("mwmvwea", members)
  const isLastPage = useSelector((state) => state.business.lastPage);
  const user = useSelector((state) => state.user);
  const { errors } = useUiContext();
  const [selected, setSelected] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [afterRender, setAfterRender] = useState(false);

  const onDelete = async () => {
    let membersToDelete = selected.map((member) => member.id);
    let details = { members: membersToDelete };
    dispatch(removeMembers(details));
    setSelected([]);
    setDeleteDialogOpen(false);
  };
  return (
    <BasePage navigation="Dashboard/Members" business={business} canView={user?.permissions?.member?.view}>
      <MembersTable
        members={members?.members?.data || []}
        isLoading={members.isLoading}
        isLastPage={isLastPage}
        getAllData={getMembers}
        setCreateDialogOpen={setCreateDialogOpen}
        setEditDialogOpen={setEditDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        selected={selected}
        setSelected={setSelected}
        numberOfPages={members?.members?.number_of_page || 0}
      />

      <MembersDialog
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
        title="Create new member"
        errors={errors}
        createMember={createMember}
        accountType={user["team_type"]}
        user={user}
        addOrEdit="add"
      />

      <MembersDialog
        open={editDialogOpen}
        setOpen={setEditDialogOpen}
        title="Edit member"
        description="Edit details of the member."
        errors={errors}
        editMember={editMember}
        data={selected[0]}
        selected={selected}
        setSelected={setSelected}
        addOrEdit="edit"
        accountType={user["team_type"]}
      />

      <ConfirmDeleteDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onSubmit={onDelete}
        deleteHead="Are you sure to inactive user?"
        deleteText="You can activate later"
      />
    </BasePage>
  );
};

export default Members;
