import axios from "axios";
import { API_BASE } from "../env";
import { setSuccess, setErrors, clearErrors } from "./uiActions";

export const createMember = (memberData) => async (dispatch) => {
  try {
    //TODO After creating new member we should get all the members and set it in reducer?
    await axios.post(`${API_BASE}/v1/member`, memberData);
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully added new member."));
    dispatch(getMembers({}));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getMembers = ({ ...params }) => async (dispatch) => {
  try {
    dispatch({ type: "MEMBER_DATA_FETCH_STARTED" });

    let members = await axios.get(`${API_BASE}/v1/member`, {
      params: {
        perPage: params.perPage ? params.perPage : 10,
        page: params.page ? params.page : 1,
        searchField: params.search ? params.search : ""
      }
    });
    dispatch({ type: "MEMBER_DATA_FETCH_SUCCESS", payload: members?.data });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const editMember = (details) => async (dispatch) => {
  try {
    await axios({
      method: "put",
      url: `${API_BASE}/v1/member`,
      data: details,
    });
    dispatch({ type: "CLEAR_ERRORS" });
    dispatch({
      type: "SET_SUCCESS",
      payload: "Successfully edited member.",
    });
    dispatch(getMembers({}));
  } catch (err) {
    if (err.response) {
      dispatch({
        type: "SET_ERRORS",
        payload: err.response.data,
      });
    } else {
      dispatch({
        type: "SET_ERRORS",
        payload: { general: "Something unexpected happened." },
      });
    }
  }
};

export const removeMembers = (members) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/members/remove`, members);
    dispatch({ type: "CLEAR_ERRORS" });
    dispatch({
      type: "SET_SUCCESS",
      payload: "Successfully deleted member.",
    });
    dispatch(getMembers());
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch({
        type: "SET_ERRORS",
        payload: err.response.data,
      });
    } else {
      dispatch({
        type: "SET_ERRORS",
        payload: { general: "Something unexpected happened." },
      });
    }
  }
};
