import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const AddInvoiceLineComponent = ({
  errors,
  loading,
  onInvoiceLineAdd,
  invoiceLines,
  setInvoiceLines,
  totalAmount,
  totalvat_percentage,
  totalDiscount,
  products,
  isLoading,
}) => {
  const { register, control, watch, handleSubmit, setValue } = useForm();

  const watchProduct = watch("product");
  const watchQuantity = watch("product_quantity");
  const watchDiscount = watch("rate_of_discount");

  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [lineTotalAmount, setLineTotalAmount] = useState(0);
  const [lineVatAmount, setLineVatAmount] = useState(0);
  const [productName, setProductName] = useState("");
  const [lineUnitPrice, setLineUnitPrice] = useState(0);
  const [lineDiscountAmount, setLineDiscountAmount] = useState(0);
  const [lineVatPercentage, setLineVatPercentage] = useState(0);

  //run once to setup initial values
  useEffect(() => {
    setValue("product_quantity", 1);
    setValue("rate_of_discount", 0);
    setValue("discount_amount", 0);
    setValue("unit_price", 0);
  }, [watchProduct, products, setValue]);

  useEffect(() => {
    let selectedProduct;

    if (products && watchProduct) {
      selectedProduct =
        products && products.data.find((x) => x.id === watchProduct.id);
    }

    if (selectedProduct) {
      console.log("        selectedProduct.unit_price", selectedProduct.unit_price)


      let quantity = Number(watchQuantity);
      console.log("quqnaity", quantity);
      let vatPercentage = parseFloat(selectedProduct.vat_percentage);
      let discount_percentage = parseFloat(watchDiscount);

      let unitPrice = parseFloat(selectedProduct.unit_price.toString().replace(/,/g, "")).toFixed(2);
      const line_total_amount = quantity * unitPrice;


      // Subtotal amount (VAT exclusive)
      const subtotal_amount = line_total_amount;

      // Line vat amount
      const line_vat_amount = (subtotal_amount * vatPercentage) / 100;

      const vat_percentage = vatPercentage;

      // Subtotal amount (VAT inclusive)
      const subtotal_inclusive_amount = subtotal_amount + line_vat_amount;


      setLineVatAmount(line_vat_amount);
      setLineVatPercentage(vat_percentage);
      setSubTotalAmount(subtotal_amount);

      setLineTotalAmount(subtotal_inclusive_amount);
      // setLineDiscountAmount(discount_amount);
      setLineUnitPrice(unitPrice);
      setValue("unit_price", unitPrice);
      setValue("vat_percentage", vatPercentage);
      setProductName(selectedProduct.product_name);
    }
    // eslint-disable-next-line
  }, [
    watchProduct,
    products,
    watchDiscount,
    watchQuantity,
    subTotalAmount,
    lineUnitPrice,
  ]);

  const handLineAadd = (data) => {

    let lineToAdd = {
      subtotal_amount: subTotalAmount,
      product: productName,
      product_quantity: watchQuantity,
      unit_price: lineUnitPrice,
      line_vat_amount: lineVatAmount,
      line_total_amount: lineTotalAmount,
      line_discount_amount: lineDiscountAmount,
      line_vat_percentage: lineVatPercentage
    };
    console.log("line to add", lineToAdd);
    onInvoiceLineAdd(lineToAdd);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={3}>
        {/** TODO: paginate products here as well*/}
        {products?.data && (
          <Controller
            render={(props) => (
              <Autocomplete
                {...props}
                options={products.data}
                getOptionLabel={(option) => option.product_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a product"
                    variant="outlined"
                  />
                )}
                onChange={(_, data) => props.onChange(data)}
              />
            )}
            name="product"
            id="product"
            control={control}
          />
        )}
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <TextField
          required
          fullWidth
          id="product_quantity"
          name="product_quantity"
          label="Quantity"
          error={errors.product_quantity ? true : false}
          helperText={errors.product_quantity ?? false}
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <CurrencyTextField
          label="Unit Price"
          id="unit_price"
          name="unit_price"
          variant="standard"
          value={lineUnitPrice}
          currencySymbol="£"
          outputFormat="string"
          inputProps={{ ref: register }}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      {/* <Grid item xs={12} md={2} lg={2}>
        <TextField
          fullWidth
          id="rate_of_discount"
          name="rate_of_discount"
          label="Discount"
          error={errors.rate_of_discount ?? false}
          helperText={errors.rate_of_discount ?? false}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
        />
      </Grid> */}

      <Grid item xs={12} md={3} lg={3}>
        <TextField
          fullWidth
          id="vat_percentage"
          name="vat_percentage"
          label="VAT Percentage"
          error={errors.vat_percentage ?? false}
          helperText={errors.vat_percentage ?? false}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            readOnly: true,
          }}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
        />
      </Grid>

      <Button
        variant="outlined"
        color="secondary"
        style={{ margin: 20 }}
        onClick={handleSubmit(handLineAadd)}
        fullWidth
      >
        Add Invoice Line
      </Button>
    </Grid>
  );
};

export default AddInvoiceLineComponent;
