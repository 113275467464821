import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import BasePage from "../../../../Components/BasePage";
import TableComponent from "../../../../Components/Table/TableComponent";
import { exchanceCodeForTrueLayerToken, getBankAuthUrl, getSelfBankAccounts, setReceivePaymentAccount } from "../../../../store/actions/bankingActions";
import { getBusinessDetails, getBusinessStat } from "../../../../store/actions/businessActions";
import { useUiContext } from "./../../../../Contexts/UiContext";
import AddBankAccountDialog from "./AddBankAccountDialog";
import BankAccountsToolbar from "./BankAccountsToolbar";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";


const headCells = [
  { id: "provider_name", label: "Provider" },
  { id: "account_type", label: "Account Type" },
  { id: "display_name", label: "Account Name" },
  { id: "account_number", label: "Account Number" },
  { id: "currency", label: "Currency" },
  { id: "balance", label: "Balance" },
  { id: "overdraft", label: "Overdraft" },
];

const BankAccounts = ({ history }) => {
  const { errors } = useUiContext();
  let [addBankAccountDialogOpen, setAddBankAccountDialogOpen] = useState(false);
  let [transactionDateDialogOpen, setTransactionDateDialogOpen] = useState(false);

  let [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      dispatch(getSelfBankAccounts());
    }
    // eslint-disable-next-line
  }, [afterRender]);

  const bankAccounts = useSelector((state) => state.banking.bankAccounts);
  console.log("bankKKKKKKKAcocunt", bankAccounts);
  const isLoading = useSelector((state) => state.banking.isLoading);
  const authUrl = useSelector((state) => state.banking.authUrl);
  const authUrlLoading = useSelector((state) => state.banking.authUrlLoading);
  const business = useSelector((state) => state.business);
  console.log("business", business);
  let bankingLoading = useSelector((state) => state.banking.isLoading);
  const user = useSelector((state) => state.user);

  const onSubmit = () => {
    let businessBankingInfo = business.company_details.banking[0];
    let selectedBankingInfo = selected[0];

    if (businessBankingInfo.account_number === selectedBankingInfo.account_number) {
      if (businessBankingInfo.sort_code === selectedBankingInfo.sort_code.replace("-", "")) {
        alert("here");
        dispatch(setReceivePaymentAccount(selected[0]));
      }
    } else {
      alert("The provided informations are not matching in our system!");
    }
  };

  const renderToolbar = () => {
    return (
      <BankAccountsToolbar
        setAddBankAccountDialogOpen={setAddBankAccountDialogOpen}
        addBankAccountDialogOpen={addBankAccountDialogOpen}
        setTransactionDateDialogOpen={setTransactionDateDialogOpen}
        transactionDateDialogOpen={transactionDateDialogOpen}
        history={history}
        onSubmit={onSubmit}
        selected={selected}
        business={business}
      />
    );
  };

  const renderConnectedAccounts = () => {
    return (
      <>{bankAccounts?.data && bankAccounts?.data.length > 0 && (
        <>      <FormControl component="fieldset">
          <FormLabel component="legend">Choose your favorite erp application</FormLabel>
          <RadioGroup aria-label="erp" name="gender1" style={{ marginTop: "15px" }}>
            <Grid container spacing={0}>
              <Grid item xs={12} lg={3}>
                <div style={{ marginTop: "50px" }}>
                  <FormControlLabel value={bankAccounts.data.provider_id} control={<Radio />} label={bankAccounts.data.provider_name} />
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <img src={bankAccounts.data.provider_name} alt={bankAccounts.data.provider_name} weight="150" height="150" />
              </Grid>
              {/* <Grid item xs={12} lg={5}>
              {connectedCloudApp === "xero" && <RenderConnectStatus />}{" "}
            </Grid>
            <Grid item xs={12} lg={3}>
              <div style={{ marginTop: "50px" }}>
                <FormControlLabel value="quickbooks" control={<Radio />} label="Quickbooks" disabled />
              </div>
            </Grid> */}

            </Grid>
          </RadioGroup>
        </FormControl></>
      )}
      </>
    )
  }
  const handleGetAuthUrl = () => {
    dispatch(getBankAuthUrl());
  };

  // useEffect(() => {
  //   dispatch(getBusinessStat());
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (authUrl) {
      setTimeout(function () {
        window.location.href = authUrl.authUrl;
      }, 2000);
    }
  }, [authUrl]);

  useEffect(() => {
    if (afterRender) {
      var code = new URLSearchParams(location.search).get("code");
      var error = new URLSearchParams(location.search).get("error");

      if (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Could not connect your bank!",
        }).then(async (val) => {
          if (val.isConfirmed) {
            history.push("/dashboard/bank-accounts");
          }
          if (val.isDismissed) {
            history.push("/dashboard/bank-accounts");
          }
        });
      }
      (async () => {
        if (code) {
          dispatch(exchanceCodeForTrueLayerToken(code));
        }
      })();
    }

    // eslint-disable-next-line
  }, [location, afterRender]);

  return (
    <BasePage navigation="Dashboard/BankAccounts" business={business} canView={user?.permissions?.bankAccount?.view}>
      <Grid container style={{ marginBottom: "5px" }}>
        <Grid item xs={8}>
          {business?.banking && business.banking[0] && (
            <Alert severity={business.isReceivePaymentVerified ? "success" : "info"} style={{ fontSize: "15px", backgroundColor: "#FAFAFA" }}>
              Receive Payment Account Details {!business.isReceivePaymentVerified ? "Not Verified yet" : ""}
              <br />
              <strong>Account number:</strong> {business && business.banking[0].account_number} <strong>Sort code:</strong>{" "}
              {business && business.banking[0].sort_code}
            </Alert>
          )}

        </Grid>
        <Grid item xs={4}>
          <Alert style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
            You can connect maximum {business?.payeda_package?.bank_token?.credit} banks. <br />
            <strong>(You have {business?.payeda_package?.bank_token?.balance || 0} left)</strong>
          </Alert>
        </Grid>
      </Grid>
      <div style={{ fontSize: "5px" }}></div>
      {bankingLoading && "loading"}
      <TableComponent
        toolbar={renderToolbar()}
        data={bankAccounts?.data || []}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isLoading={isLoading}
        getAllData={getSelfBankAccounts}
        shouldSearch={false}
        shouldGetData={false}
        shouldPaginate={false}
        shouldView={true}
        numberOfPages={bankAccounts?.data.number_of_page || 0}
      />

      <AddBankAccountDialog
        open={addBankAccountDialogOpen}
        errors={errors}
        setOpen={setAddBankAccountDialogOpen}
        handleGetAuthUrl={handleGetAuthUrl}
        authUrl={authUrl}
        authUrlLoading={authUrlLoading}
      />
    </BasePage>
  );
};

export default BankAccounts;
