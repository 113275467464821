import { Typography, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import React from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";
import CopyToClipboardButton from "../../../Components/shared/CopyToClipBoard";
import { handleSendInvoicePaymentLink } from "../../../store/actions/invoiceActions";

const PaymentQRDialog = ({ link, open, setOpen, customerHasEmail, invoice }) => {
  const dispatch = useDispatch();
  const business = useSelector((state) => state.business);
  const handleSendPaymentLink = () => {
    let invoices = [];
    invoices.push(invoice);
    let dataToSend = {};
    dataToSend.invoiceIds = invoices.map(inv => inv.id);
    dispatch(handleSendInvoicePaymentLink(dataToSend));
  };

  return (
    <DialogComponent open={open} setOpen={setOpen} title={"Payment QR Code"} description={"Scan QR code to redirect payment screen"} withActions={false}>
      <div style={{ alignItems: "center", textAlign: "center" }}>
        <QRCode value={link} />
        <Typography variant="h5" style={{ marginTop: "25px" }}>
          <strong>Or Copy Payment Link</strong>
        </Typography>

        <CopyToClipboardButton text={link} />
        <br />
        <Tooltip title={customerHasEmail ? "" : "Customer does not have email"}>
          <span>
            <Button variant="outlined" color="primary" onClick={handleSendPaymentLink} disabled={customerHasEmail ? false : true} style={{ marginTop: "10px" }}>
              Send Link via email
            </Button>
          </span>
        </Tooltip>
      </div>
    </DialogComponent>
  );
};

export default PaymentQRDialog;
