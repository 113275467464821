import React from "react";

import TableComponent from "../../../Components/Table/TableComponent";

const headCells = [
  { id: "business_name", label: " Business Name" },
  { id: "admin_email", label: " Admin Email" },

  { id: "receive_payment_info", label: "Receive Payment Account" },

  { id: "receive_payment_account_holder", label: "Account Holder Name" },
];

const VerifiedBusinessesTable = ({ businessesToDeny, selected, setSelected, numberOfPages, toolbar, getAllData }) => {
  return (
    <TableComponent
      toolbar={toolbar}
      data={businessesToDeny}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
      getAllData={getAllData}
      shouldGetData={true}
      shouldView={true}
      numberOfPages={numberOfPages}
    />
  );
};

export default VerifiedBusinessesTable;
