import React, { useState } from 'react';
import { Button, CircularProgress, Grid, Link, TextField, Typography } from "@material-ui/core";
import axios from 'axios';
import { API_BASE } from '../../../store/env';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBankStatement, getCompanyStatement, getIdFrontPage, getIdRearPage, getUploadedFileStatuses, handleUploadFile } from '../../../store/actions/businessActions';

const uploadStatuses = {
    WAITING: 0,
    UPLOADING: 1,
    UPLOADED: 2,
    FAIL: 3,
    DELETING: 4
}

const CompanyDocuments = ({ formData, setFormData, documents, user, isPathUpdate }) => {

    const dispatch = useDispatch();

    const idRearLoading = useSelector(state => state.business.idRearLoading)
    const idFrontLoading = useSelector(state => state.business.idFrontLoading)
    const companyStatementLoading = useSelector(state => state.business.companyStatementLoading)
    const bankStatementLoading = useSelector(state => state.business.bankStatementLoading)
    const fileStatuses = useSelector(state => state.business.fileStatuses)

    useEffect(() => {
        dispatch(getUploadedFileStatuses())
    }, [])

    const handleFileChange = (fileType, e) => {
        const files = Array.from(e.target.files)
        let formData = new FormData();
        formData.append(fileType, files[0]);
        dispatch(handleUploadFile(formData));
    }

    const uploadingText = () => {
        return (
            <small style={{ fontStyle: "italic" }}><strong>Uploading..</strong></small>
        )
    }
    const handleDownloadDocuments = (fileType) => {
        let teamType = user.team_type === "Business" ? 0 : 1;
        let teamId = user.team_id;

        if (fileType === "idRear") {
            dispatch(getIdRearPage(teamId, teamType))
        } else if (fileType === "idFront") {
            dispatch(getIdFrontPage(teamId, teamType))
        } else if (fileType === "companyState") {
            dispatch(getCompanyStatement(teamId, teamType))

        } else if (fileType === "bankStatement") {
            console.log("runs")
            dispatch(getBankStatement(teamId, teamType))

        }
    }

    return (
        <Grid container spacing={2} style={{ marginTop: "30px" }}>
            <Grid item xs={12}>
                {(!isPathUpdate && user.isInvited) && <Typography>(Optional section)</Typography>}
                <br />
                <Typography>Enter account number sortcode where you want to receive payments</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    name="account_number"
                    label="Account Number"
                    value={formData.account_number}
                    onChange={(e) => setFormData({ ...formData, account_number: e.target.value })}
                    fullWidth={true}
                    InputProps={{ inputProps: { min: 2 } }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"

                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    name="sort_code"
                    label="Sort Code"
                    value={formData.sort_code}
                    onChange={(e) => setFormData({ ...formData, sort_code: e.target.value })}
                    fullWidth={true}
                    InputProps={{ inputProps: { min: 2 } }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"

                />
            </Grid>

            <Grid item xs={12} lg={4} md={4}>
                <small>Bank statement:</small> <br />
                <input name="bank_statement" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => handleFileChange("bank_statement", e)} />

                <br />
                <small style={{ fontSize: "13px" }}>Document of the account you want to receive payments into</small>
                <br />
                <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
                <br />
                {fileStatuses?.bank_statement_status === uploadStatuses.UPLOADING && uploadingText()}
                {fileStatuses?.bank_statement_status === uploadStatuses.UPLOADED && (
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            handleDownloadDocuments("bankStatement")
                        }}
                    >
                        {bankStatementLoading ? "Loading.. " : "Download the bank statement"}
                    </Link>
                )}
            </Grid>

            {
                formData.selectedOption === "soleTrader" && (
                    <>
                        {" "}
                        <Grid item xs={12} lg={3} md={3}>
                            <small>Front page of your ID:</small> <br />
                            <input name="id_front" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => handleFileChange("id_front", e)} /> <br />
                            <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
                            <br />
                            {fileStatuses?.id_front_status === uploadStatuses.UPLOADING && uploadingText()}
                            {fileStatuses?.id_front_status === uploadStatuses.UPLOADED && (
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        handleDownloadDocuments("idFront")
                                    }}
                                >
                                    {idFrontLoading ? "Loading.. " : "Download the front page of ID"}
                                </Link>

                            )}

                        </Grid>
                        <Grid item xs={12} lg={3} md={3}>
                            <small>Rear page of your ID:</small> <br />
                            <input name="id_rear" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => handleFileChange("id_rear", e)} /> <br />
                            <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>,
                            <br />
                            {fileStatuses?.id_rear_status === uploadStatuses.UPLOADING && uploadingText()}
                            {fileStatuses?.id_rear_status === uploadStatuses.UPLOADED && (
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        handleDownloadDocuments("idRear")
                                    }}
                                >
                                    {idRearLoading ? "Loading..." : " Download the rear page of ID"}
                                </Link>
                            )}
                        </Grid>
                    </>
                )
            }

            {
                formData.selectedOption === "registeredCH" && (
                    <Grid item xs={12} lg={4} md={4}>
                        <small>Company Certificate:</small> <br />
                        <input
                            name="company_certificate"
                            type="file"
                            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                            onChange={(e) => handleFileChange("company_certificate", e)}
                        />{" "}
                        <br />
                        <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
                        <br />
                        {fileStatuses?.company_certificate_status === uploadStatuses.UPLOADING && uploadingText()}
                        {fileStatuses?.company_certificate_status === uploadStatuses.UPLOADED && (
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    handleDownloadDocuments("companyState")
                                }}
                            >
                                {companyStatementLoading ? "Loading..." : "Download the company certificate"}
                            </Link>
                        )}
                    </Grid>
                )
            }
        </Grid >
    )
}

export default CompanyDocuments