import axios from "axios";
import { API_BASE } from "../env";
import { setErrors, setSuccess } from "./uiActions";

export const getAllPackages = () => async (dispatch) => {
  try {
    dispatch({ type: "PACKAGE_DATA_FETCH_STARTED" });

    let data = await axios.get(`${API_BASE}/v1/admin/packages`);

    dispatch({ type: "PACKAGE_DATA_FETCH_SUCCESS", payload: data.data });
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const createPackage = (data, isEdit) => async (dispatch) => {
  try {
    dispatch({ type: "PACKAGE_DATA_FETCH_STARTED" });

    await axios.post(`${API_BASE}/v1/admin/package`, data);

    if (isEdit) {
      dispatch(setSuccess("Successfully edited the package"));
    } else {
      dispatch(setSuccess("Successfully added the package"));
    }
    dispatch(getAllPackages());
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const deletePackage = (data) => async (dispatch) => {


  try {
    dispatch({ type: "PACKAGE_DATA_FETCH_STARTED" });

    await axios.delete(`${API_BASE}/v1/admin/package/${data.id}`);


    dispatch(getAllPackages());
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const assignPackageToCustomer = (data) => async (dispatch) => {
  try {
    dispatch({ type: "PACKAGE_DATA_FETCH_STARTED" });

    await axios.post(`${API_BASE}/v1/assign-package`, data);
    dispatch(setSuccess("Successfully assigned package for selected customer"));

    dispatch(getAllPackages());
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
