import { Divider, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import SimpleTable from "../../Components/Table/SimpleTable";
import ConfirmDeleteDialog from "./../../Components/Dialogs/ConfirmDeleteDialog";
import TableComponent from "./../../Components/Table/TableComponent";

const outgoingInvoicesTableHeadCells = [
  { id: "invoice_number", label: "Invoice Number" },
  { id: "customer_name", label: "Customer Name" },
  { id: "issued_date", label: "Issue Date" },
  { id: "due_date", label: "Due Date" },
  { id: "allStatusDetails", label: "Status" },

  { id: "amount", label: "Amount to be Paid" },
  { id: "invoiceDetails", label: "Details" },
  { id: "manual" },
];

const incomingInvoicesTableHeadCells = [
  { id: "invoice_number", label: "Invoice Number" },
  { id: "supplier_name", label: "Company Name" },
  { id: "issued_date", label: "Issue Date" },
  { id: "due_date", label: "Due Date" },
  { id: "allStatusDetails", label: "Status" },

  { id: "amount", label: "Amount" },

  { id: "invoiceDetails", label: "Details" },

  { id: "manual" },
];

const invoiceLinesHeadCells = [
  { id: "product", label: "Product" },
  { id: "product_quantity", label: "Quantity" },
  { id: "unit_price", label: "Unit Price" },
  { id: "line_vat_amount", label: "Vat Amount" },
  { id: "line_discount_amount", label: "Line Discount Amount" },
  { id: "subtotal_amount", label: "Subtotal Amount" },
];

const searchOptions = [
  { default: true, id: "invoice_number_lower", label: "Invoice Number" },
  { id: "customer_lower", label: "Customer Name" },
];

const statusOptions = [
  { default: true, id: "Pending", label: "Pending" },
  { id: "Paid", label: "Paid" },
  { id: "Timeout", label: "Timeout" },
];

const InvoicesListComponent = (props) => {
  const {
    isLastPage,
    isLoading,
    invoices,
    toolbar,
    footer,
    businessId,
    removeInvoices,
    title,
    getAllData,
    dispatch,
    setErrors,
    shouldView,
    shouldGetData,
    setShouldGetData,
    shouldSearch,
    showFilters,
    paginateRedis,
    currentPage,
    setCurrentPage,
    selectedFieldId,
    selectedStatus,
    shouldPaginate,
    selectedDateOption,
    applyDateFilterOption,
    numberOfPages,
    getDataParams,
    triggerGetData
  } = props;
  const { selected, setSelected, deleteDialogOpen, setDeleteDialogOpen } = props;

  //These are used to show invoice details when "Show" button is clicked on the table
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onDelete = () => {
    if (selected.length > 0) {
      // const allEqual = (arr) => arr.every((val) => val.adder.businessId === businessId);
      // const result = allEqual(selected);

      // if (!result) {
      //   setErrors({
      //     general: "You cannot delete invoices added by someone else",
      //   });
      //   setDeleteDialogOpen(false);
      //   setSelected([]);
      // }

      let selectedInvoices = selected.map((item) => item.id);
      setDeleteLoading(true);
      dispatch(removeInvoices({ idsToDelete: selectedInvoices }, setDeleteDialogOpen, setSelected, setDeleteLoading));
      setDeleteLoading(true);
      // setDeleteDialogOpen(false);
      // setSelected([]);
    }
  };

  const renderDetailsDialog = () => {
    let { description, invoice_lines: invoiceLines, invoice_image: invoiceImage } = invoiceDetails;

    if (typeof invoiceLines === "string") {
      invoiceLines = null;
    }

    console.log(invoiceLines)
    return (
      <DialogComponent open={detailsDialogOpen} setOpen={setDetailsDialogOpen} title="Invoice Details">
        <Grid container>

          {description && (
            <Grid item xs={12} sm={6}>
              <Typography>Description: {description}</Typography>
              <Divider />
              <br />
            </Grid>
          )}

          {invoiceLines && invoiceLines.length > 0 && (
            <Grid item xs={12}>
              <Typography>Invoice Lines</Typography>
              <SimpleTable data={invoiceLines} headCells={invoiceLinesHeadCells} />
              <Divider />
              <br />
            </Grid>
          )}

          {invoiceImage && (
            <Grid item xs={12} sm={6}>
              <Typography>Invoice Image</Typography>
              <img src={invoiceImage} alt="invoice" />
            </Grid>
          )}
        </Grid>
      </DialogComponent>
    );
  };

  const onShowDetailsClicked = (invoice) => {
    setDetailsDialogOpen(true);
    setInvoiceDetails(invoice);
  };

  const onShowPDFClicked = (invoice) => { };

  return (
    <div>
      <TableComponent
        toolbar={toolbar}
        footer={footer}
        data={invoices}
        selected={selected}
        setSelected={setSelected}
        headCells={title === "incoming" ? incomingInvoicesTableHeadCells : outgoingInvoicesTableHeadCells}
        businessId={businessId}
        showDetailsAction={onShowDetailsClicked}
        showPDFAction={onShowPDFClicked}
        getAllData={getAllData}
        searchOptions={searchOptions}
        statusOptions={statusOptions}
        searchbarText="Search Invoices"
        isLastPage={isLastPage}
        isLoading={isLoading}
        shouldPaginate={shouldPaginate}
        shouldView={shouldView}
        shouldSearch={shouldSearch}
        showFilters={showFilters}
        paginateRedis={paginateRedis}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        selectedFieldId={selectedFieldId}
        selectedStatus={selectedStatus}
        selectedDateOption={selectedDateOption}
        applyDateFilterOption={applyDateFilterOption}
        handleSearchBar={true}
        numberOfPages={numberOfPages}
        getDataParams={getDataParams}
        triggerGetData={triggerGetData}
        shouldGetData={true}
      />

      <ConfirmDeleteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} onSubmit={onDelete} isLoading={deleteLoading} />

      {renderDetailsDialog()}
    </div>
  );
};

export default InvoicesListComponent;
