import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import countryList from "react-select-country-list";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../Components/DropdownComponent";
import { API_BASE } from "../../store/env";
import PhoneInputComponent from "./../../Components/PhoneInputComponent";
import { usePhoneInputStyles } from "../../Components/ComponentStyles";
const customStyles = {
  menu: () => ({
    backgroundColor: "white",
  }),
};

const CustomerDialog = ({ open, setOpen, title, description, errors, onSubmit, defaultValues, business, isEdit, setCustomerDialCode, setCustomerPhone }) => {
  const { register, control, handleSubmit, reset, setValue, watch } = useForm({
    shouldUnregister: false,
  });
  const classes = usePhoneInputStyles();


  const [platformUsage, setPlatformUsage] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");

  useEffect(() => {
    if (business && business.account_settings) {
      let platformUsage = business?.account_settings?.invoice_settings;
      if (platformUsage) {
        setPlatformUsage(platformUsage.isErp ? "erp" : "manual");
      }
    }
  }, [business]);


  const [addressList, setAddressList] = useState([])
  const [searchTimeout, setSearchTimeout] = useState(null);

  let postCode = watch("post_code");

  const getAddressWithPostCode = async (postCode) => {
    try {
      let request = await axios.get(`${API_BASE}/account/postcode?postCode=${postCode}`);
      if (request.status === 200 && request?.data?.Items.length > 0) {
        let items = request?.data?.Items;

        let addresses = []
        items.forEach((item) => {
          addresses.push({ value: item.Id, label: item.Text + ", " + item.Description })
        });
        setAddressList(addresses)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (postCode) {

      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      setSearchTimeout(
        setTimeout(() => {
          console.log("post codess", postCode)
          getAddressWithPostCode(postCode)
        }, 1000),
      );
    }

  }, [postCode])
  // useEffect(() => {

  //   reset(defaultValues);
  // }, [defaultValues, reset]);

  const [phoneDefault, setPhoneDefault] = useState("");
  useEffect(() => {
    setValue("country", defaultValues?.address?.country);
    setValue("city", defaultValues?.address?.city);
    setValue("address_line_1", defaultValues?.address?.address_line_1);
    setValue("address_line_2", defaultValues?.address?.address_line_2);
    setValue("region_province", defaultValues?.address?.region_province);
    setValue("post_code", defaultValues?.address?.post_code);
    setValue("name", defaultValues?.name);
    setValue("email", defaultValues?.email);
    setValue("phone", defaultValues?.phone);
    setValue("erp_no", defaultValues?.erp_no);
    setValue("phone", defaultValues?.phone);

    setPhoneDefault(defaultValues?.phoneCountryCode + defaultValues?.phone)
    // reset(defaultValues);

  }, [defaultValues])

  useEffect(() => {
    if (selectedAddress) {
      const address = addressList.find(add => add.value === selectedAddress)
      setValue("address_line_1", address.label)

    }
  }, [selectedAddress])

  const ChooseAddressComponent = () => {
    return (
      <DropdownComponent
        required
        id="chosen_address"
        name="chosen_address"
        label="Choose address"
        onChange={(e) => {
          setSelectedAddress(e.target.value);
        }}
        items={addressList}
        error={errors.chosen_address ? true : false}
        helperText="Addresses"
        control={control}
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
        defaultValue={selectedAddress}
      />
    )
  }
  const renderAddress = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="post_code"
            name="post_code"
            label="Postcode"
            error={errors.post_code ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={12}>
          {addressList && addressList.length > 0 && (
            <ChooseAddressComponent />
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="address_line_1"
            name="address_line_1"
            label="Address"
            error={errors.address_line_1 ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            InputLabelProps={{ shrink: true }}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="address_line_2"
            name="address_line_2"
            label="Address"
            error={errors.address_line_2 ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            InputLabelProps={{ shrink: true }}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField

            variant="outlined"
            id="country"
            name="country"
            label="Country "
            error={errors.Country ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="city"
            name="city"
            label="City"
            error={errors.city ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="region_province"
            name="region_province"
            label="Region/Province/County "
            error={errors.region_province ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit(onSubmit)}
      withActions={platformUsage === "manual" ? true : false}
    >
      <TextField
        margin="dense"
        variant="outlined"
        required
        autoFocus
        id="name"
        name="name"
        label="Company Name or Full Name"
        error={errors.name ? true : false}
        helperText={errors.name ?? ""}
        fullWidth
        inputRef={register}
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />
      <br />
      <br />


      {isEdit && <TextField
        required
        margin="dense"
        id="erp_no"
        name="erp_no"
        label="Customer No (ERP)"
        error={errors.erp_no ? true : false}
        helperText={errors.id ?? "The ID assigned to the customer in your system. If you don't have one you can use Tax ID or National ID"}
        fullWidth
        variant="outlined"
        inputRef={register}
        inputProps={{ readOnly: isEdit }}
      />
      }
      {console.log("sadsa")}      <div>
        {/* <PhoneInputComponent errors={errors} register={register} control={control} disabled={platformUsage === "erp" ? true : false} /> */}
        <PhoneInput
          id="phone"
          name="phone"
          inputClass={classes.phone}
          containerClass={classes.phone}
          searchClass={classes.phone}
          inputStyle={{ height: "55px" }}
          isValid={!errors.phone}
          defaultErrorMessage={errors.phone ?? false}
          country={"gb"}
          inputRef={register}
          control={control}
          disabled={platformUsage === "erp" ? true : false}
          onChange={(phone, value) => { console.log("phpne", phone, "value", value); setCustomerDialCode(value.dialCode); setCustomerPhone("+" + phone) }}
        />
        <Typography color={errors.phone ? "error" : "default"} variant="caption" component="p">
          {/* {errors.phone ? errors.phone : "Required for invitation request."} */}
        </Typography>
      </div>

      <Divider />

      {/* <TextField
        margin="dense"
        id="full_name"
        name="full_name"
        label="Representative Name"
        helperText="Optional"
        inputRef={register}
        variant="outlined"
        fullWidth
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      /> */}
      <TextField
        margin="dense"
        id="email"
        name="email"
        label="Email"
        helperText="Optional"
        fullWidth
        variant="outlined"
        inputRef={register}
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />

      <DropdownComponent
        required
        id="currency"
        name="currency"
        label="Currency"
        defaultValue={"GBP"}
        items={[{ value: "GBP", label: "GBP" }]}
        error={errors.currency ? true : false}
        helperText="Invoice Currency"
        control={control}
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />
      <h2>Address</h2>
      {renderAddress()}

      <br />
    </DialogComponent>
  );
};

export default CustomerDialog;
