import React, { useEffect, useState } from "react";
//Vendor
import styled from "@react-pdf/styled-components";
import { Document, Font } from "@react-pdf/renderer";
import Noto from "./NotoSans-Regular.ttf";
import { UI_API_BASE, API_BASE } from "../../store/env";
import axios from "axios";
import currency from "currency.js";
Font.register({
  family: "Noto Sans",
  format: "truetype",
  src: Noto,
});

const BillPage = styled.Page`
  padding: 80px 40px;
  font-family: "Noto Sans";
`;

const BillDetails = styled.View`
  display: table;
  width: auto;
  margin: 0 auto;
  flex-direction: row;
`;

const BillColumnLeft = styled.View`
  width: 50%;
  padding-right: 50px;
  padding-left: 0px;

  text-align: left;
`;
const BillColumnRight = styled(BillColumnLeft)`
  padding-left: 50px;
  padding-right: 0px;
  text-align: right;
`;

const InvoiceHeading = styled.Text`
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  width: 100%;
`;
const InvoiceNumber = styled.Text`
  color: #444;
  font-size: 12px;
`;

const Details = styled.Text`
  font-size: 12;
  padding: 5px 0;
  line-height: 1.2;
`;

const Textt = styled.Text`
  padding: 5px 0;
`;

const BillTable = styled.View`
  display: table;
  width: 100%;
`;
const BillRow = styled.View`
  margin: 0 auto;
  flex-direction: row;
  padding: 8px 0;
  width: "100%";
`;
const BillRowHead = styled(BillRow)`
  background-color: #333;
  font-size: 5px;
  border-radius: 2px;
  color: white;
  width: "100%";
`;
const BillDataText = styled.Text`
  width: 70%;
  padding: 0 5px;
  font-size: 12px;
`;
const BillDataNum = styled.Text`
  width: 15%;
  padding: 0 5px;
  font-size: 12px;
`;
const BillDataSerial = styled(BillDataNum)`
  width: 2%;
`;
const BillTotal = styled(BillColumnRight)`
  marging-top: 15px;
  padding: 0;
`;

function InvoicePDF(props) {
  let data = props.invoiceData;
  console.log("data1321321", data)
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [supplierDetails, setSupplierDetails] = useState({});

  // const getBusinessDetails = async (customerId, supplierId) => {
  //   setLoading(true);
  //   console.log("customerid", customerId, "supplierıd", supplierId);
  //   var customerRequest = await axios.post(`${API_BASE}/get-business-details`, { id: customerId });
  //   var supplierRequest = await axios.post(`${API_BASE}/get-business-details`, { id: supplierId });

  //   if (customerRequest.status === 200) {
  //     let customerDetails = customerRequest.data;

  //     setLoading(false);

  //     setCustomerDetails({ ...customerDetails, id: customerId });
  //   }

  //   if (supplierRequest.status === 200) {
  //     let supplierDetails = supplierRequest.data;
  //     setLoading(false);

  //     setSupplierDetails({ ...supplierDetails, id: supplierId });
  //   }
  // };

  // useEffect(() => {
  //   setLoading(true);

  //   (async () => {
  //     if (data.supplier) {
  //       console.log("data321321", data);
  //       await getBusinessDetails(data.customer_doc_id || data.customer, data.supplier_doc_id || data.supplier);
  //     }
  //   })();
  //   // eslint-disable-next-line
  // }, [data]);

  let isCheckout = props.isCheckout;

  let invoiceLines = data.invoice_lines;
  let invoiceType = data.payeda_package_id || data.description === "Payeda package payment";
  console.log("dataaa", data)
  const itemList = invoiceLines.map(({ product, subtotal_amount, line_total_amount, line_vat_amount, product_quantity, unit_price, id }, i) => (
    <BillRow key={id}>
      <BillDataSerial style={{ fontSize: "10px" }}>{i + 1}</BillDataSerial>
      <BillDataText style={{ fontSize: "10px" }}>{product}</BillDataText>
      <BillDataNum style={{ width: "15%", fontSize: "10px" }}>{product_quantity}</BillDataNum>
      <BillDataNum style={{ width: "15%", fontSize: "10px" }}>{unit_price}</BillDataNum>
      <BillDataNum style={{ width: "15%", fontSize: "10px" }}>{subtotal_amount}</BillDataNum>

      <BillDataNum style={{ width: "15%", fontSize: "10px" }}>{line_vat_amount}</BillDataNum>
      <BillDataNum style={{ width: "15%", fontSize: "10px" }}>{line_total_amount}</BillDataNum>
    </BillRow>
  ));

  const packageList = invoiceLines.map((line, i) => (
    <BillRow key={i}>
      <BillDataSerial>{i + 1}</BillDataSerial>
      <BillDataText>{line}</BillDataText>
    </BillRow>
  ));

  return (
    <Document>
      <BillPage>
        <BillDetails>
          <BillColumnLeft>
            <Textt style={{ fontSize: "18px" }}>{data?.supplier_name}</Textt>
            <Textt style={{ fontSize: "13px" }}>{data?.supplier_address?.address?.address_line_1 || ""}</Textt>
            <Textt style={{ fontSize: "13px" }}>
              {(data?.supplier_address?.address_line_2 || "") + " " + (data?.supplier_address?.region_province || "")}
            </Textt>
            <Textt style={{ fontSize: "13px" }}>{data?.supplier_address?.post_code}</Textt>
            <Details style={{ marginTop: "40px" }}>Invoice Date : {data.issued_date}</Details>
            <Details>Due Date : {data.due_date}</Details>
          </BillColumnLeft>
          <BillColumnRight>
            <InvoiceHeading style={{ fontSize: isCheckout && "15px" }}> {isCheckout ? "Copy of Invoice" : "INVOICE"}</InvoiceHeading>
            <InvoiceNumber># {data.invoice_number}</InvoiceNumber>
            <Details style={{ marginTop: "20px" }}>Bill To</Details>
            <Textt style={{ fontSize: "13px" }}>{data.customer_name}</Textt>
            <Textt style={{ fontSize: "13px" }}>{data?.customer_address?.address_line_1 || ""}</Textt>

            <Textt style={{ fontSize: "13px" }}> {(data?.customer_address?.address_line_2 || "") + " " + (data?.customer_address?.region_province || "")}</Textt>

          </BillColumnRight>
        </BillDetails>

        <>
          <BillTable>
            <BillRowHead style={{ fontSize: "5px", backgroundColor: "#4169E1" }}>
              <BillDataSerial>#</BillDataSerial>
              <BillDataText style={{ fontSize: "10px" }}>Detail</BillDataText>
              <BillDataNum style={{ width: "15%", fontSize: "10px" }}>QTY</BillDataNum>
              <BillDataNum style={{ width: "15%", fontSize: "10px" }}>Unit Price</BillDataNum>
              <BillDataNum style={{ width: "15%", fontSize: "10px" }}>Subtotal</BillDataNum>

              <BillDataNum style={{ width: "15%", fontSize: "10px" }}>Vat</BillDataNum>
              <BillDataNum style={{ width: "15%", fontSize: "10px" }}>Total</BillDataNum>
            </BillRowHead>
          </BillTable>
          {itemList}
        </>

        {!invoiceType && (
          <BillDetails style={{ padding: "0 5px" }}>
            <BillColumnLeft>{/* <Details style={{ marginTop: "50px" }}>Note : Thanks</Details> */}</BillColumnLeft>
            <BillColumnRight>
              <BillDetails style={{ marginTop: "50px" }}>
                <BillTotal>
                  <Details>Sub Total:</Details>
                  <Details>Vat Amount:</Details>
                  <Details>Total Amount:</Details>

                  <Details>Total to Pay:</Details>
                </BillTotal>
                <BillTotal>
                  <Details>GBP {data.subtotal_amount || 0}</Details>
                  <Details>GBP {data.total_vat_amount || 0}</Details>
                  <Details>
                    GBP{" "}
                    {currency(data.total_paid_amount)
                      .add(data.amount)
                      .format({ symbol: "" })}
                  </Details>
                  <Details>GBP {data.amount}</Details>
                </BillTotal>
              </BillDetails>
            </BillColumnRight>
          </BillDetails>
        )}
        {invoiceType && (
          <>
            <BillDetails style={{ padding: "0 5px" }}>
              <BillColumnLeft>{/* <Details style={{ marginTop: "50px" }}>Note : Thanks</Details> */}</BillColumnLeft>
              <BillColumnRight>
                <BillDetails style={{ marginTop: "50px" }}>
                  <BillTotal>
                    <Details>Total:</Details>
                  </BillTotal>
                  <BillTotal>
                    <Details>GBP {data.amount}</Details>
                  </BillTotal>
                </BillDetails>
              </BillColumnRight>
            </BillDetails>
          </>
        )}
      </BillPage>
    </Document>
  );
}

export default InvoicePDF;
