import React, { useEffect, useState } from "react";

import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import AddButton from "./../../Components/shared/AddButton";
import TableToolbarComponent from "./../../Components/Table/TableToolbarComponent";
import { usePermissionsContext } from "./../../Contexts/PermissionsContext";
import canPerformAction from "./../../util/permissions/canPerformAction";
import checkIfHasActiveMembership from "./../../util/permissions/checkIfHasActiveMembership";

const ProductsTableToolbar = ({
  numSelected,
  header,
  setCreateDialogOpen,
  setEditDialogOpen,
  setInviteDialogOpen,
  setDeleteDialogOpen,
  business,
  setOpenBuyPackageDialog,
  setErrors
}) => {
  const { permissions } = usePermissionsContext();

  let [userHasMembership, setUserHasMembership] = useState(null);

  useEffect(() => {
    async function checkMembership() {
      let hasMembership = await checkIfHasActiveMembership(business);
      setUserHasMembership(hasMembership);
    }
    checkMembership();
    // eslint-disable-next-line
  }, []);

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>
        {numSelected > 0 ? (
          <>
            {/* {numSelected === 1 && (
              <>
                {canPerformAction("products", permissions, "edit") && (
                  <>
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => setEditDialogOpen(true)}
                        style={{ color: "#1F036D" }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            )} */}
            {canPerformAction("products", permissions, "remove") && (
              <Tooltip title="Delete">
                <IconButton onClick={() => setDeleteDialogOpen(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {canPerformAction("products", permissions, "create") &&
              (
                <>
                  <AddButton onClick={() => {
                    if (!business.isReceivePaymentVerified) {
                      setErrors({ general: "Please verify receive payment bank account" })
                      return false
                    }
                    if (Object.keys(business.payeda_package) <= 0) {
                      setOpenBuyPackageDialog(true);
                    } else {
                      setCreateDialogOpen(true)
                    }
                  }} />
                </>
              )}
          </>
        )}
      </>
    </TableToolbarComponent>
  );
};

export default ProductsTableToolbar;
