import { Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import MakePaymentDialog from "../MakePaymentDialog";
import TableToolbarComponent from "./../../../Components/Table/TableToolbarComponent";
import canPerformAction from "./../../../util/permissions/canPerformAction";
import { usePermissionsContext } from "./../../../Contexts/PermissionsContext";
import IncomingInvoiceFilterDialog from "./IncomingInvoiceFilterDİalog";

const IncomingInvoicesToolbar = ({
  numSelected,
  header,
  errors,
  setErrors,
  clearErrors,
  suppliers,
  selectedInvoice,

  availableInstitutions,
  makePayment,
  renderSupplierSelection,
  renderStatusSelection,
  getIncomingInvoices,
  dispatch,
  setMakePaymentDialogOpen,
  makePaymentDialogOpen,
  openFilterDialog,
  setOpenFilterDialog,
  applyInvoiceDownloadFilter
}) => {
  const { permissions } = usePermissionsContext();

  const handleOpenMakePayment = () => {
    if (!numSelected || numSelected === 0) {
      setErrors({ general: "Please select invoices to make payment" });
      return false;
    }
    let filterPaid = selectedInvoice.filter((invoice) => invoice.statusDetails.status === "Paid");
    if (filterPaid.length === 0) setMakePaymentDialogOpen(true);
    else setErrors({ general: "Choose only unpaid invoices!" });
  };

  const handleGetLatestInvoices = () => {
    // setCurrentPage(1);
    dispatch(getIncomingInvoices([{ cleanRedis: true }]));
  };

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>
        {/* {numSelected > 0 &&
          canPerformAction("incominginvoices", permissions, "remove") && (
            <Tooltip title="Delete">
              <IconButton onClick={() => setDeleteDialogOpen(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )} */}
        {numSelected ? (
          <>
            {canPerformAction("incomingInvoices", permissions, "makePayment") && (
              <>
                <Button onClick={() => handleOpenMakePayment()} color="secondary" variant="contained" style={{ float: "right" }}>
                  Make Payment
                </Button>
                {makePaymentDialogOpen && (
                  <MakePaymentDialog
                    open={makePaymentDialogOpen}
                    setOpen={setMakePaymentDialogOpen}
                    selectedInvoices={selectedInvoice}
                    suppliers={suppliers}
                    errors={errors}
                    setErrors={setErrors}
                    clearErrors={clearErrors}
                    availableInstitutions={availableInstitutions}
                    submitPayment={makePayment}
                  //   // handleSubmit={handlePaymentSubmit}
                  //   // handleCancel={() => setPaymentDialogOpen(false)}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Grid
              container

              spacing={3}

            >

              <Grid item xs={6} lg={3} md={3} >
                {renderSupplierSelection}
              </Grid>
              <Grid item xs={6} lg={3} md={3}>
                {renderStatusSelection}
              </Grid>

              <Grid item xs={6} lg={3} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenFilterDialog(true);
                  }}
                  style={{
                    borderRadius: "30px",
                    marginLeft: "40px",
                    marginTop: "10px",
                    backgroundColor: "orange"
                  }}
                >
                  Download Invoices
                </Button>
                <IncomingInvoiceFilterDialog open={openFilterDialog} setOpen={setOpenFilterDialog} renderSupplierSelection={renderSupplierSelection} onSubmit={applyInvoiceDownloadFilter} />
              </Grid>
              <Grid item xs={6} lg={2} md={2}>

                {canPerformAction("incomingInvoices", permissions, "makePayment") && (
                  <>
                    <Button onClick={() => handleOpenMakePayment()} color="primary" variant="outlined" style={{
                      borderRadius: "30px",
                      marginLeft: "40px",
                      marginTop: "10px",
                      backgroundColor: "white",
                      color: "blue"
                    }}>
                      Make Payment
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    </TableToolbarComponent>
  );
};

export default IncomingInvoicesToolbar;


