import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import shortid from "shortid";
import BasePage from "../../Components/BasePage";
import ConfirmDeleteDialog from "../../Components/Dialogs/ConfirmDeleteDialog";
import DropdownComponent from "../../Components/DropdownComponent";

import { invitePeople, mailToContact } from "../../store/actions/businessActions";
import { addCustomer, editCustomer, getCustomerGroups, getCustomers, removeCustomers } from "../../store/actions/customerActions";
import { customerSchema } from "../../util/yup/yupSchemas";
import { useUiContext } from "./../../Contexts/UiContext";
import verifySchema from "./../../util/yup/verifySchema";
import CustomerDialog from "./CustomerDialog";
import CustomersTable from "./CustomersTable";
import InviteDialog from "./InviteDialog";
import checkIfHasActiveMembership from "../../util/permissions/checkIfHasActiveMembership";
import { createNotification } from "../../store/actions/notificationActions";
import BuyPackageDialog from "../../Components/Dialogs/BuyPackageDialog";

const Customers = ({ business, businessId }) => {
  const dispatch = useDispatch();
  const { register, control, handleSubmit } = useForm();

  const customers = useSelector((state) => state.customer);
  const isLastPage = useSelector((state) => state.business.lastPage);
  const user = useSelector((state) => state.user);

  let customerGroups = useSelector((state) => state.customer.customerGroups);
  console.log("customers", customers)
  const { errors, setErrors, clearErrors } = useUiContext();

  const [selected, setSelected] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [msgToCustomer, setMsgToCustomer] = useState("Join to Payeda and see your invoices I made for you ");
  const [afterRender, setAfterRender] = useState(false);
  const [customersWithoutEmail, setCustomersWithoutEmail] = useState(null);
  const [selectedCustomerGroupId, setSelectedCustomerGroupId] = useState(null);
  const [userHasMembership, setUserHasMembership] = useState(null);
  const [isMessage, setIsMessage] = useState(false);
  const [openBuyPackageDialog, setOpenBuyPackageDialog] = useState(false);
  const [customerDialCode, setCustomerDialCode] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  // useEffect(() => {
  //   async function checkMembership() {
  //     let hasMembership = await checkIfHasActiveMembership(business);
  //     setUserHasMembership(hasMembership);
  //   }
  //   checkMembership();
  //   // eslint-disable-next-line
  // }, [business]);

  // useEffect(() => {
  //   if (selectedCustomerGroupId === "all_customers") {
  //     dispatch(getCustomers({}));
  //   } else {
  //     dispatch(getCustomerGroups(selectedCustomerGroupId));
  //   }
  // }, [selectedCustomerGroupId]);



  // useEffect(() => {
  //   if (afterRender) {
  //     dispatch(getCustomers({}));
  //   }
  //   // eslint-disable-next-line
  // }, [afterRender]);

  // // TODO: select customer group olarak değiştirildi
  // useEffect(() => {
  //   if (customerGroups && customerGroups.length > 0 && customerGroups[customerGroups.length - 1].value !== "all_customers") {
  //     customerGroups.push({ value: "all_customers", label: "All Customers" });
  //   }
  // }, [customerGroups]);

  const onCreateNew = async (data) => {
    data.address = {
      address_line_1: data.address_line_1,
      address_line_2: data.address_line_2,
      country: data.country,
      region_province: data.region_province,
      city: data.city,
      post_code: data.post_code,
    }
    data.erp_no = shortid.generate();
    console.log("customerDialCode", customerDialCode);
    console.log("hpone", customerPhone);
    if (customerPhone && customerDialCode) {
      data.phoneCountryCode = "+" + customerDialCode;
      console.log("customerPhone", customerPhone);
      data.phone = customerPhone.replace(data.phoneCountryCode, "");
    }

    console.log("data", data);
    const valid = await verifySchema(customerSchema, data, clearErrors, setErrors);
    if (valid) {
      dispatch(addCustomer(data));
    }
    setCreateDialogOpen(false);
    // setSelected([]);
  };

  const onEdit = async (data) => {
    if (selected.length > 0) {
      data.address = {
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        country: data.country,
        region_province: data.region_province,
        city: data.city,
        post_code: data.post_code,
      }
      console.log("customerDialCode", customerDialCode);
      console.log("hpone", customerPhone);
      if (customerPhone && customerDialCode) {
        data.phoneCountryCode = "+" + customerDialCode;
        console.log("customerPhone", customerPhone);

        data.phone = customerPhone.replace(data.phoneCountryCode, "");
      }

      const valid = await verifySchema(customerSchema, data, clearErrors, setErrors);
      if (valid) {
        const dataToSend = { customer_id: selected[0].id, ...data };
        dispatch(editCustomer(dataToSend));
      }
    }
    setEditDialogOpen(false);
    setSelected([]);
  };

  const onDelete = async () => {
    let customersToDelete = selected.map((customer) => customer.id);
    let details = { idsToDelete: customersToDelete };
    dispatch(removeCustomers(details));
    setSelected([]);
    setDeleteDialogOpen(false);
  };

  const onInvite = async () => {
    if (isMessage) {
      if (!msgToCustomer) {
        setErrors({ general: "Please enter your message" });
        return false;
      }
      let dataToSend = {};
      let invitees = [];
      for (let sel of selected) {
        invitees.push({ id: sel.id, email: sel.email });
      }
      dataToSend.invitees = invitees;
      dataToSend.message = msgToCustomer;
      dispatch(mailToContact(dataToSend));
      setInviteDialogOpen(false);
      setSelected([]);
      return true;
    }
    else
      console.log("whatpsapp checked", selected[0]);

    if (selected.length === 1 && selected[0]?.invite_information?.invitation_code) {
      setErrors({ general: "Already invited" });
      return false;
    }
    if (selected.length === 1 && selected[0].whatsappChecked) {
      console.log("hereee");
      //TODO: check if user has already invited
      var inviteeInfo = selected[0];
      var inviteePhone = inviteeInfo.phone;

      var inviteCode;
      if (selected[0].invite_information.invited) {
        inviteCode = selected[0].invite_information.invitation_code;
      } else {
        inviteCode = shortid.generate();
      }
      window.open("https://api.whatsapp.com/send?phone=" + inviteePhone + "&text=" + msgToCustomer + " https://dev2.payeda.net/invite?code=" + inviteCode);

      selected[0].invite_information.invitation_code = inviteCode;
      let data = {
        customerIdsToInvite: [selected[0].id],
        isWhatsapp: true,
        invitationCode: inviteCode
      }
      dispatch(invitePeople(data, false));
    } else {
      // eger toplu gönderim olacaksa (email) kod backend'de üretilecek ve mail atılacak
      // mail address'i olmayanlar array'den çıkarılacak

      selected[0].businessName = business.business_name;
      selected[0].msgToCustomer = msgToCustomer;
      selected[0].emailChecked = true;
      let customersWithEmail = selected.filter((s) => s.email);
      let customersToInvite = await customersWithEmail.map(customer => customer.id)
      let customersWithoutEmail = selected.filter((s) => !s.email);
      let dataToSend = {
        msgToCustomer: msgToCustomer,
        customerIdsToInvite: customersToInvite
      }
      dispatch(invitePeople(dataToSend, false));
      // handle adding notification for user when people could not send because they do not have mail address

      let notificationDetail = [];

      if (customersWithoutEmail.length > 0) {
        customersWithoutEmail.map((person) => {
          return notificationDetail.push(`${person.name} doesn't have email address`);
        });

        let notificationData = {
          message: `${customersWithoutEmail.length} customers doesn't have email address, select to see the details`,
          recipient: user.business,
          title: "Invitations could not send to some customers",
          read: false,
          logId: "",
          detail: notificationDetail,
        };

        // // TODO: dispatch nofitication data
        // dispatch(createNotification(notificationData));
      }
    }
    setInviteDialogOpen(false);
    setSelected([]);
  };

  const handleGroupOptionChange = (e) => {
    setSelectedCustomerGroupId(e.target.value);
  };

  const renderCustomerGroupSelection = () => {
    return (
      <>
        <FormControl variant="standard" sx={{ m: 5, minWidth: 500 }} fullWidth>
          <InputLabel id="demo-simple-select-standard-label" style={{ marginLeft: "10px" }}>
            Select a contact group{" "}
          </InputLabel>

          <Select
            value={selectedCustomerGroupId}
            onChange={handleGroupOptionChange}
            error={errors.selectedCustomerGroupId ? true : false}
            helperText={errors.selectedCustomerGroupId ?? ""}
            variant="outlined"
          >
            {customerGroups &&
              customerGroups?.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </>
    );
  };
  return (
    <BasePage navigation="Dashboard/Customers" business={business} canView={user?.permissions?.customer?.view}>

      <div>
        <CustomersTable
          customers={customers?.customers?.data || []}
          setCreateDialogOpen={setCreateDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
          setInviteDialogOpen={setInviteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          selected={selected}
          setSelected={setSelected}
          setErrors={setErrors}
          isLoading={customers.isLoading}
          getAllData={getCustomers}
          numberOfPages={customers?.customers?.number_of_page || 0}
          business={business}
          dispatch={dispatch}
          renderCustomerGroups={renderCustomerGroupSelection()}
          setIsMessage={setIsMessage}
          setOpenBuyPackageDialog={setOpenBuyPackageDialog}
        />
        {business?.address && (
          <>
            <CustomerDialog
              open={createDialogOpen}
              setOpen={setCreateDialogOpen}
              title="Create new customer"
              description="Create new customer to start receiving payments."
              onSubmit={onCreateNew}
              errors={errors}
              business={business}
              isEdit={false}
              setCustomerDialCode={setCustomerDialCode}
              setCustomerPhone={setCustomerPhone}
            />

            <CustomerDialog
              open={editDialogOpen}
              setOpen={setEditDialogOpen}
              title="Edit customer"
              description="Edit details of the customer."
              onSubmit={onEdit}
              errors={errors}
              defaultValues={selected[0]}
              business={business}
              isEdit={true}
              setCustomerDialCode={setCustomerDialCode}
              setCustomerPhone={setCustomerPhone}

            />

            <InviteDialog
              title={!isMessage ? "Invite the customer" : "Type your message"}
              description={!isMessage ? "Invite the customer to receive payment" : ""}
              selected={selected}
              setSelected={setSelected}
              onSubmit={onInvite}
              open={inviteDialogOpen}
              setOpen={setInviteDialogOpen}
              msgToCustomer={msgToCustomer}
              setMsgToCustomer={setMsgToCustomer}
            />

            <ConfirmDeleteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} onSubmit={onDelete} />
            <BuyPackageDialog
              open={openBuyPackageDialog}
              setOpen={setOpenBuyPackageDialog}
            />
          </>
        )}
      </div>
    </BasePage>
  );
};

export default Customers;
