import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";
import NumberFormatCustom from "../../../Components/NumberFormatCustom";
import { createPaymentLimit } from "../../../store/actions/adminActions";

const PaymentLimitDialog = ({ open, setOpen, paymentLimits }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [maxDailyPaymentLimitPersonal, setMaxDailyPaymentLimitPersonal] = useState("");
    const [maxDailyPaymentLimitBusiness, setMaxDailyPaymentLimitBusiness] = useState("");

    const [maxAtOncePaymentLimitPersonal, setMaxAtOncePaymentLimitPersonal] = useState("");
    const [maxAtOncePaymentLimitBusiness, setMaxAtOncePaymentLimitBusiness] = useState("");


    useEffect(() => {
        console.log(paymentLimits?.data?.daily_total_limit_business);
        console.log(paymentLimits);
        if (paymentLimits?.data?.daily_total_limit_business) {
            setMaxDailyPaymentLimitBusiness(paymentLimits?.data?.daily_total_limit_business.toString());
        }
        if (paymentLimits?.data?.daily_total_limit_personal) {
            setMaxDailyPaymentLimitPersonal(paymentLimits?.data?.daily_total_limit_personal.toString());
        }
        if (paymentLimits?.data?.at_once_total_limit_business) {
            setMaxAtOncePaymentLimitBusiness(paymentLimits?.data?.at_once_total_limit_business.toString());
        }
        if (paymentLimits?.data?.at_once_total_limit_personal) {
            setMaxAtOncePaymentLimitPersonal(paymentLimits?.data?.at_once_total_limit_personal.toString());
        }
    }, [paymentLimits])
    const onSubmit = () => {
        console.log(maxDailyPaymentLimitBusiness, maxDailyPaymentLimitPersonal, maxAtOncePaymentLimitBusiness, maxAtOncePaymentLimitPersonal);


        const data = {
            maxDailyPaymentLimitBusiness: parseFloat(maxDailyPaymentLimitBusiness),
            maxDailyPaymentLimitPersonal: parseFloat(maxDailyPaymentLimitPersonal),
            maxAtOncePaymentLimitPersonal: parseFloat(maxAtOncePaymentLimitPersonal),
            maxAtOncePaymentLimitBusiness: parseFloat(maxAtOncePaymentLimitBusiness),

        }

        dispatch(createPaymentLimit(data));
    }


    return (
        <DialogComponent
            open={open}
            setOpen={setOpen}
            title={"Set Payment Limits"}
            onSubmit={onSubmit}
            withActions={true}
        >
            <Grid container spacing={2} direction="column"
                alignItems="center"
                justifyContent="center">

                <Typography>Maximum At Once Payment Limit: </Typography>
                <Grid item xs={12} lg={12} md={12}>

                    <TextField
                        required
                        variant="outlined"
                        id="maxAt"
                        name="maxAtOncePaymentLimitPersonal"
                        label="Max At Once Payment Limit Personal"
                        value={maxAtOncePaymentLimitPersonal}
                        type="text"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "450px" }}
                        onChange={(e) => setMaxAtOncePaymentLimitPersonal(e.target.value)}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>

                    <TextField
                        required
                        variant="outlined"
                        id="maxAtOncePaymentLimitBusiness"
                        name="maxAtOncePaymentLimitBusiness"
                        label="Max At Once Payment Limit Business"
                        value={maxAtOncePaymentLimitBusiness}

                        type="text"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "450px" }}
                        onChange={(e) => setMaxAtOncePaymentLimitBusiness(e.target.value)}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                </Grid>
                <Typography>Maximum Daily Payment Limit: </Typography>

                <Grid item xs={12} lg={12} md={12}>

                    <TextField
                        required
                        variant="outlined"
                        id="maxAtc"
                        name="maxDailyPaymentLimitPersonal"
                        label="Max Daily Payment Limit Personal"
                        value={maxDailyPaymentLimitPersonal}

                        type="text"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "450px" }}
                        onChange={(e) => setMaxDailyPaymentLimitPersonal(e.target.value)}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>

                    <TextField
                        required
                        variant="outlined"
                        id="maxDailyPaymentLimitBusiness"
                        name="maxDailyPaymentLimitBusiness"
                        label="Max Daily Payment Limit Business"
                        value={maxDailyPaymentLimitBusiness}

                        type="text"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "450px" }}
                        onChange={(e) => setMaxDailyPaymentLimitBusiness(e.target.value)}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                </Grid>
            </Grid>

        </DialogComponent>
    );
};

export default PaymentLimitDialog;
