import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import TableComponent from "../../../Components/Table/TableComponent";
import { getAllBusinessBankAccounts, getBankAccountChangeRequests } from "../../../store/actions/adminActions";
import BusinessBankAcctDetailsDialog from "./BusinessBankAcctDetailsDialog";

const headCells = [
  { id: "business_name", label: "Business Name" },
  { id: "createdAt", label: "Request Date" },
];

//TODO: search criterias will be added
const BankAccountChangeRequests = ({ business }) => {
  const [selected, setSelected] = useState([]);
  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  const [selectedSearchType, setSelectedSearchType] = useState(null);

  const [openBankAccountDetails, setOpenBankAccountDetails] = useState(false);

  let dispatch = useDispatch();

  let isLoading = useSelector((state) => state.admin.isLoading);
  let allBankAccounts = useSelector((state) => state?.admin?.allBankAccounts?.data);

  const handleGetData = () => {
    dispatch(getBankAccountChangeRequests());
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const renderDetailsButton = () => {
    return (
      <>
        <Button variant="outlined" color="secondary" style={{ float: "right" }} onClick={() => setOpenBankAccountDetails(true)}>
          Details
        </Button>
      </>
    );
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BasePage navigation="Dashboard/All Bank Accounts" business={business} canView={true}>
        {/* {selected.length === 1 && renderDetailsButton()} */}
        <TableComponent data={allBankAccounts} isLoading={isLoading} selected={selected} setSelected={setSelected} headCells={headCells} shouldGetData={false} shouldView={true} />
      </BasePage>
    </MuiPickersUtilsProvider>
  );
};

export default BankAccountChangeRequests;
