const BUSINESS_TYPES = [
  { value: "personalCompany", label: "Personal Company" },
  { value: "limitedCompany", label: "Limited Liability Company" },
  { value: "incorporatedCompany", label: "Incorporated Company" },
  { value: "nongovernmental", label: "Non-Governmental Company" },
  { value: "soleTrader", label: "Sole Trader" },
  { value: "limitedLiabilityPartnership", label: "Limited Liability Partnership" },


];

const BUSINESS_MODELS = [
  {
    value: "accommododationFood",
    label: "Accommodation and Food Services",
  },
  {
    value: "adminSupport",
    label: "Administrative and Support Services",
  },
  {
    value: "artsRecreation",
    label: "Arts and Recreation Services",
  },
  { value: "construction", label: "Construction/Builder" },
  { value: "education", label: "Education and Training" },
  { value: "farming", label: "Farming, forestry and fishing" },
  { value: "financial", label: "Financial Services & Insurance" },
  { value: "manufacturing", label: "Manufacturing" },
  {
    value: "medical",
    label: "Medical/Health Care/Community Services",
  },
  {
    value: "personal",
    label: "Personal, Beauty, Wellbeing and other services",
  },
  {
    value: "professional",
    label: "Professional Services(e.g. Legal, Accounting, Marketing, Consulting)",
  },
  {
    value: "propery",
    label: "Property Operators and Real Estate Services",
  },
  {
    value: "rental",
    label: "Rental&Hiring Services(non Real Estate)",
  },
  {
    value: "professional",
    label: "Professional Services(e.g. Legal, Accounting, Marketing, Consulting)",
  },
  {
    value: "propery",
    label: "Property Operators and Real Estate Services",
  },
  {
    value: "rental",
    label: "Rental&Hiring Services(non Real Estate)",
  },
  {
    value: "repair",
    label: "Repair and Maintenance(Automative & Property)",
  },
  {
    value: "retailTradeFood",
    label: "Retail Trade (Food & Beverage)",
  },
  {
    value: "retailTradeNonFood",
    label: "Retail Trade (Non-Food)",
  },
  {
    value: "technology",
    label: "Technology/Telecommunications services",
  },
  {
    value: "tradesWork",
    label: "Trades work(e.g. Plumber, Carpenter, Electrician)",
  },
  {
    value: "tradeAssociation",
    label: "Trade Association, Association",
  },
  {
    value: "transporLogistics",
    label: "Transport, Logistics, Postal, Warehousing",
  },
  {
    value: "wholeTrade",
    label: "Whole Trade",
  },
];
module.exports = {
  BUSINESS_MODELS,
  BUSINESS_TYPES,
};
