import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { getVerifiedBusinesses } from "../../../store/actions/adminActions";
import BusinessesToolbar from "./BusinessesToolbar";
import BusinessFilesDialog from "./BusinessFilesDialog";
import VerifiedBusinessesTable from "./VerifiedBusinessesTable";

const VerifiedBusinesses = ({ denyBusiness, business }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [businessFilesDialogOpen, setBusinessFilesDialogOpen] = useState(false);

  const verifiedBusinesses = useSelector((state) => state.admin.verifiedBusinesses);
  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (afterRender) {
  //     dispatch(getVerifiedBusinesses());
  //   }
  //   // eslint-disable-next-line
  // }, [afterRender]);

  const handleDenyBusiness = (e) => {
    var businessId;
    selected.map((business, index) => (businessId = business.id));
    var details = { businessId };
    denyBusiness(details);
  };

  const renderToolbar = () => {
    return (
      <BusinessesToolbar
        numSelected={selected ? selected.length : 0}
        header="Verified Businesses"
        setBusinessFilesDialogOpen={setBusinessFilesDialogOpen}
        selected={selected[0]}
        handleDenyBusiness={handleDenyBusiness}
      />
    );
  };

  return (
    <BasePage navigation="Dashboard/Verified-Businesses" business={business} canView={true}>
      <VerifiedBusinessesTable
        toolbar={renderToolbar()}
        businessesToDeny={verifiedBusinesses.data}
        selected={selected}
        setSelected={setSelected}
        getAllData={getVerifiedBusinesses}
        numberOfPages={verifiedBusinesses?.number_of_page || 0}
      />

      {selected.length > 0 && (
        <BusinessFilesDialog open={businessFilesDialogOpen} selected={selected[0]} setSelected={setSelected} setOpen={setBusinessFilesDialogOpen} business={selected[0]} />
      )}
    </BasePage>
  );
};

export default VerifiedBusinesses;
