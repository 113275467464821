import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import CompanyOrSoleTraderInformation from './Business/CompanyOrSoleTraderInformation';
import CompanyDocuments from './Business/CompanyDocuments';
import { useHistory, useLocation } from "react-router-dom";

import { useUiContext } from "../../Contexts/UiContext";
import { createBusiness, getBusinessDetails } from '../../store/actions/businessActions';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));


function BusinessSteps() {
    return ['Company or Sole Trader Information', 'Bank Information and Other Documents'];
}

// TODO: personal steps



const CreateAccount = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { setErrors, clearErrors, errors } = useUiContext();
    const location = useLocation();

    const user = useSelector(state => state.user);
    const business = useSelector(state => state.business);

    const [steps, setSteps] = useState([]);
    const [activeStep, setActiveStep] = useState(0)
    const [isPathUpdate, setisPathUpdate] = useState(undefined);

    useEffect(() => {
        const pathName = window.location.pathname.split("/")[1];
        setisPathUpdate(pathName === "update-account" ? true : false);

        if (business.approve === 2) {
            history.push("/dashboard");
        }
        // eslint-disable-next-line
    }, [location]);

    const [formData, setFormData] = useState({
        vat_number: "",
        national_insurance_number: "",
        passport_id: "",
        utr_number: "",
        business_name: "",
        business_phone: "",
        business_email: "",
        business_type: "",
        business_model: "",
        registrationOption: "",
        address: {
            address_line_1: "",
            address_line_2: "",
            post_code: "",
            country: "",
            city: ""
        }
    })

    useEffect(() => {
        if (user.team_type === "Business") {
            setSteps(BusinessSteps)
        }
    }, [user])

    const renderWarning = () => {
        return (
            <Alert severity="warning" >
                <Typography variant="h6">
                    <strong>When you complete account creation you will not be able edit again. </strong>
                </Typography>
                <Typography variant="p">
                    Please make sure you entered correct details or you can exit without submitting your information and come and edit it later.
                </Typography>
            </Alert>
        )
    }

    const renderAccountUpgradeInfo = () => {
        return (
            <Alert severity="info" >
                {/* <Typography variant="h6">
                    <strong>Save</strong>
                </Typography> */}
                <Typography variant="p">
                    You can come and edit your account later on
                </Typography>
            </Alert>
        )
    }
    useEffect(() => {
        setFormData({
            vat_number: business.vat_number || "",
            national_insurance_number: business.national_insurance_number || "",
            passport_id: business.passport_id || "",
            utr_number: business.utr_number || "",
            business_name: business.business_name || "",
            business_phone: business.business_phone || "",
            business_email: business.business_email || "",
            business_type: business.business_type || "",
            business_model: business.business_model || "",
            registrationOption: business.registrationOption || "",
            selectedOption: business.isSoleTrader ? "soleTrader" : business.isRegisteredCompanyHouse ? "registeredCH" : "",
            company_house_id: business.company_house_id || "",
            account_number: business?.banking?.length > 0 && business.banking[0]?.account_number || "",
            sort_code: business?.banking?.length > 0 && business?.banking[0]?.sort_code || "",
            address: {
                address_line_1: business?.address?.address_line_1 || "",
                address_line_2: business?.address?.address_line_2 || "",
                post_code: business?.address?.post_code || "",
                country: business?.address?.country || "",
                city: business?.address?.city || ""
            },
            isManual: business.isManual || false,
            isErp: business.isErp || false
        })
        console.log("triggered")
    }, [business])


    const handleSubmit = async (step) => {

        if (formData.selectedOption === "soleTrader") {
            formData.isSoleTrader = true
            formData.isRegisteredCH = false
            formData.company_house_id = ""
        } else if (formData.selectedOption === "registeredCH") {
            formData.isRegisteredCH = true
            formData.isSoleTrader = false
            formData[formData.registrationOption] = ""
            formData.registrationOption = ""
        }

        let isFinished = false
        let shouldShowAwaitForApprovalMessage = false
        if (step === "next") {
            formData.isFinish = false

        }

        if (step === "finish") {
            formData.isUpgradeToReceivePayment = isPathUpdate ? true : false
            formData.isFinish = true
            isFinished = true
            shouldShowAwaitForApprovalMessage = true
        }

        if (user.isInvited && !isPathUpdate) shouldShowAwaitForApprovalMessage = false

        dispatch(createBusiness(formData, isFinished, shouldShowAwaitForApprovalMessage, history))
        dispatch(getBusinessDetails())
    }

    const handlePrepareFormData = () => {
        let fd = new FormData();

        for (const [key, value] of Object.entries(formData)) {
            if (key === "logo" || key === "bank_statement" || key === "company_certificate" || key === "id_rear_page" || key === "id_front_page") {
                if (value !== null && value.item(0) !== null) formData[key] = value.item(0);
                else formData[key] = "";
            }
        }
        for (var key in formData) {
            fd.append(key, formData[key]);
        }

        return fd
    }

    const handleNext = () => {
        handleSubmit("next")
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleFinish = () => {
        handleSubmit("finish")

    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getBusinessStepContent(step) {
        switch (step) {
            case 0:
                return <CompanyOrSoleTraderInformation
                    setErrors={setErrors}
                    formData={formData}
                    setFormData={setFormData}
                    user={user}
                    isPathUpdate={isPathUpdate}
                />
            case 1:
                return <CompanyDocuments
                    formData={formData}
                    setFormData={setFormData}
                    user={user}
                    isPathUpdate={isPathUpdate}
                />
            default:
                break;
        }
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <Typography>{getBusinessStepContent(index)}</Typography>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    {true && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            Save & Next
                                        </Button>
                                    )}

                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    {(!isPathUpdate && user.isInvited) ? renderAccountUpgradeInfo() : renderWarning()}
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFinish}
                        className={classes.button}
                    >
                        Complete Account Creation
                    </Button>
                </Paper>
            )}
        </div>)
}

export default CreateAccount