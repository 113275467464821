import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import VerifySoleTraderWithVat from './VerifySoleTraderWithVat';
import { BUSINESS_MODELS, BUSINESS_TYPES } from "../../../util/constants/companyInformation";
import MenuItem from "@material-ui/core/MenuItem";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import VerifyBusinessWithCompanyHouse from './VerifyBusinessWithCompanyHouse';

const CompanyOrSoleTraderInformation = ({ setShowNextButton, setErrors, formData, setFormData, user, isPathUpdate }) => {
    const dispatch = useDispatch();
    const business = useSelector(state => state.business);
    const [registrationOption, setRegistrationOption] = useState(formData.registrationOption);
    const [selectedOption, setSelectedOption] = useState(formData.selectedOption);
    const [textFieldName, setTextFieldName] = useState(null);
    const [textFieldLabel, setTextFieldLabel] = useState(null);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [businessTypes, setBusinessTypes] = useState([]);
    const [platformUsage, setPlatformUsage] = useState("")

    useEffect(() => {
        if (registrationOption === "vat_number") {
            setTextFieldName("vat_number");
            setTextFieldLabel("Enter your VAT number");
        }
        if (registrationOption === "passport_id") {
            setTextFieldName("passport_id");
            setTextFieldLabel("Enter your passport id number");
        }
        if (registrationOption === "national_insurance_number") {
            setTextFieldName("national_insurance_number");
            setTextFieldLabel("Enter your national insurance number");
        }
        if (registrationOption === "utr_number") {
            setTextFieldName("utr_number");
            setTextFieldLabel("Enter your Unique taxpayer reference");
        }
    }, [registrationOption]);


    useEffect(() => {
        let types = [];
        if (selectedOption === "soleTrader") {
            types = BUSINESS_TYPES.filter((type) => type.value === "soleTrader");
        } else {
            types = BUSINESS_TYPES.filter((type) => type.value !== "soleTrader");
        }
        setBusinessTypes(types);
    }, [selectedOption]);

    useEffect(() => {
        setSelectedOption(formData.selectedOption)
        setRegistrationOption(formData.registrationOption)
    }, [formData])

    useEffect(() => {
        console.log("platformusage", platformUsage)
        if (platformUsage === "isErp") {
            formData.isErp = true
            formData.isManual = false

        } else if (platformUsage === "isManuel") {
            formData.isManual = true
            formData.isErp = false

        }
        console.log("formDatajsdsa", formData)
    }, [platformUsage])

    const handleRadioChange = (e) => {

        let val = e.target.value;
        setSelectedOption(val);
        setFormData({ ...formData, selectedOption: val });
    };

    const renderIsErpOrIsManuel = () => {
        return (
            <form>
                <div style={{ fontSize: "18px" }}>
                    <div>
                        <input
                            id="isErp"
                            value="isErp"
                            name="isErpOrIsManuel"
                            type="radio"
                            defaultChecked={business.isErp}
                            onChange={(e) => setPlatformUsage(e.target.value)}
                        />
                        <label for="isErpOrIsManuel">Cloud Invoicing Platfrom</label>
                    </div>
                    <input
                        id="isManuel"
                        value="isManuel"
                        name="isErpOrIsManuel"
                        type="radio"
                        defaultChecked={business.isManual}

                        onChange={(e) => setPlatformUsage(e.target.value)}
                    />
                    <label for="isErpOrIsManuel">Manual invoicing platform</label>
                </div>
            </form>
        );
    };
    const renderChooseBusinessType = () => {
        return (
            <form>
                <div style={{ marginTop: "30px" }}>
                    <Typography variant="body">
                        <strong style={{ color: "steelblue", fontWeight: "bold" }}></strong>
                    </Typography>
                    <div>
                        <input id="soleTrader" name="businessType" value="soleTrader" checked={selectedOption === "soleTrader"} type="radio" onChange={(e) => handleRadioChange(e)} />
                        <label for="soleTrader">Sole Trader</label>
                    </div>
                    <div>
                        <input id="registeredCH" name="businessType" value="registeredCH" checked={selectedOption === "registeredCH"} type="radio" onChange={(e) => handleRadioChange(e)} />
                        <label for="registeredCH">Registered to company house</label>
                    </div>
                </div>
            </form>
        );
    };
    console.log("isPathUpdate", isPathUpdate);
    return (
        <>

            {renderChooseBusinessType()}

            {selectedOption === "soleTrader" && (
                <VerifySoleTraderWithVat
                    textFieldName={textFieldName}
                    textFieldLabel={textFieldLabel}
                    textFieldValue={textFieldValue}
                    setTextFieldValue={setTextFieldValue}
                    registrationOption={registrationOption}
                    setRegistrationOption={setRegistrationOption}
                    setErrors={setErrors}
                    setFormData={setFormData}
                    formData={formData}
                />
            )}

            {selectedOption === "registeredCH" && (
                <VerifyBusinessWithCompanyHouse business={business} formData={formData} />
            )}
            <Grid container spacing={2} style={{ marginTop: "30px" }}>

                <Grid item xs={12} lg={5} md={5}>
                    <TextField
                        required
                        variant="outlined"
                        id="business_name"
                        name="business_name"
                        label="Company Name"
                        value={formData.business_name}
                        type="text"
                        fullWidth={true}
                        InputProps={{ inputProps: { min: 2 } }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFormData({ ...formData, business_name: e.target.value })}
                    />
                </Grid>

                <Grid item xs={12} lg={4} md={4}>
                    <TextField
                        required
                        variant="outlined"
                        id="business_email"
                        name="business_email"
                        label="Company Email"
                        value={formData.business_email}

                        type="text"
                        fullWidth={true}
                        InputProps={{ inputProps: { min: 2 } }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFormData({ ...formData, business_email: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                    <PhoneInput
                        inputProps={{
                            name: 'Business Phone',
                            required: true,
                            autoFocus: true
                        }}
                        country={'gb'}
                        value={formData.business_phone}
                        onChange={phone => setFormData({ ...formData, business_phone: phone })}
                        inputStyle={{ height: "55px" }}

                    />
                </Grid>


                <Grid item xs={12} lg={6} md={6}>
                    <TextField
                        required
                        variant="outlined"
                        id="address_line_1"
                        name="address_line_1"
                        label="Address Line 1"
                        value={formData.address.address_line_1}

                        type="text"
                        fullWidth={true}
                        InputProps={{ inputProps: { min: 2 } }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFormData({ ...formData, address: { ...formData["address"], address_line_1: e.target.value } })}
                    />
                </Grid>


                <Grid item xs={12} lg={6} md={6}>
                    <TextField
                        required
                        variant="outlined"
                        id="address_line_2"
                        name="address_line_2"
                        label="Address Line 2"
                        value={formData.address.address_line_2}

                        type="text"
                        fullWidth={true}
                        InputProps={{ inputProps: { min: 2 } }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFormData({ ...formData, address: { ...formData["address"], address_line_2: e.target.value } })}
                    />
                </Grid>

                <Grid item xs={12} lg={6} md={6}>
                    <TextField
                        required
                        variant="outlined"
                        id="post_code"
                        name="post_code"
                        label="Post Code"
                        value={formData.address.post_code}

                        type="text"
                        fullWidth={true}
                        InputProps={{ inputProps: { min: 2 } }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFormData({ ...formData, address: { ...formData["address"], post_code: e.target.value } })}
                    />
                </Grid>


                <Grid item xs={12} lg={6} md={6}>
                    <TextField
                        required
                        variant="outlined"
                        id="country"
                        name="country"
                        label="Country"
                        value={formData.address.country}

                        type="text"
                        fullWidth={true}
                        InputProps={{ inputProps: { min: 2 } }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFormData({ ...formData, address: { ...formData["address"], country: e.target.value } })}
                    />
                </Grid>

                <Grid item xs={12} lg={6} md={6}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Choose business type"
                        value={formData.business_type}

                        onChange={(e) => setFormData({ ...formData, business_type: e.target.value })}
                    >
                        {businessTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Choose business model"
                        value={formData.business_model}

                        onChange={(e) => setFormData({ ...formData, business_model: e.target.value })}
                    >
                        {BUSINESS_MODELS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid xs={12} style={{ margin: "15px", marginBottom: "15px" }}>
                    <Typography style={{ marginTop: "10px" }}>Choose your invoicing platform usage type:</Typography>

                    {renderIsErpOrIsManuel()}
                </Grid>


            </Grid>

        </>
    )
}

export default CompanyOrSoleTraderInformation