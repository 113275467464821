import { Checkbox, FormControlLabel, Paper, TextareaAutosize, Typography, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import { useInviteDialogStyles } from "./CustomerStyles";

const InviteDialog = ({ open, setOpen, title, description, selected, setSelected, onSubmit, msgToCustomer, setMsgToCustomer }) => {
  const classes = useInviteDialogStyles();

  const handleChange = (e, invitee) => {
    const field = e.target.name;
    const temp = [...selected];
    const selectedIndex = temp.indexOf(invitee);
    const customer = { ...temp[selectedIndex] };

    if (field === "email") {
      customer.emailChecked ? (customer.emailChecked = false) : (customer.emailChecked = true);
    }
    if (field === "whatsapp") {
      customer.whatsappChecked ? (customer.whatsappChecked = false) : (customer.whatsappChecked = true);
    }

    temp[selectedIndex] = customer;
    setSelected(temp);
  };

  useEffect(() => {
    if (selected.length > 1) {
      for (let inviteSelection of selected) {
        inviteSelection["emailChecked"] = true;
      }
    }
    // eslint-disable-next-line
  }, [selected]);

  return (
    <DialogComponent open={open} setOpen={setOpen} title={title} description={description} onSubmit={onSubmit} withActions={true}>
      <Typography variant="body2">Your message:</Typography>

      <TextField
        aria-label="empty textarea"
        placeholder="Enter your message here"
        variant="outlined"
        defaultValue={""}
        name="text_message"
        id="text_message"
        onChange={(e) => setMsgToCustomer(e.target.value)}
        multiline
        minRows={15}
        maxRows={15}
        fullWidth
        inputProps={{ maxLength: 400 }}
      />

      {selected &&
        selected.map((invitee) => (
          <>

            <Paper key={invitee.id} elevation={3} className={classes.paper}>
              <Typography variant="body2">{invitee.name}:</Typography>
              {invitee.email && <FormControlLabel control={<Checkbox checked={invitee.emailChecked} name="email" />} label={`Email (${invitee.email})`} onChange={(e) => handleChange(e, invitee)} />}
              {invitee.phone && selected.length === 1 && (
                <FormControlLabel control={<Checkbox checked={invitee.whatsappChecked} name="whatsapp" />} label={`Whatsapp (${invitee.phone})`} onChange={(e) => handleChange(e, invitee)} />
              )}
            </Paper>

          </>
        ))}
    </DialogComponent>
  );
};

export default InviteDialog;
