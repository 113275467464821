import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import InfoIcon from "@material-ui/icons/Info";
import DataFilterDialogByDate from "../../../Components/Dialogs/DataFilterDialogByDate";

const OutgoingInvoiceFilterDialog = ({ open, setOpen, onSubmit, renderCustomerSelection, renderStatusSelection }) => {

    return (
        <DataFilterDialogByDate
            open={open}
            setOpen={setOpen}
            title="Select filters to download invoices"
            onSubmit={onSubmit}
            withActions={true}
        >

            <Grid item xs={12} sm={6} md={6} lg={6}>
                {renderCustomerSelection}
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                {renderStatusSelection}
            </Grid> */}

        </DataFilterDialogByDate>
    );
};

export default OutgoingInvoiceFilterDialog;