import React from "react";
import { Grid } from "@material-ui/core";

import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePDF from "../InvoicePDF";
import ButtonBase from "../../../Components/shared/ButtonBase";
import { getInvoicePDF } from "../../../store/actions/invoiceActions";

const IncomingInvoicesFooter = ({
  selected,
  setSelected,
  updateInvoiceStatus,
  setDownloadPDF,
  downloadPDF,
  dispatch,
  selectedSupplier,
}) => {
  const handleInvoiceStatusChange = (action) => {
    // let errors = {};
    // if (selected.length === 0) {
    //   errors = { general: "Please select a field!" };
    // } else if (selected.length > 1) {
    //   errors = { general: "You can update one invoice at a time." };
    // } else if (selected[0].status === "Rejected") {
    //   errors = {
    //     general: "You cannot request credit/factoring for rejected invoices",
    //   };
    // } else {
    //   let invoiceId = selected[0]?.id;
    //   var details = {
    //     invoiceId,
    //     statusDetails: { creditRequester: businessId, status: action },
    //   };

    //   updateInvoiceStatus(details);
    //   setSelected([]);
    // }
    // setErrors(errors);
    let allIds = [];
    for (let select of selected) {
      allIds.push(select.id);
    }
    let dataToSend = {
      details: {
        data: {
          isApproved: true,
          status: "Approved By Customer",
        },
        invoices: allIds,
      },
    };
    dispatch(updateInvoiceStatus(dataToSend, selectedSupplier));
    setSelected([]);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid container itme xs={12} spacing={1}>
        {/* {canPerformAction("incominginvoices", permissions, "askCredit") && (
          <Grid item xs={3}>
            <Button
              id="statusss"
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleInvoiceStatusChange("Requested Loan")}
            >
              ASK FOR CREDIT
            </Button>
          </Grid>
        )} */}
        {/* {selected.length > 0 && (
            <ButtonBase
              tooltip="Approve Invoice"
              text="Approve"
              backColor="#00AFA1"
              textColor="white"
              onClick={() => handleInvoiceStatusChange("isApproved")}
            />
          )} */}

        {/* <DenyDialog
                  title="Reject The Invoice"
                  open={denyDialogOpen}
                  setOpen={setDenyDialogOpen}
                  selected={selected}
                  // handleDenyInvoice={()=>handleDenyInvoice()}
                /> */}
        {/* {canPerformAction("incominginvoices", permissions, "askExtension") && (
          <Grid item xs={3}>
            <Button variant="contained" color="primary" size="small">
              Extend term
            </Button>
          </Grid>
        )}
        {canPerformAction("incominginvoices", permissions, "creditApprove") && (
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setBankAgreementDialogOpen(true)}
              size="small"
              style={{ textAlign: "justify" }}
            >
              Credit Approval
            </Button>
            <BankAgreementDialog
              open={bankAgreementDialog}
              setOpen={setBankAgreementDialogOpen}
              selected={selected}
              finances={finances}
              errors={errors}
              user={user}
              updateInvoiceStatus={updateInvoiceStatus}
              // title={setButtonClicked}
              // buttonClick={buttonClick}
              // handleDenyInvoice={()=>handleDenyInvoice()}
            />
          </Grid>
        )} */}
        {selected.length === 1 && (
          <Grid item xs={12} sm={6} md={6} lg={4}>

            <ButtonBase
              tooltip="Prepare PDF"
              text="PDF"
              backColor="#00AFA1"
              textColor="white"
              onClick={() => dispatch(getInvoicePDF(selected[0].id))}
              fontSize="400"
            />


          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default IncomingInvoicesFooter;
