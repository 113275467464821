import { Button, Divider, Paper, TextField, Typography, Grid } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkoutPaymentRequest } from "../../../store/actions/bankingActions";
import { useUiContext } from "./../../../Contexts/UiContext";
import { UI_API_BASE, API_BASE } from "./../../../store/env";
import { useCheckoutStyles } from "../InvoiceStyles";
import ReceiptPDF from "./ReceiptPDF";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useHistory } from "react-router-dom";
import { getReceiptPDF } from "../../../store/actions/invoiceActions";

const ViewInvoice = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [supplierDetails, setSupplierDetails] = useState({});
  const [paymentLink, setPaymentLink] = useState("");

  const { register, setValue, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const { errors, setErrors } = useUiContext();
  const classes = useCheckoutStyles();
  const location = useLocation();

  useEffect(() => {
    console.log("here aasdas");
    setLoading(true);
    var id = new URLSearchParams(location.search).get("payment_id");

    window.location.href = `${API_BASE}/v1/receipt/generate/` +
      id;
    setLoading(false);

  }, [])


  return (
    <>
      {loading && <h4>Loading...</h4>}


    </>
  );
};

export default ViewInvoice;
