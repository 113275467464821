import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import Routes from "../Routes";
import { getUnreadNotifications } from "../store/actions/notificationActions";
import items from "../util/sidebarItems/items";
import Header from "./Header";
import LeftDrawer from "./LeftDrawer";
import defaultTheme from "./theme";

const useStyles = makeStyles(() => ({
  container: {
    margin: "80px 20px 20px 15px",
    paddingLeft: defaultTheme.drawer.width,
    [defaultTheme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    // width: `calc(100% - ${defaultTheme.drawer.width}px)`
  },
  containerFull: {
    paddingLeft: defaultTheme.drawer.miniWidth,
    [defaultTheme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
}));

const userHasNoBusiness = [
  { text: "DashBoard", icon: <DashboardIcon />, link: "/dashboard" },

  {
    text: "Create Account",
    icon: <AddBoxIcon />,
    link: "/dashboard/create-account",
  },
];

const userHasNoAccount = [
  { text: "DashBoard", icon: <DashboardIcon />, link: "/dashboard" },

  {
    text: "Create Account",
    icon: <AddBoxIcon />,
    link: "/dashboard/create-business",
  },
];

const Bar = React.memo(({ user, business, history, isUserPresent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const notifications = useSelector((state) => state.notification.unreadnotifications);
  const stateSideBar = useSelector((state) => state.user.sideBarItems);


  const { authenticated, team_type } = user;

  const [sideBarMenus, setSideBarMenus] = useState([]);
  const [navDrawerOpen, setNavDrawerOpen] = useState(true);

  // let data = sidebarItems(business, user);
  const [dashboard, setDashboard] = useState(localStorage.getItem("dashboardType"));
  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    setAfterRender(true);
  }, []);

  useEffect(() => {
    if (afterRender) {
      let dashboardType = localStorage.getItem("dashboardType");

      setDashboard(dashboardType);
      // if (user.team_id)
      //   dispatch(getUnreadNotifications());
    }
  }, [afterRender]);

  const handleDashboardChange = async () => {
    await setUserSideBar();
  };
  // eslint-disable-next-line
  useEffect(async () => {
    if (user && (user?.name || user?.bankName)) {
      await setUserSideBar();
    }
    // eslint-disable-next-line
  }, [business, stateSideBar]);

  const setUserSideBar = () => {
    if (user.team_type === "Admin") {
      setSideBarMenus(items(user));
    }

    if (business && business.approve === 0) {
      console.log("goes here")
      if (user.team_type === "Business") setSideBarMenus(userHasNoBusiness);
      else if (user.team_type === "Personal") setSideBarMenus(userHasNoAccount);
    } else if ((business.approve === 1 || business.approve === 2) && user.sideBarItems) {
      setSideBarMenus(items(user));
    }

  };

  const handleChangeNavDrawer = () => {
    setNavDrawerOpen(!navDrawerOpen);
  };

  return (
    <>
      <Header
        handleChangeNavDrawer={handleChangeNavDrawer}
        navDrawerOpen={navDrawerOpen}
        ui={ui}
        user={user}
        business={business}
        unreadnotifications={notifications}
        history={history}
        handleDashboardChange={handleDashboardChange}
        dashboard={dashboard}
      />
      {user && team_type && authenticated && (
        <LeftDrawer
          navDrawerOpen={navDrawerOpen}
          handleChangeNavDrawer={handleChangeNavDrawer}
          menus={sideBarMenus}
          ui={ui}
          user={user}
          business={business}
          unreadnotifications={notifications}
          history={history}
        />
      )}

      <div
        className={classNames({
          [classes.container]: user.authenticated,
          [classes.containerFull]: !navDrawerOpen && user.authenticated,
        })}
      >
        <Switch>
          <Routes isUserPresent={isUserPresent} user={user} business={business} authenticated={user.authenticated} businessId={user.business} />

          {/* Admin Routes */}
          {/* Finance Routes */}
        </Switch>
      </div>
    </>
  );
});

export default Bar;
