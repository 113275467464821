const initialState = {
  isLoading: false,
};

//TODO create sepereate reducers for each actions. such as supplierReducer, productReducer etc..
const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BUSINESS_DATA_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
      };
    case "BUSINESS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case "BUSINESS_CREATE_STARTED":
      return {
        ...state,
        businessCreateLoading: true,
      };
    case "BUSINESS_CREATE_FAILED":
      return {
        ...state,
        businessCreateLoading: false,
      };
    case "BUSINESS_CREATE_SUCCESS":
      return {
        ...state,
        businessCreateLoading: false,
        ...action.payload,
      };
    case "SET_LAST_PAGE":
      return {
        ...state,
        lastPage: action.payload,
      };
    case "SET_COMPANY_HOUSE_INFORMATION":
      return {
        ...state,
        companyHouseResult: action.payload,
        companyHouseLoading: false

      };
    case "SET_COMPANY_HOUSE_INFORMATION_LOADING":
      return {
        ...state,
        companyHouseLoading: true
      };
    case "SET_COMPANY_HOUSE_INFORMATION_STOP_LOADING":
      return {
        ...state,
        companyHouseLoading: false

      };

    case "SET_HMRC_INFORMATION":
      return {
        ...state,
        hmrcResult: action.payload,
      };
    case "MERGED_ACCOUNTS_DATA_FETCH_STARTED":
      return {
        ...state,
        mergedLoading: true,
      };
    case "MERGED_ACCOUNTS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        mergedLoading: false,
        mergedAccts: action.payload,
      };
    case "INVITER_DATA_FETCH_STARTED":
      return {
        ...state,
        inviterLoading: true,
      };
    case "INVITER_DATA_FETCH_SUCCESS":
      return {
        ...state,
        inviterLoading: false,
        inviterInformation: action.payload,
      };
    case "STATISTICS_DATA_FETCH_STARTED":
      return {
        ...state,
        staticsLoading: true,
      };
    case "STATISTICS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        staticsLoading: false,
        statistics: action.payload,
      };
    case "BUSINESS_PLANS":
      return {
        ...state,
        loading: false,
        packages: action.payload,
      };
    case "START_FETCHING_CONTACTS":
      return {
        ...state,
        contactsLoading: true,
      };
    case "SET_CONTACTS":
      return {
        ...state,
        contactsLoading: false,
        contacts: action.payload,
      };
    case "SET_CONTACT_GROUPS":
      return {
        ...state,
        contactsLoading: false,
        contactGroups: action.payload,
      };

    case "SET_CONTACTS":
      return {
        ...state,
        contacts: action.payload,
      };

    case "SET_FILE_STATUSES":
      return {
        ...state,
        fileStatuses: action.payload,
      };
    case "ID_REAR_LOADING":
      return {
        ...state,
        idRearLoading: true,
      };

    case "SET_ID_REAR_PAGE":
      return {
        ...state,
        idRearPage: action.payload,
        idRearLoading: false
      };

    case "ID_FRONT_LOADING":
      return {
        ...state,
        idFrontLoading: true,
      };

    case "SET_ID_FRONT_PAGE":
      return {
        ...state,
        idFrontPage: action.payload,
        idFrontLoading: false
      };

    case "BANK_STATEMENT_LOADING":
      return {
        ...state,
        bankStatementLoading: true,
      };
    case "SET_BANK_STATEMENT":
      return {
        ...state,
        bankStatement: action.payload,
        bankStatementLoading: false
      };
    case "COMPANY_STATEMENT_LOADING":
      return {
        ...state,
        companyStatementLoading: true,
      };
    case "SET_COMPANY_STATEMENT":
      return {
        ...state,
        companyCertificate: action.payload,
        companyStatementLoading: false
      };
    case "SET_UNAUTHENTICATED":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default businessReducer;
