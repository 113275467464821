import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import { Avatar, Button, CssBaseline, FormControl, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";

import { useUiContext } from "../../Contexts/UiContext";
import { signinUser } from "../../store/actions/userActions";
import LoadingIcon from "./../../Components/LoadingIcon";
import { useSigninPageStyles } from "./LoginStyles";
import PasswordResetVerifyEmailDialog from "./PasswordResetVerifyEmailDialog";
import BackToHome from "./BackToHome";

//TODO use translations
const SigninPage = ({ history, authenticated, loading }) => {
  const classes = useSigninPageStyles();
  const { errors } = useUiContext();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const [openPasswordResetForm, setOpenPasswordResetForm] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onSubmit = async (data) => {
    dispatch(signinUser({ ...data, isWeb: true }, history));
  };

  return authenticated ? (
    <Redirect to="/dashboard" />
  ) : loading ? (
    <LoadingIcon />
  ) : (
    <>
      <BackToHome />
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} elevation={1} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <FormControl className={classes.form}>
              <br />

              <TextField
                variant="outlined"
                required
                id="email"
                name="email"
                label="Email"
                error={errors.email ? true : false}
                helperText={errors.email ?? false}
                inputRef={register}
              />
              <br />
              <TextField
                required
                variant="outlined"
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                error={errors.password ? true : false}
                helperText={errors.password ?? false}
                onKeyUp={(e) => (e.key === "Enter" ? handleSubmit(onSubmit)() : "")}
                inputRef={register}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button variant="contained" color="secondary" className={classes.submit} onClick={handleSubmit(onSubmit)} style={{ backgroundColor: "#0CC474" }}>
                Sign in
              </Button>
            </FormControl>

            {/*TODO: implement reset password system instead of using firestore's UI*/}

            <Link
              underline="hover"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/signup");
              }}
            >
              Don't have an account? Sign up
            </Link>
            <Link
              underline="hover"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenPasswordResetForm(true);
                setIsPasswordReset(false);
              }}
            >
              Need to verify your Email ?
            </Link>
            <Link
              underline="hover"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenPasswordResetForm(true);
                setIsPasswordReset(true);
              }}
            >
              Forgot Password ?
            </Link>

            <PasswordResetVerifyEmailDialog
              open={openPasswordResetForm}
              setOpen={setOpenPasswordResetForm}
              handleSubmit={handleSubmit}
              errors={errors}
              register={register}
              isPasswordReset={isPasswordReset}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(SigninPage);
