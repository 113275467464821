import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import ExitToAppIconOutlined from "@material-ui/icons/ExitToAppOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useState } from "react";
import { Zoom } from "react-awesome-reveal";

import { useUiContext } from "../../Contexts/UiContext";
import { resetPassword, verifyEmail } from "../../store/actions/userActions";
import { API_BASE, UI_API_BASE } from "../../store/env";
import verifySchema from "./../../util/yup/verifySchema";
import { passwordResetSchema } from "./../../util/yup/yupSchemas";
import axios from "axios";

const PasswordResetForm = ({ isModeVerify }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();

  const { register, handleSubmit } = useForm();
  const { errors, setErrors, clearErrors } = useUiContext();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [verifyEmailError, setVerifyEmailError] = useState(null);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const ValidationType = ({ type }) => {
    const ErrorMessage = errors[type];
    return (
      <Zoom direction="left">
        <Typography style={{ color: "red" }}>{ErrorMessage}</Typography>
      </Zoom>
    );
  };

  const onSubmit = async (data) => {
    let token = new URLSearchParams(location.search).get("token");

    data.token = token;

    const valid = await verifySchema(passwordResetSchema, data, clearErrors, setErrors);

    if (valid) {
      dispatch(resetPassword(data, history));
    }
  };

  useEffect(() => {
    if (isModeVerify) {
      submitVerifyEmailToken();
    }
  }, [isModeVerify]);

  const submitVerifyEmailToken = async () => {
    let token = new URLSearchParams(location.search).get("token");

    try {
      let result = await axios.post(`${API_BASE}/auth/verify-email`, { token });
      if (result.status === 200) {
        setVerifyEmailError(false);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);

      setVerifyEmailError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main404">
        <img src="/img/payeda_logo_new.png" height="75px" weight="750px" />

        <div style={{ width: "500px", padding: 100, marginTop: "10px", border: "1px", borderColor: "#e5e5e5", borderStyle: "solid", borderRadius: "8px" }}>
          <Grid container spacing={3} justify={"center"} alignItems={"center"}>
            {!isModeVerify && (
              <>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    inputRef={register}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />{" "}
                </Grid>
                <Grid item xs={12}>
                  <ValidationType type="password" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Repeat Password"
                    type={showPassword ? "text" : "password"}
                    inputRef={register}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <ValidationType type="confirmPassword" />
                <Grid item xs={12}>
                  <Button style={{ backgroundColor: "#0CC474", color: "white" }} variant="contained" onClick={handleSubmit(onSubmit)} fullWidth>
                    Reset Password
                  </Button>
                </Grid>{" "}
              </>
            )}

            {!isLoading && isModeVerify && (
              <>
                {!verifyEmailError && (
                  <>
                    <Grid item xs={12}>
                      <h2>Your email has been verified</h2>
                      <p>You can now sign in with your account</p>
                    </Grid>
                  </>
                )}

                {verifyEmailError && (
                  <>
                    <Grid item xs={12}>
                      <h2>Try verifying your email again</h2>
                      <p>Your request to verify your email has expired or the link has already been used</p>
                    </Grid>
                  </>
                )}
              </>
            )}
            {/* <Grid item xs={12}>
              <Button variant="contained" startIcon={<ExitToAppIconOutlined />} onClick={() => (window.location.href = `${UI_API_BASE}/signin`)} fullWidth>
                Signin
              </Button>
            </Grid> */}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PasswordResetForm;
