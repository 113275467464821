import axios from "axios";
import { API_BASE } from "../env";
import { setSuccess, setErrors, clearErrors } from "./uiActions";

export const getUnreadNotifications = () => async (dispatch) => {
  try {
    let notifications = await axios.get(`${API_BASE}/notifications/unread`);
    dispatch({ type: "SET_UNREAD_NOTIFICATIONS", payload: notifications.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getNotifications = (selectedFilter) => async (dispatch) => {
  try {
    let notifications = await axios.get(`${API_BASE}/notifications`, {
      params: {
        selectedFilter: selectedFilter || "unread",
      },
    });
    console.log("notifications", notifications);
    dispatch({ type: "SET_NOTIFICATIONS", payload: notifications.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const markNotificationAsRead = (details) => async (dispatch) => {
  try {
    let notifications = await axios.put(`${API_BASE}/notifications`, details);
    dispatch({
      type: "SET_NOTIFICATIONS",
      payload: notifications.data,
    });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully set notifications as read."));
    dispatch(getNotifications("unread"));
    dispatch(getUnreadNotifications());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const createNotification = (details) => async (dispatch) => {
  try {
    let notifications = await axios.post(`${API_BASE}/notifications`, details);
    // dispatch({
    //   type: "SET_NOTIFICATIONS",
    //   payload: notifications.data,
    // });
    dispatch(clearErrors());
    // dispatch(setSuccess("Successfully set notifications as read."));
    // dispatch(getNotifications("unread"));
    dispatch(getUnreadNotifications());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getActivityLogs = () => async (dispatch) => {
  try {
    let activityLogs = await axios.get(`${API_BASE}/activityLogs`);
    dispatch({ type: "SET_ACTIVITY_LOGS", payload: activityLogs.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
