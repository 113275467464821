const verifySchema = (schema, data, clearErrors, setErrors) => {
  console.log("schema: " + schema)
  return schema
    .validate(data, { abortEarly: false })
    .then(() => {
      clearErrors();
      return true;
    })
    .catch((err) => {
      let errors = {};
      if (err && err.errors) {
        for (let i = 0; i < err.errors.length; i++) {
          let msg = err.errors[i];
          let path = err.inner[i].params.path;
          errors[path] = msg;
        }
        console.log("error", errors)
        setErrors(errors);
      }

      /**
       * TODO: handle showing multiple errors at the same time.
       * Right now it shows only one snackbar even though multiple errors exist
       */

      // for (let error of err.errors) {
      //   setErrors({general : error})
      // }

      return false;
    });
};

export default verifySchema;
