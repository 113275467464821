//have own Switch inside for /dashboard/"stuff"
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "./../../Components/BasePage";
import { Button, Grid } from "@material-ui/core";
import FastInvoiceDialog from "../Invoice/Outgoing/FastInvoiceDialog/FastInvoiceDialog";
import PaymentQRDialog from "../Invoice/Outgoing/PaymentQRDialog";
import { FaFileInvoiceDollar } from "react-icons/fa";
import checkIfHasActiveMembership from "../../util/permissions/checkIfHasActiveMembership";
import Cards from "./Cards/Cards";
import MoreIcon from "@material-ui/icons/MoreVert";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GroupIcon from "@material-ui/icons/Group";
import { getIncomingInvoices, getOutgoingInvoices } from "../../store/actions/invoiceActions";
import { getSelfBankAccounts } from "../../store/actions/bankingActions";
import { getSuppliers } from "../../store/actions/supplierActions";
import { getCustomers } from "../../store/actions/customerActions";

import { bankDetailsSchema } from "../../util/yup/yupSchemas";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { getStatistics } from "../../store/actions/businessActions";

const Dashboard = ({ user }) => {
  const dispatch = useDispatch();
  useCallback(() => dispatch(getSuppliers({})), [dispatch]);

  const [imageSource, setImageSource] = useState(null);
  // state for render in mobile mode!
  const [isMobile, setIsMobile] = useState(false);
  const [openAddFastInvoiceDialog, setOpenAddFastInvoiceDialog] = useState(false);
  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);
  const [QRPaymentLink, setQRPaymentLink] = useState("");
  const [platformUsage, setPlatformUsage] = useState("");
  const [userHasMembership, setUserHasMembership] = useState(null);

  const business = useSelector((state) => state.business);

  const customers = useSelector((state) => state.customer.customers);
  const paidOutgoingInvoices = useSelector((state) => state.invoice.paidOutgoingInvoices);
  const pendingOutgoingInvoices = useSelector((state) => state.invoice.pendingOutgoingInvoices);
  const bankAccounts = useSelector((state) => state.banking.bankAccounts);
  const banking = useSelector((state) => state.banking);
  const pendingIncomingInvoices = useSelector((state) => state.invoice.pendingIncomingInvoices);

  const [totalUnpaidInvoices, setTotalUnpaidInvoices] = useState("£0.00");
  const [totalPaidInvoices, setTotalPaidInvoices] = useState("£0.00");
  const [transactionAccountBalance, setTransactionAccountBalance] = useState("£0.00");
  const [savingsAccountBalance, setsavingsTransactionBalance] = useState("£0.00");
  const [totalPendingIncoming, setTotalPendingIncoming] = useState("£0.00");
  const [totalBankAccountBalance, setTotalBankAccountBalance] = useState("£0.00");
  const parseMoneyToFloat = async (price) => {
    if (price === 0 || price === "0" || price === "undefined" || price === null || price === "") return 0;

    let amount = parseFloat(
      price
        .toString()
        .replace(/(,|\.)([0-9]{3})/g, "$2")
        .replace(/(,|\.)/, ".")
    );

    console.log("amount", amount);

    return amount;
  };

  useEffect(() => {
    if (business && business?.account_settings) {
      let platformUsage = business?.account_settings?.invoice_settings;
      if (platformUsage) {
        setPlatformUsage(platformUsage.isErp ? "erp" : "manual");
      }
    }
  }, [business]);

  useEffect(() => {
    dispatch(getStatistics())
  }, [])


  const handleOpenFastInvoiceDashboard = () => {
    setOpenAddFastInvoiceDialog(true);
  };

  useEffect(async () => {
    if (bankAccounts?.data?.length > 0) {
      let savingTotal = 0;
      let transactionTotal = 0;
      for (let bankAccount of bankAccounts.data) {
        if (bankAccount.account_type === "TRANSACTION") savingTotal = (await parseMoneyToFloat(bankAccount.balance) || 0) + savingTotal;
        if (bankAccount.account_type === "SAVINGS") transactionTotal = (await parseMoneyToFloat(bankAccount.balance) || 0) + transactionTotal;
      }
      const total = savingTotal + transactionTotal;
      setTransactionAccountBalance(new Intl.NumberFormat("en-US", { style: "currency", currency: "GBP" }).format(transactionTotal));
      setsavingsTransactionBalance(new Intl.NumberFormat("en-US", { style: "currency", currency: "GBP" }).format(savingTotal));
      setTotalBankAccountBalance(new Intl.NumberFormat("en-US", { style: "currency", currency: "GBP" }).format(total));
    } else {
      setTransactionAccountBalance(0);
      setsavingsTransactionBalance(0);
    }
  }, [bankAccounts]);


  useEffect(() => {
    if (window.screen.width >= 1280) {
      setIsMobile(false);
    } else setIsMobile(true);
  }, [isMobile]);

  useEffect(() => {
    if (user.team_type === "Business") {
      if (user?.usage_type?.isInvoicing) {
        setImageSource("/img/Payeda_How_To_Use_It_Business_23122021.png");
      } else {
        setImageSource("/img/Payeda_How_To_Use_It_MakePaymentUser_Business-Inv.png");
      }
    } else if (user.team_type === "Personal") {
      if (user?.usage_type?.isTransferMoney) {
        setImageSource("/img/Payeda_How_To_Use_It_Personal_23122021.png");
      } else {
        setImageSource("/img/Payeda_How_To_Use_It_MakePaymentUser_Personal-Inv.png");
      }
    }
  }, [user]);

  return (
    <BasePage navigation="Dashboard" business={business} canView={true}>
      {/* {business && business.company_details && renderAwaitinApproval()} */}
      {!user.team_id && user.team_type !== "Admin" && <img src={imageSource} alt="How it works"></img>}

      {isMobile && platformUsage === "manual" && user.team_type === "Business" && business.canReceivePayment && (
        <Grid container>
          <Grid item xs={12}>
            <Button onClick={() => handleOpenFastInvoiceDashboard()} variant="outlined" color="secondary" startIcon={<FaFileInvoiceDollar color="#172B4D" />}>
              {" "}
              Fast Invoice
            </Button>
          </Grid>
        </Grid>
      )}
      {user.team_id && business.canReceivePayment && (
        <>
          <Grid container spacing={6} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "100%" }}>
            <Grid item xs={12} lg={12}>
              <Cards
                title="Total Unpaid Sales"
                number={business.staticsLoading ? "Loading.." : " £" + business?.totalUnpaidSales}
                avatar={<AssignmentIcon style={{ fontSize: "80px", color: "#4ECF7B" }} />}
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <Cards
                title="Total Unpaid Purchases"
                number={business.staticsLoading ? "Loading.." : " £" + business?.totalUnpaidPurchases}
                avatar={<AssignmentIcon style={{ fontSize: "80px", color: "red" }} />}
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <Cards
                number={banking.isLoading ? "Loading.." : totalBankAccountBalance}
                title={"Bank Account Balance"}
                footer={"Transaction + Savings"}
                avatar={<CreditCardIcon style={{ fontSize: "80px", color: "blue" }} />}
              />
            </Grid>
          </Grid>

          <FastInvoiceDialog
            userHasMembership={userHasMembership}
            open={openAddFastInvoiceDialog}
            setOpen={setOpenAddFastInvoiceDialog}
            setOpenQRCodeDialog={setOpenQRCodeDialog}
            setQRPaymentLink={setQRPaymentLink}
          />
          <PaymentQRDialog link={QRPaymentLink} open={openQRCodeDialog} setOpen={setOpenQRCodeDialog} />
        </>
      )}

    </BasePage>
  );
};

export default Dashboard;
