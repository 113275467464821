import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE } from "../../../store/env";
import { getCompanyHouseInformation } from "./../../../store/actions/businessActions";

function VerifyBusinessWithCompanyHouse({
    business,
    formData
}) {
    const dispatch = useDispatch();

    const [companyRegNumber, setCompanyRegNumber] = useState("");
    const loadingButton = useSelector((state) => state.business.companyHouseLoading);
    // const companyHouseResult = useSelector((state) => state.business.companyHouseResult);
    const [companyHouseResult, setCompanyHouseResult] = useState({})
    const [searchTimeout, setSearchTimeout] = useState(null);

    useEffect(() => {
        if (formData.company_house_id) {
            setCompanyRegNumber(formData.company_house_id);
        }
    }, [formData]);

    useEffect(() => {
        if (companyRegNumber !== "") {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
            setSearchTimeout(
                setTimeout(() => {
                    if (
                        companyRegNumber !== "" ||
                        companyRegNumber !== undefined ||
                        companyRegNumber !== null
                    ) {
                        // dispatch({
                        //     type: "SET_COMPANY_HOUSE_INFORMATION",
                        //     payload: {},
                        // });
                        setCompanyHouseResult({})
                        // dispatch(getCompanyHouseInformation(companyRegNumber, true));
                        checkForCompayNumber(companyRegNumber)
                        formData.company_house_id = companyRegNumber;
                        // setValue("company_reg_number", companyRegNumber);
                    }
                }, 1000)
            );
        } else {
        }
        // eslint-disable-next-line
    }, [companyRegNumber]);

    const checkForCompayNumber = async (number) => {
        try {
            let result = await axios.get(`${API_BASE}/v1/account/getCompanyInformation`, {
                params: {
                    companyNumber: number,
                },
            });
            if (result.data) {
                setCompanyHouseResult(result.data.businessData)
            }
        } catch (error) {

        }
    };

    const displayCompanyHouseResult = () => {
        return (
            <>
                <Typography>
                    Company Name:
                    <span style={{ color: "green" }}>
                        {companyHouseResult.company_name}
                    </span>
                </Typography>
                <Typography>
                    Region:{" "}
                    <span style={{ color: "green" }}>
                        {companyHouseResult?.registered_office_address?.region ||
                            companyHouseResult?.registered_office_address?.city ||
                            companyHouseResult?.registered_office_address?.locality}
                    </span>
                </Typography>
                <Typography>
                    Address:{" "}
                    <span style={{ color: "green" }}>
                        {companyHouseResult?.registered_office_address?.address_line_1}{" "}
                        {companyHouseResult?.registered_office_address?.address_line_2}
                    </span>
                </Typography>
                <Typography>
                    <span style={{ color: "green" }}></span>
                </Typography>
                <Typography>
                    Postal Code:{" "}
                    <span style={{ color: "green" }}>
                        {companyHouseResult?.registered_office_address?.postal_code}
                    </span>
                </Typography>
            </>
        );
    };

    return (
        <form>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>

                <Grid item xs={4} lg={5} md={5}>
                    <TextField
                        required
                        variant="outlined"
                        id="company_reg_number"
                        name="company_reg_number"
                        label="Company Number"
                        type="text"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        disabled={loadingButton}
                        defaultValue={companyRegNumber || formData.company_house_id}
                        onChange={(e) => setCompanyRegNumber(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={4} md={4}>
                    {companyHouseResult?.registered_office_address &&
                        displayCompanyHouseResult()}
                </Grid>
            </Grid>
        </form>
    );
}

export default VerifyBusinessWithCompanyHouse;
