import { Avatar, Button, Card, CardContent, CardMedia, Grid, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import LoadingDialog from "../../Components/Dialogs/LoadingDialog";
import PaymentConfirmationDialog from "../../Components/Dialogs/PaymentConfirmationDialog";
import { getSelfBankAccounts, getUserRemainingBalance, makePaymentRequest } from "../../store/actions/bankingActions";
import { getSupplier } from "../../store/actions/supplierActions";
import verifySchema from "../../util/yup/verifySchema";
import { paymentBankAccount } from "../../util/yup/yupSchemas";

const styles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
}));

const MakePaymentDialog = ({ open, setOpen, selectedInvoices, errors, setErrors, clearErrors, submitPayment, redirectURI, payeda_package_id }) => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const classes = styles();

  const [totalAmountToBePaid, setTotalAmountToBePaid] = useState(0);
  const [disableNextPage, setDisableNextPage] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const steps = ["Payment Details", "Selection", "Review Details"];

  const [activeStep, setActiveStep] = useState(0);
  const [gelenData, setGelenData] = useState({});
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState(null);
  const [selectedSortCode, setSelectedSortCode] = useState(null);

  const bankAccounts = useSelector((state) => state.banking.bankAccounts?.data);
  const bankAccountLoading = useSelector((state) => state.banking.isLoading);
  let selectedSupplier = useSelector((state) => state.supplier.singleSupplier);

  let paymentRequest = useSelector((state) => state.banking.paymentRequest);
  // let dailyRemainingBalance = useSelector((state) => state.banking.daily_remaining_balance);
  let business = useSelector((state) => state.business);

  useEffect(() => {
    dispatch(getSelfBankAccounts());
    // dispatch(getUserRemainingBalance());
    // eslint-disable-next-line
  }, []);

  const handleSelectBankAccount = (e) => {
    setSelectedBankAccount(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: "PAYMENT_REQUEST_FETCH_SUCCESS",
      payload: {},
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paymentRequest) {
      if (paymentRequest.payment_uri) {
        setTimeout(function () {
          window.location.href = paymentRequest.payment_uri;
        }, 1000);
      }
    }
  }, [paymentRequest]);

  useEffect(() => {
    //TODO: if user has selected different supplier's invoice, payment shouldn't be allowed
    if (selectedInvoices && open) {
      var supplierArray = selectedInvoices.map(function (item) {
        return item.supplier;
      });

      const allEqual = (arr) => supplierArray.every((val) => val === arr[0]);
      const result = allEqual(supplierArray);

      if (!result) {
        setOpen(false);

        setErrors({
          general: "You cannot pay different suppliers at the same time",
        });
      }

      if (selectedInvoices.length > 10) {
        setOpen(false);

        setErrors({
          general: "You cannot pay more than 10 invoices at the same time",
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedInvoices, open]);

  // useEffect(() => {
  //   if (selectedInvoices && open) {
  //     dispatch(getSupplier(selectedInvoices[0].supplier));
  //   }
  //   // eslint-disable-next-line
  // }, [selectedInvoices, open]);

  const calculateTotalAmount = () => {
    let totalAmount = 0;

    if (selectedInvoices) {
      for (let invoice of selectedInvoices) {
        totalAmount = currency(invoice.amount || invoice.amount_to_be_paid).add(totalAmount).value;
      }
    }
    setTotalAmountToBePaid(currency(totalAmount).format({ symbol: "" }));
    return totalAmount;
  };

  useEffect(() => {
    calculateTotalAmount();
    // eslint-disable-next-line
  }, [selectedInvoices, totalAmountToBePaid]);

  useEffect(() => {
    if (totalAmountToBePaid && business?.remaining_payment_balance) {
      if (currency(business?.remaining_payment_balance).value < currency(totalAmountToBePaid).value) {
        setErrors({
          general: `The maximum amount you can send is ${business?.remaining_payment_balance}`,
        });
        setDisableNextPage(true);
      }
    }
    // eslint-disable-next-line
  }, [totalAmountToBePaid]);

  const handleNext = (data) => {
    let accountType = business.account_type;

    let paymentLimit = accountType === "Business" ? "10,000" : "1,000";

    if (currency(totalAmountToBePaid).intValue > currency(paymentLimit).intValue) {
      setErrors({
        general: `The maximum amount you can send at once is GBP ${paymentLimit}`,
      });
      setDisableNextPage(true);
      return false;
    }

    if (currency(totalAmountToBePaid).intValue < currency("0.01").intValue) {
      setErrors({ general: `The minumum amount you can send is GBP 0.01` });
      return false;
    }

    if (currency(business?.remaining_payment_balance).value < currency(totalAmountToBePaid).value) {
      setErrors({
        general: `The maximum amount you can send is ${business?.remaining_payment_balance}`,
      });
      setDisableNextPage(true);
    }

    if (disableNextPage) return false;
    if (activeStep === 1) {
      if (selectedOption === "fromConnectedAccounts") {
        if (!bankAccounts || bankAccounts?.length === 0) {
          console.log("should be here")
          setErrors({ general: "You don't have any connected accounts" });
          return false;
        }
      } else if (selectedOption === "byManuallyTyping") {
        if (!data.accountNumber || !data.sortCode) {
          setErrors({ general: "You must fill the required fields" });
          return false;
        }
      }
    }

    if (activeStep === 1 && selectedOption !== "byManuallyTyping") {
      if (!selectedBankAccount) {
        setErrors({ general: "Please choose an account" });
        return false;
      }
    }
    if (selectedOption === "byManuallyTyping") {
      if (activeStep === 1) {
        setSelectedAccountNumber(data.accountNumber);
        setSelectedSortCode(data.sortCode);
      }

      if (selectedOption === "byManuallyTyping") {
        if (data.accountNumber) {
          setGelenData(data);
        }
      }
    }

    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === steps.length - 1) {
      let dataToSend = {};
      if (selectedOption === "fromConnectedAccounts") {
        dataToSend.account_number = selectedAccountNumber;
        dataToSend.sort_code = selectedSortCode;
      } else {
        dataToSend.account_number = gelenData.accountNumber;
        dataToSend.sort_code = gelenData.sortCode;
      }

      handleNextButton(dataToSend);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <PaymentDetails />;
      case 1:
        return <AccountDetailsForm />;
      case 2:
        return <ReviewDetails />;
      default:
        return <h1>ERROR</h1>;
    }
  };

  const handleRadioChange = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
  };

  const [dataToSubmit, setDataToSubmit] = useState(null);
  const [openPaymentConfirmDialog, setOpenPaymentConfirmDialog] = useState(false);

  const handleNextButton = async (data) => {
    // let data = {
    //   account_number: e.accountNumber,
    //   sort_code: e.sortCode,
    // };

    const validBankDetails = await verifySchema(paymentBankAccount, data, clearErrors, setErrors);

    let invoiceIds = [];

    for (let invoice of selectedInvoices) {
      invoiceIds.push(invoice.id);
    }
    if (validBankDetails) {


      let dataToSend = {
        invoices: invoiceIds,
        currency: "GBP",
        remitter_sort_code: data.sort_code,
        remitter_account_number: data.account_number,
      };

      setDataToSubmit(dataToSend);
      setOpenPaymentConfirmDialog(true);
      console.log("datatosend", dataToSend);
      // dispatch(makePaymentRequest(dataToSend));
    }
  };

  const handleSubmitPayment = async () => {
    // setOpenLoadingDialog(true);
    console.log("dataToSubmit", dataToSubmit);

    dispatch(makePaymentRequest(dataToSubmit));
  };

  const PaymentDetails = () => {
    return (
      <div style={{ marginLeft: "50px" }}>
        <Typography>You are paying to: {selectedInvoices[0].supplier_name}</Typography>
        <Typography>
          Payment Amount: <strong>{totalAmountToBePaid}</strong>
        </Typography>
        <form>
          <div style={{ marginTop: "30px" }}>
            <Typography variant="body">
              <strong style={{ color: "steelblue", fontWeight: "bold" }}>Choose payment style</strong>
            </Typography>
            <div>
              <input
                id="fromConnectedAccounts"
                name="paymentSelectionOption"
                value="fromConnectedAccounts"
                checked={selectedOption === "fromConnectedAccounts"}
                type="radio"
                onChange={(e) => handleRadioChange(e)}
              />
              <label for="makePayment">From Connected Bank Accounts </label>
            </div>
            <div>
              <input
                id="byManuallyTyping"
                name="paymentSelectionOption"
                value="byManuallyTyping"
                checked={selectedOption === "byManuallyTyping"}
                type="radio"
                onChange={(e) => handleRadioChange(e)}
              />
              <label for="receivePayment">By entering account number</label>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const AccountDetailsForm = () => {
    return (
      <>
        {selectedOption === "fromConnectedAccounts" && userConnectedAccounts()}
        {selectedOption === "byManuallyTyping" && bankAccountsForm()}
      </>
    );
  };

  const bankAccountsForm = () => {
    return (
      <>
        <Grid container>
          <Grid xs={12} style={{ padding: "10px" }}>
            <TextField
              variant="outlined"
              id="accountNumber"
              name="accountNumber"
              required
              label="Your Account Number"
              error={errors.accountNumber ? true : false}
              helperText={errors.accountNumber ?? ""}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              inputRef={register}
              placeholder="e.g. 10000000"
            // onChange={(e) => setSelectedAccountNumber(e.target.value)}
            />
          </Grid>
          <Grid xs={12} style={{ padding: "10px" }}>
            <TextField
              variant="outlined"
              id="sortCode"
              name="sortCode"
              required
              label="Your Sort code"
              error={errors.sortCode ? true : false}
              helperText={errors.sortCode ?? ""}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              placeholder="e.g. 880000"
              inputRef={register}

            // onChange={(e) => setSelectedSortCode(e.target.value)}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const userConnectedAccounts = () => {
    return (
      <>
        {bankAccountLoading && "Loading..."}
        {!bankAccountLoading && (!bankAccounts || bankAccounts.length === 0) && "No data found"}
        {!bankAccountLoading &&
          bankAccounts &&
          bankAccounts
            .filter((acct) => currency(acct.balance).value > currency(totalAmountToBePaid).value && acct.account_type === "TRANSACTION")
            .map((acct) => {
              return (
                <Card
                  variant="outlined"
                  style={{
                    display: "inline-block",
                    marginLeft: "50px",
                    width: "150px",
                    marginTop: "10px",
                  }}
                >
                  <input
                    id={acct.id}
                    name="selectedBankAccountID"
                    value={acct.id}
                    checked={selectedBankAccount === acct["id"]}
                    type="radio"
                    onChange={(e) => handleSelectBankAccount(e)}
                  />
                  <label for="selectedBankAccountID">{acct.provider_name}</label>

                  <CardMedia align="center">
                    <Avatar alt="Remy Sharp" src={acct.logo} />
                  </CardMedia>
                  <CardContent>
                    <Typography variant="h6" align="center" style={{ fontSize: "15px" }}>
                      {acct.account_number}
                    </Typography>
                    <Typography variant="subtitle1" align="center" style={{ fontSize: "12px" }}>
                      {acct.sort_code}
                    </Typography>{" "}
                    <Typography variant="subtitle1" align="center" style={{ fontSize: "12px", color: "darkgreen" }}>
                      Balance:
                      <strong> {acct.balance}</strong>
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
      </>
    );
  };

  const ReviewDetails = () => {
    return (
      <div style={{ marginLeft: "50px", fontWeight: "bold" }}>
        <Typography>Account Number: {selectedAccountNumber}</Typography>
        <Typography>Sort Code: {selectedSortCode}</Typography>
        <Typography>
          <strong>Amount to Pay: </strong>
          {totalAmountToBePaid}
        </Typography>
      </div>
    );
  };

  const renderSteps = () => {
    return (
      <>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <>
          <>
            {getStepContent(activeStep)}
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={() => handleBack()} className={classes.button}>
                  Back
                </Button>
              )}
              {/* {activeStep === steps.length && diplayYouWillBeRedirectMessage()} */}
              <Button variant="contained" color="primary" onClick={handleSubmit((data) => handleNext(data))} className={classes.button}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </>
        </>
      </>
    );
  };
  console.log(selectedBankAccount)
  useEffect(() => {
    if (activeStep === 1) {
      if (selectedOption === "fromConnectedAccounts") {
        if (selectedBankAccount) {
          let selectedAccount = bankAccounts.filter((acct) => acct.id === selectedBankAccount);
          setSelectedAccountNumber(selectedAccount[0].account_number);
          setSelectedSortCode(selectedAccount[0].sort_code.replace(/-/g, ""));
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedBankAccount]);

  return (
    <DialogComponent open={open} setOpen={setOpen} title="Make Payment" description="" withActions={false}>
      {renderSteps()}
      <LoadingDialog open={openLoadingDialog} setOpen={setOpenLoadingDialog} loadingText="You will be redirected to payment provider!" backgroundColor={"lightBlue"} />
      <PaymentConfirmationDialog open={openPaymentConfirmDialog} setOpen={setOpenPaymentConfirmDialog} onSubmit={handleSubmitPayment} />

      {/* {activeStep === steps.length -1 && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => handleNext(data))}
            className={classes.button}
          >
            Finishsub
          </Button>
        </div>
      )} */}

      {/* <button onClick={handleSubmit((data) => handleNext(data))}></button> */}
    </DialogComponent>
  );
};

export default MakePaymentDialog;
