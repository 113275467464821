import * as yup from "yup";

// function checkIfFilesAreTooBig(files) {
//   let valid = true;
//   if (files) {
//     files.map((file) => {
//       const size = file.size / 1024 / 1024;
//       if (size > 10) {
//         valid = false;
//       }
//     });
//   }
//   return valid;
// }

// function checkIfFilesAreCorrectType(files) {
//   let valid = true;
//   if (files) {
//     files.map((file) => {
//       if (!["application/pdf", "image/jpeg", "image/png"].includes(file.type)) {
//         valid = false;
//       }
//     });
//   }
//   return valid;
// }

// const FILE_SIZE = 160 * 1024;
// const SUPPORTED_FORMATS = [
//   "image/jpg",
//   "image/jpeg",
//   "image/gif",
//   "image/png",
//   "application/pdf",
// ];

const addressSchema = yup.object().shape({
  address_line_1: yup.string().required("Please enter your address."),
  country: yup.string().required("Please enter country"),
  city: yup.string().required("Please enter city"),
  post_code: yup.string().required("Please enter post code"),
});

const businessSchema = yup.object().shape({
  // company_reg_number: yup.string().required("Please enter company reg number"),
  business_name: yup.string().required("Please enter company name"),

  business_model: yup.string().required("Please choose business model"),
  business_type: yup.string().required("Please choose business type"),
  business_email: yup
    .string()
    .email()
    .required("Please enter business email"),
  business_phone: yup.string().required("Please enter business phone"),

  address_line_1: yup.string().required("Address is required"),
});

const companyCertAndPlatformUsage = yup.object().shape({
  company_certificate: yup
    .mixed()
    .required("Company certificate is required")

    .test("fileSize", "Maximum allowed Company certificate size is 5 MB", (value) => {
      if (!value.length) return false;
      return value[0].size < 5242880;
    }),
});
const bankAccountSchema = yup.object().shape({
  bank_statement: yup.mixed().test("fileSize", "Maximum allowed Bank statement size is 5 MB", (value) => {
    if (!value.length) return false;
    return value[0].size < 5242880;
  }),
  account_number: yup
    .string()
    .required("Account number required")
    .test("len", "Account number must be exactly 8 characters", (val) => val.length === 8),
  sort_code: yup
    .string()
    .required("Sort code required")
    .test("len", "Sort code must be exactly 6 characters", (val) => val.length === 6),

  // bank_statement: yup
  // .mixed()
  // .required("Bank statement required")
  // .test(
  //   "fileSize",
  //   "File too large",
  //   value => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   value => value && SUPPORTED_FORMATS.includes(value.type)
  // )
});
const paymentBankAccount = yup.object().shape({
  account_number: yup
    .string()
    .required("Account number required")
    .test("len", "Account number must be exactly 8 characters", (val) => val.length === 8),
  sort_code: yup
    .string()
    .required("Sort code required")
    .test("len", "Sort code must be exactly 6 characters", (val) => val.length === 6),

  // bank_statement: yup
  // .mixed()
  // .required("Bank statement required")
  // .test(
  //   "fileSize",
  //   "File too large",
  //   value => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   value => value && SUPPORTED_FORMATS.includes(value.type)
  // )
});

const transferMoneySchema = yup.object().shape({
  beneficiary_name: yup.string().required("Beneficiary name required"),
  beneficiary_account_number: yup
    .string()
    .required("Account number required")
    .test("len", "Account number must be exactly 8 characters", (val) => val.length === 8),
  beneficiary_sort_code: yup
    .string()
    .required("Sort code required")
    .test("len", "Sort code must be exactly 6 characters", (val) => val.length === 6),
  remitter_account_number: yup
    .string()
    .required("Account number required")
    .test("len", "Account number must be exactly 8 characters", (val) => val.length === 8),
  remitter_sort_code: yup
    .string()
    .required("Sort code required")
    .test("len", "Sort code must be exactly 6 characters", (val) => val.length === 6),
  amount: yup.string().required("Please enter amount to be paid."),
  remitter_name: yup.string().required("Beneficiary name required"),
});

const directorSchema = yup.object().shape({
  passport_number: yup
    .string()
    .max(250, "You have exceed max length")
    .required("Passport or National ID Required"),
  full_name: yup
    .string()
    .max(250, "You have exceed max length")
    .required("Name Required"),
  email: yup
    .string()
    .max(250, "You have exceed max length")
    .email()
    .required("Email required"),
  director_address_line_1: yup
    .string()
    .max(250, "You have exceed max length")
    .required("Address required"),
  director_country: yup
    .string()
    .max(250, "You have exceed max length")
    .required("Country is required fied"),
});

const directorPassportSchema = yup.object().shape({
  passport_number: yup.string().required("Passport or National ID required"),
});

const directorFilesSchema = yup.object().shape({
  id_front_page: yup
    .mixed()
    .required("Front Page of ID is required")
    .test("fileSize", "Maximum allowed Front Page size is 5 M", (value) => {
      if (!value.length) return false;
      return value[0].size < 5242880;
    }),
  id_rear_page: yup
    .mixed()
    .required("Rear Page of ID is required")
    .test("fileSize", "Maximum allowed Rear Page size is 5 M", (value) => {
      if (!value.length) return false;
      return value[0].size < 5242880;
    }),
  address_proof: yup
    .mixed()
    .required("Proof of address is required")
    .test("fileSize", "Maximum allowed Proof of address size is 5 M", (value) => {
      if (!value.length) return false;
      return value[0].size < 5242880;
    }),
});

const idPhotos = yup.object().shape({
  id_front_page: yup
    .mixed()
    .required("Front Page of ID is required")
    .test("fileSize", "Maximum allowed Front Page size is 4 M", (value) => {
      if (!value.length) return false;
      return value[0].size < 5242880;
    }),
  id_rear_page: yup
    .mixed()
    .required("Rear Page of ID is required")
    .test("fileSize", "Maximum allowed Rear Page size is 4 M", (value) => {
      if (!value.length) return false;
      return value[0].size < 5242880;
    }),
});
const paymentSchema = yup.object().shape({
  supplierBankAccount: yup.string().required("Please select a bank account of the supplier."),
  businessBankAccount: yup.string().required("Please select the bank account to make payment from."),
  amount: yup.number().required("Please enter amount to be paid."),
  currency: yup.string().required("Please select the currency."),
});

const businessDocumentSchema = yup.object().shape(
  {
    boardDecision: yup.string().when("isExecutive", {
      is: false,
      then: yup.string().required("You must provide board decision if you are not an executive of the company"),
    }),
    idFront: yup.string().required("You must provide front of your id document."),
    idRear: yup.string().required("You must provide back of your id document."),
    signatureCircular: yup.string().required("You must provide Signature circular."),
    taxSignboard: yup.string().required("You must provide tax sign board."),
    termsAndConditionAccepted: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
  },
  ["boardDecision", "isExecutive"]
);

const personalBusinessDocumentSchema = yup.object().shape({
  idFront: yup.string().required("You must provide front of your id document."),
  idRear: yup.string().required("You must provide back of your id document."),
  termsAndConditionAccepted: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});

const companyContactInformation = yup.object().shape({
  full_name: yup.string().required("Representative name missing"),
  email: yup
    .string()
    .email()
    .required("Representative email missing"),
  phone: yup.number().required(),
  // .test("wrong format", "Not a valid phone number", (val) => {
  //   console.log(val.toString().length);
  //   return val.toString().length > 10;
  // }),
  position: yup.string().required("Representative position missing"),
  // taxAdmin: yup.mixed().required("Tax administration missing"),
  // taxIdNo: yup.mixed().required("Tax id number missing"),
  // country: yup.string().required("Country information missing"),
  // city: yup.string().required("City information missing"),
  // name: yup.string().required("Business name missing"),
  // business_type: yup.string().required("Business type missing"),
  // business_model: yup.string().required("Business model missing"),
  // dateOfBirth:
});

const personalBusinessSchemaFirstStep = yup.object().shape({
  passport_number: yup.string().required("Passport number required"),
});
const personalBusinessSchemaSecondStep = yup.object().shape({
  name: yup.string().required("Company name or Full name required"),
  business_type: yup.string().required("Business type missing"),
  business_model: yup.string().required("Business model missing"),
  address_line_1: yup.string().required("Adress Line required"),
  address_line_2: yup.string().required("Adress Line required"),
  post_code: yup.string().required("Post code required"),
  country: yup.string().required("Country required"),
  city: yup.string().required("City required"),
  region_province: yup.string().required("Region required"),
});
const personalBusinessSchemaThirdStep = yup.object().shape({
  full_name: yup.string().required("Full name required"),
  email: yup.string().required("Email required"),

  // dateOfBirth:
});

const businessSchemaSecondStep = yup.object().shape(
  {
    name: yup.string().required("Company name required"),
    business_type: yup.string().required("Business type missing"),
    business_model: yup.string().required("Business model missing"),
    address_line_1: yup.string().required("Adress Line required"),
    address_line_2: yup.string().required("Adress Line required"),
    post_code: yup.string().required("Post code required"),
    country: yup.string().required("Country required"),
    city: yup.string().required("City required"),
    currency: yup.string().required("Currency required"),
    region_province: yup.string().required("Region required"),
    company_reg_number: yup.string().required("Crn required"),
    // taxIdNo: yup.string().when(['nino', 'utrNo'], {
    //   is: (nino, utrNo) => !nino && !utrNo,
    //   then: yup.string().required('Please enter one of the three fields')
    // }),
    // nino: yup.string().when(['taxIdNo', 'utrNo'], {
    //   is: (taxIdNo, utrNo) => !taxIdNo && !utrNo,
    //   then: yup.string().required('Please enter one of the three fields')
    // }),
    // utrNo: yup.string().when(['taxIdNo', 'nino'], {
    //   is: (taxIdNo, nino) => !taxIdNo && !nino,
    //   then: yup.string().required('Please enter one of the three fields')
    // })
    // dateOfBirth:
  },
  [
    ["taxIdNo", "nino"],
    ["taxIdNo", "utrNo"],
    ["nino", "utrNo"],
  ]
);

let businessSchemaFirstStep = yup.object().shape({
  company_reg_number: yup.string().required("Company number required"),
  passport_number: yup.string().required("Passport number required"),
});
//TODO paid amount should be smaller or equal to amount validation required!
let outgoingInvoiceSchema = yup.object().shape({
  customer: yup
    .string()
    .notOneOf(["null"], "Please create a customer.")
    .required("Please select a customer."),
  amount_to_be_paid: yup.string().required("Please enter total amount."),
});

//TODO paid amount should be smaller or equal to amount validation required!
const incomingInvoiceSchema = yup.object().shape({
  supplier: yup
    .string()
    .notOneOf(["null"], "Please create a customer.")
    .required("Please select a supplier."),
  amount_to_be_paid: yup.number().required("Please enter total amount."),
});

const invoiceLineSchema = yup.object().shape({
  product: yup
    .string()
    .notOneOf(["null"], "Please select a product.")
    .required("Please select a product."),
  product_quantity: yup
    .number()
    .typeError("Should be a positive integer.")
    .positive("Quantity must be greater than zero.")
    .required("Quantity is required."),
});

const productSchema = yup.object().shape({
  product_name: yup.string().required("Please enter a product or service name."),
  unit_price: yup.string().required("Please enter unit price"),
  currency: yup.string().required("Please enter currency"),
});

const bankDetailsSchema = yup.object().shape({
  institution: yup.string().required("Please select an institution."),
  accountName: yup.string().required("Please enter an Account Name."),
  accountFormat: yup.string().required(),
  accountValue: yup.string().required("Please enter a valid account nubmer."), //limit to 16 digits/letters
  agreementDocument: yup.string().required("Please sign and upload the agreement document."),
});

const customerSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Please enter a company or full name."),
});

const supplierSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Please enter a company or full name."),
  erp_no: yup.string().required("Customer id number in your system"),
});

const invitationSignupSchema = yup.object().shape({
  inviteCode: yup.string().required("Invitation code should be provided"),
  name: yup.string().required("Please Enter your first name"),
  surname: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter a valid email"),
  // birthdate: yup.date("Plase enter valid date")
  // .required()
  // .test("age", "You must be 18 or older", function(birthdate) {
  //   const cutoff = new Date();
  //   cutoff.setFullYear(cutoff.getFullYear() - 18);
  //   return birthdate <= cutoff;
  // }),

  // .test("wrong format", "Not a valid phone number", (val) => {
  //   console.log(val.toString().length);
  //   return val.toString().length > 10;
  // }),
  termsAndConditionAccepted: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
  password: yup
    .string()
    .required("No password provided.")
    .min(12, "Password is too short - should be 12 chars minimum.")
    .max(36, "Password should be less than 30 chars")
    .matches(/[a-z]+/, "Password should contain at least one lowercase character")
    .matches(/[A-Z]+/, "Password should contain at least one uppercase character")
    .matches(
      //eslint-disable-next-line
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
      "Password should contain at least one special character"
    )
    .matches(/\d+/, "Password should contain at least one number"),

  confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

const invitationSigninSchema = yup.object().shape({
  inviteCode: yup.string().required("Invitation code should be provided"),
  email: yup
    .string()
    .email()
    .required("Please enter a valid email"),
  password: yup.string().required("Password is required"),
});
const passwordResetSchema = yup.object().shape({
  password: yup
    .string()
    .required("No password provided.")
    .min(12, "Password is too short - should be 12 chars minimum.")
    .max(36, "Password should be less than 36 chars")
    .matches(/[a-z]+/, "Password should contain at least one lowercase character")
    .matches(/[A-Z]+/, "Password should contain at least one uppercase character")
    .matches(
      //eslint-disable-next-line
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
      "Password should contain at least one special character"
    )
    .matches(/\d+/, "Password should contain at least one number"),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});
const signUpSchema = yup.object().shape({
  name: yup.string().required("Please Enter your first name"),
  surname: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email()
    .required("Please enter a valid email"),

  usageType: yup.string().required("Please choose a valid usage type!"),
  // birthdate: yup.date("Plase enter valid date")
  //   .required()
  //   .test("age", "You must be 18 or older", function(birthdate) {
  //     const cutoff = new Date();
  //     cutoff.setFullYear(cutoff.getFullYear() - 18);
  //     return birthdate <= cutoff;
  //   }),
  // .test("wrong format", "Not a valid phone number", (val) => {
  //   console.log(val.toString().length);
  //   return val.toString().length > 10;
  // }),
  password: yup
    .string()
    .required("No password provided.")
    .min(12, "Password is too short - should be 12 chars minimum.")
    .max(36, "Password should be less than 36 chars")
    .matches(/[a-z]+/, "Password should contain at least one lowercase character")
    .matches(/[A-Z]+/, "Password should contain at least one uppercase character")
    .matches(
      //eslint-disable-next-line
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
      "Password should contain at least one special character"
    )
    .matches(/\d+/, "Password should contain at least one number"),

  termsAndConditionAccepted: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
  confirm_password: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

const customerExcelFieldSchema = yup.object().shape({
  name: yup.string(),
});

const addFinanceAccountSchema = yup.object().shape({
  repFullName: yup.string().required("Please Enter full name"),
  email: yup
    .string()
    .email()
    .required("Please enter a valid email"),
  phone: yup.number().required(),
  bankName: yup.string().required("Please Enter bank name"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

const financingOfferSchema = yup.object().shape({
  advance_payment_amount: yup.string().required("Please Enter advance payment amount"),
  advance_payment_maturity: yup.string().required("Please enter advance payment maturity"),
  advance_payment_maturity_type: yup.string().required("Please Enter advance payment maturity type"),
  advance_payment_percentage: yup.string().required("Please Enter advance payment percentage"),
  factoring_expense_amount: yup.string().required("Please Enter factoring expense amount"),
  factoring_expense_percentage: yup.string().required("Please Enter factoring expense percentage"),
  remaining_payment_amount: yup.string().required("Please Enter remaining payment amount"),
  remaining_payment_maturity: yup.string().required("Please Enter remaining payment maturity"),
  remaining_payment_maturity_type: yup.string().required("Please Enter remaining payment maturity type"),
  remaining_payment_percentage: yup.string().required("Please Enter remaining payment percentage"),
  valid_until: yup.string().required("Please Enter validity date"),
});

const financingInformationSchema = yup.object().shape({
  turnover_amount: yup.string().required("Please Enter turnover amount"),
  currency: yup.string().required("Please enter turnover currency"),
  year: yup.string().required("Please enter turnover year"),
  number_of_employee: yup.string().required("Please enter number of employee"),
});

const memberSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Please Enter turnover amount"),
  name: yup.string().required("Please enter name"),
  surname: yup.string().required("Please enter surname"),
});

export {
  addressSchema,
  businessSchema,
  bankAccountSchema,
  companyCertAndPlatformUsage,
  directorSchema,
  directorPassportSchema,
  directorFilesSchema,
  paymentBankAccount,
  transferMoneySchema,
  businessDocumentSchema,
  companyContactInformation,
  businessSchemaFirstStep,
  businessSchemaSecondStep,
  personalBusinessSchemaThirdStep,
  personalBusinessSchemaFirstStep,
  personalBusinessSchemaSecondStep,
  personalBusinessDocumentSchema,
  incomingInvoiceSchema,
  invoiceLineSchema,
  customerSchema,
  supplierSchema,
  bankDetailsSchema,
  productSchema,
  outgoingInvoiceSchema,
  paymentSchema,
  customerExcelFieldSchema,
  signUpSchema,
  invitationSignupSchema,
  invitationSigninSchema,
  passwordResetSchema,
  addFinanceAccountSchema,
  financingOfferSchema,
  financingInformationSchema,
  memberSchema,
  idPhotos
};
