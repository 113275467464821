import axios from "axios";
import { API_BASE } from "../env";
import { clearErrors, setErrors, setSuccess, startLoading } from "./uiActions";

export const addBankAccount = (details) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    let business = await axios({
      method: "post",
      url: `${API_BASE}/add-bank-account`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });

    let authUrl = business.data.authUrl;
    dispatch(getSelfBankAccounts());
    dispatch(setSuccess("Added new bank account."));
    dispatch(clearErrors());
    window.location.href = authUrl;
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeBankAccount = (acctId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios({
      method: "post",
      url: `${API_BASE}/remove-bank-account`,
      data: acctId,
      headers: { "Content-Type": "multipart/form-data" },
    });

    dispatch(getSelfBankAccounts());
    dispatch(setSuccess("Removed a bank account."));
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const submitAispConsent = (consent) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/aisp-consent`, { consent });
    dispatch(clearErrors());
    dispatch(getSelfBankAccounts());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const makeCheckoutPayment = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await (await axios.post(`${API_BASE}/make-checkout-payment`, details)).data;

    if (response.authUrl) {
      window.location.href = response.authUrl;
    }

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const makePaymentRequest = (details) => async (dispatch) => {
  try {
    dispatch({ type: "PAYMENT_REQUEST_LOADING" });
    let response = await axios.post(`${API_BASE}/v3/payment`, details);

    if (response) {
      dispatch({
        type: "PAYMENT_REQUEST_FETCH_SUCCESS",
        payload: response.data,
      });
      window.location.href = response.data.data.hpp_url || response.data.data.auth_uri;
    }
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getUserRemainingBalance = () => async (dispatch) => {
  try {
    let response = await axios.get(`${API_BASE}/banking/daily-remaining-balance`);

    if (response) {
      dispatch({ type: "SET_REMAINING_BALANCE", payload: response.data });
    }

    // if (response.authUrl) {
    //   window.location.href = response.authUrl;
    // }

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const checkoutPaymentRequest = (details) => async (dispatch) => {
  console.log(details);
  try {
    dispatch({ type: "PAYMENT_REQUEST_LOADING" });
    let response = await axios.post(`${API_BASE}/v1/banking/checkout-payment`, details);

    if (response) {
      dispatch({
        type: "PAYMENT_REQUEST_FETCH_SUCCESS",
        payload: response.data,
      });
    }

    console.log("response", response);
    // if (response.authUrl) {
    //   window.location.href = response.authUrl;
    // }

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getPaymentResult = (code) => async (dispatch) => {
  console.log("code", code);
  try {
    dispatch({ type: "PAYMENT_RESULT_LOADING" });

    let response = await axios.get(`${API_BASE}/v3/payment/${code}`);

    console.log("response", response);
    dispatch({
      type: "PAYMENT_RESPONSE_FETCH_SUCCESS",
      payload: response.data,
    });

    dispatch(clearErrors());
  } catch (err) {
    dispatch({ type: "PAYMENT_RESPONSE_FETCH_FAILED" });
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getCheckoutPaymentResult = (code) => async (dispatch) => {
  console.log("code", code);
  try {
    dispatch({ type: "PAYMENT_RESULT_LOADING" });

    let response = await axios.get(`${API_BASE}/v1/banking/checkout-payment-result`, {
      params: {
        simp_id: code,
      },
    });

    console.log("response", response);
    dispatch({
      type: "PAYMENT_RESPONSE_FETCH_SUCCESS",
      payload: response.data,
    });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getPackagePaymentResult = (code) => async (dispatch) => {
  try {
    dispatch({ type: "PAYMENT_RESULT_LOADING" });

    let response = await axios.get(`${API_BASE}/v1/packages/payment-result`, {
      params: {
        simp_id: code,
      },
    });

    console.log("response", response);
    dispatch({
      type: "PAYMENT_RESPONSE_FETCH_SUCCESS",
      payload: response.data,
    });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const transferMoneyRequest = (details) => async (dispatch) => {
  console.log(details);
  try {
    dispatch({ type: "TRANSFER_MONEY_REQUEST_LOADING" });
    let response = await axios.post(`${API_BASE}/v1/banking/transfer-money`, details);

    if (response) {
      dispatch({
        type: "TRANSFER_MONEY_REQUEST_FETCH_SUCCESS",
        payload: response.data,
      });
    }

    console.log("response", response);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getMoneyTransactions = () => async (dispatch) => {
  try {
    dispatch({ type: "TOKEN_LOGS_LOADING" });
    let response = await axios.get(`${API_BASE}/v1/banking/money-transactions`);

    if (response) {
      dispatch({ type: "TRANSACTIONS_FETCH_SUCCESS", payload: response.data });
    }

    console.log("response", response);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getPayedaTokenLogs = ({ ...params }) => async (dispatch) => {
  try {
    dispatch({ type: "TOKEN_LOGS_LOADING" });
    let response = await axios.get(`${API_BASE}/v1/account/token-spendings`, {
      params: {
        perPage: params.perPage ? params.perPage : 10,
        page: params.page ? params.page : 1,
        searchField: params.search ? params.search : "",
      },
    });

    if (response) {
      dispatch({ type: "TOKEN_LOGS_FETCH_SUCCESS", payload: response.data });
    }

    console.log("response", response);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getBankingLogs = () => async (dispatch) => {
  try {
    dispatch({ type: "BANKING_LOGS_LOADING" });
    let response = await axios.get(`${API_BASE}/v1/banking/banking-logs`);

    if (response) {
      dispatch({ type: "BANKING_LOGS_FETCH_SUCCESS", payload: response.data });
    }

    console.log("response", response);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const submitPispConsent = (consentId, bankingId) => async (dispatch) => {
  console.log(consentId);
  try {
    let consentResponse = await axios.post(`${API_BASE}/pisp-consent`, {
      consentId,
      bankingId,
    });

    console.log("consentResponse");
    console.log(consentResponse);

    dispatch({
      type: "SET_PAYMENT_RESULT",
      payload: consentResponse.data.payment,
    });
    console.log("consentResponse", consentResponse);
    dispatch(clearErrors());
    // // dispatch(getIncomingInvoices());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getBankAuthUrl = () => async (dispatch) => {
  try {
    dispatch({ type: "BANKING_AUTH_URL_LOADING" });
    let response = await axios.get(`${API_BASE}/v1/bank-auth-url`);

    if (response) {
      dispatch({ type: "BANKING_AUTH_URL_SUCCESS", payload: response.data });
    }

    console.log("response", response);
    // if (response.authUrl) {
    //   window.location.href = response.authUrl;
    // }

    dispatch(clearErrors());
  } catch (err) {
    dispatch({ type: "BANKING_AUTH_URL_FAILED" });

    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getTransactions = ({ ...params }) => async (dispatch) => {
  console.log("trans", params);
  try {
    dispatch({ type: "TRANSACTION_FETCH_STARTED" });
    let response = await axios.get(`${API_BASE}/v1/transactions`, {
      params: {
        bank_account_id: params[0]?.bank_account_id ? params[0]?.bank_account_id : null,
        from: params[0]?.from ? params[0]?.from : null,
        to: params[0]?.to ? params[0]?.to : null,
        id: params[0]?.id ? params[0]?.id : null,
      },
    });

    if (response) {
      dispatch({ type: "TRANSACTION_FETCH_SUCCESS", payload: response.data });
    }

    console.log("response", response);
    // if (response.authUrl) {
    //   window.location.href = response.authUrl;
    // }

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const exchanceCodeForTrueLayerToken = (code, userId) => async (dispatch) => {
  try {
    dispatch({ type: "EXHANCE_CODE_LOADING" });
    let response = await axios.post(`${API_BASE}/v1/exchangeCode`, { exchangeCode: code, userId: userId });
    console.log("response", response);
    if (response) {
      dispatch({ type: "EXHANCE_CODE_SUCCESS" });
    }

    console.log("response", response);
    // if (response.authUrl) {
    //   window.location.href = response.authUrl;
    // }
  } catch (err) {
    dispatch({ type: "EXHANCE_CODE_FAILED" });
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getSelfBankAccounts = () => async (dispatch) => {
  try {
    dispatch({ type: "BANKING_LOADING" });

    let bankAccounts = await axios.get(`${API_BASE}/v1/bank-accounts`);
    let data = bankAccounts.data;
    dispatch({ type: "BANK_ACCOUNTS_DATA_FETCH_SUCCESS", payload: data });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
    dispatch({ type: "STOP_LOADING_UI" });
  }
};

export const getUserBankAccounts = (businessId) => async (dispatch) => {
  try {
    dispatch({ type: "BANKING_LOADING" });

    let bankAccounts = await axios.get(`${API_BASE}/banking/bank-accounts`, {
      params: {
        businessId: businessId,
      },
    });

    let data = bankAccounts.data;
    dispatch({ type: "USER_BANK_ACCOUNTS_DATA_FETCH_SUCCESS", payload: data });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
    dispatch({ type: "STOP_LOADING_UI" });
  }
};

export const getAvailableInstitutions = () => async (dispatch) => {
  try {
    // dispatch({ type: 'LOADING_UI' });
    let institutions = await (await axios.get(`${API_BASE}/get-available-institutions`)).data;

    if (institutions && institutions.length > 0) {
      dispatch({ type: "SET_AVAILABLE_INSTITUTIONS", payload: institutions });
      dispatch({ type: "CLEAR_ERRORS" });
    } else {
      dispatch({
        type: "SET_ERRORS",
        payload: "Error while fetching available institutions.",
      });
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
    if (err.response) {
      dispatch({
        type: "SET_ERRORS",
        payload: err.response.data,
      });
    } else {
      dispatch({
        type: "SET_ERRORS",
        payload: { general: "Something unexpected happened." },
      });
    }
  }
};

export const setReceivePaymentAccount = (data) => async (dispatch) => {
  try {
    // dispatch({ type: 'LOADING_UI' });
    await axios.post(`${API_BASE}/banking/select-payment-account`, data);
    dispatch(setSuccess("ok"));
  } catch (err) {
    console.log(err);
    console.log(err.response);
    if (err.response) {
      dispatch({
        type: "SET_ERRORS",
        payload: err.response.data,
      });
    } else {
      dispatch({
        type: "SET_ERRORS",
        payload: { general: "Something unexpected happened." },
      });
    }
  }
};
