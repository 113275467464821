import React, { useEffect } from 'react';
import './delete.css';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import { useDispatch } from 'react-redux';
import { deleteAccount, getUser } from '../store/actions/userActions';

const DeleteAccount = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    const handleGetUser = async (e) => {
        e.preventDefault()

        const formData = new FormData(e.target);
        const email = formData.get("email");
        const password = formData.get("password");
        let { status, token } = await dispatch(getUser({ email: email, password: password }))
        console.log("response token0", status, token);
        if (status === 200) {

            let deleteResponse = await dispatch(deleteAccount({ email: email }, token));
            if (deleteResponse === 202)
                MySwal.fire({
                    icon: 'success',
                    title: 'DELETE ACCOUNT REQUEST',
                    text: 'Your account delete request successfully received',
                });
            e.target.reset();

        }
    }

    const showConfirmationAlert = (e) => {
        e.preventDefault();
        MySwal.fire({
            title: 'Are you sure to delete?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                handleGetUser(e);
            } else {
                console.log('Deletion cancelled');
            }
        });
    };

    // useEffect(() => {
    //     function adjustContainerHeight() {
    //         var outerContainer = document.querySelector('.outer-container');
    //         var windowHeight = window.innerHeight;
    //         outerContainer.style.height = windowHeight + 'px';
    //     }

    //     window.addEventListener('resize', adjustContainerHeight);
    //     window.addEventListener('DOMContentLoaded', adjustContainerHeight);
    // }, [])
    return (



        <div className="outer-container" style={{ overflow: "auto", maxHeight: "800px" }}>
            <div className="center-div">
                <h3>DELETE ACCOUNT</h3>
                <p>
                    If you are sure that you want to delete your account, please enter your email and password below, and then press Continue.
                </p>
                <p>
                    Before you make the decision to delete your account, we recommend considering the following points:
                </p>
                <ul className="list">
                    <li>
                        Once you delete your account, all your data will be permanently erased, including your profile information, invoices, receipts, connected bank accounts, and other personal details.
                    </li>
                    <li>
                        The deleted data cannot be retrieved, and the username and password you used to access your account will no longer be valid.
                    </li>
                    <li>
                        Please remember that once you delete your account, this decision cannot be reversed.
                    </li>
                    <li>
                        If you have any questions or concerns regarding this matter, please feel free to reach out to us. We'll be happy to assist you.
                    </li>
                </ul>
                <form className="form-container" action="#" onSubmit={(e) => showConfirmationAlert(e)}>
                    <div className="input-container">
                        <input type="text" id="email" name="email" className="outlined-input" placeholder="Email" />
                    </div>
                    <div className="input-container">
                        <input type="password" id="password" name="password" className="outlined-input" placeholder="Password" />
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default DeleteAccount;
