import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../../Components/BasePage";
import TableComponent from "../../../../Components/Table/TableComponent";
import { getTransactions } from "../../../../store/actions/bankingActions";
import DropdownComponent from "./../../../../Components/DropdownComponent";
import { useUiContext } from "./../../../../Contexts/UiContext";

const headCells = [
  { id: "timestamp", label: "Date" },
  { id: "description", label: "Description" },
  { id: "transaction_type", label: "transaction_type" },
  { id: "amount", label: "Amount" },
];
const searchOptions = [{ default: true, id: "provider_name", label: "Provider" }];
const BankAccounts = ({ business }) => {
  const { register, control } = useForm();
  const { errors } = useUiContext();

  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  let [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  const bankAccounts = useSelector((state) => state.banking.bankAccounts);

  const transactions = useSelector((state) => state.banking.transactions);
  const transactionLoading = useSelector((state) => state.banking.isLoading);
  const user = useSelector((state) => state.user);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const getSelectedTransactions = () => {
    let selectedAccountInfo = bankAccounts.data.find((accounts) => accounts.id === selectedAccount);

    const params = [
      {
        from: selectedIssuDate.toISOString().split("T")[0],
        to: selectedDueDate.toISOString().split("T")[0],
        bank_account_id: selectedAccountInfo.id,
        id: selectedAccountInfo.connected_account_id,
      },
    ];
    dispatch(getTransactions(params));
  };

  const getAccountIds = () => {
    if (bankAccounts) {
      let bankAccountIds =
        bankAccounts.data &&
        bankAccounts.data.map((acct) => {
          return {
            value: acct.id,
            label: acct.provider_name + " - " + acct.display_name,
          };
        });
      return bankAccountIds;
    } else return [];
  };

  const renderAccounts = () => {
    return (
      <DropdownComponent
        required
        fullWidth
        id="bankAccountId"
        name="bankAccountId"
        label="Select an account"
        items={getAccountIds()}
        onChange={(e) => {
          setSelectedAccount(e.target.value);
        }}
        error={errors.bankAccountId ? true : false}
        helperText={errors.bankAccountId ?? ""}
        control={control}
      />
    );
  };
  const renderDate = () => {
    return (
      <Grid container spacing={3} style={{ marginBottom: "10px" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={6} sm={12} md={3} lg={3}>
            {renderAccounts()}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <KeyboardDatePicker
              inputVariant="outlined"
              margin="dense"
              id="issued_date"
              name="issued_date"
              label="From"
              value={selectedIssuDate}
              openTo="year"
              format="yyyy-MM-dd"
              size="small"
              views={["year", "month", "date"]}
              error={errors.issued_date ? true : false}
              helperText={errors.issued_date ?? false}
              inputRef={register}
              onChange={(date) => setSelectedIssueDate(date)}
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={6} sm={12} md={3} lg={3}>
            <KeyboardDatePicker
              maxDate={new Date()}
              margin="dense"
              inputVariant="outlined"
              id="due_date"
              name="due_date"
              label="To"
              value={selectedDueDate}
              openTo="year"
              format="yyyy-MM-dd"
              size="small"
              views={["year", "month", "date"]}
              error={errors.due_date ? true : false}
              helperText={errors.due_date ?? false}
              inputRef={register}
              onChange={(date) => setSelectedDueDate(date)}
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={6} sm={12} md={2} lg={2} style={{ marginTop: "8px" }}>
            <Button onClick={() => getSelectedTransactions()} variant="outlined">
              Submit
            </Button>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };
  return (
    <>
      <BasePage navigation="Dashboard/Transactions" business={business} canView={user?.permissions?.bankAccount?.view}>
        {renderDate()}

        {bankAccounts?.data && (
          <TableComponent
            //   toolbar={renderToolbar()}
            data={transactions?.data || []}
            selected={selected}
            setSelected={setSelected}
            headCells={headCells}
            isLoading={transactionLoading}
            searchOptions={searchOptions}
            shouldGetData={false}
            shouldPaginate={false}
            shouldView={true}
            numberOfPages={transactions?.data ? transactions?.data.length : 0}
          />
        )}
      </BasePage>
    </>
  );
};

export default BankAccounts;
