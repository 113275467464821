import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { TextField, Button, Typography, Link, Grid, IconButton, Tooltip, Checkbox, FormControlLabel } from "@material-ui/core";
import { useForm } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import Swal from "sweetalert2";
import DropdownComponent from "../../../../Components/DropdownComponent";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { customerSchema, outgoingInvoiceSchema, productSchema } from "../../../../util/yup/yupSchemas";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DialogComponent from "../../../../Components/Dialogs/DialogComponent";
import NumberFormatCustom from "../../../../Components/NumberFormatCustom";
import PaymentQRDialog from "../PaymentQRDialog";
import verifySchema from "../../../../util/yup/verifySchema";
import { bankAccountSchema } from "../../../../util/yup/yupSchemas";
import { updateReceivePaymentAccount } from "../../../../store/actions/invoiceActions";
import { useUiContext } from "../../../../Contexts/UiContext";
import { API_BASE, UI_API_BASE } from "../../../../store/env";
import { getAllProducts, addProduct } from "../../../../store/actions/productActions";
import { addCustomer, getCustomers } from "../../../../store/actions/customerActions";
import CustomerDialog from "../../../Customer/CustomerDialog";
import ProductDialog from "../../../Product/ProductDialog";
import currency from "currency.js";

const FastInvoiceDialog = ({ open, setOpen, setQRPaymentLink, setOpenQRCodeDialog, setCustomerHasEmail, setInvoice, userHasMembership }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, control } = useForm();
  const { errors, loading, clearErrors, setErrors } = useUiContext();
  const history = useHistory();

  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [openVatPercentageDialog, setOpenVatPercentageDialog] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const customers = useSelector((state) => state.customer.customers);
  const products = useSelector((state) => state.product.products);

  const [customerOption, setCustomerOption] = useState(false);
  const [productOption, setProductOption] = useState(false);
  const [createCustomerDialogOpen, setCreateCustomerDialogOpen] = useState(false);
  const [createProductDialog, setCreateProductDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [productAmount, setProductAmount] = useState(0);
  const [productDetails, setProductDetails] = useState("");
  const [shouldShowPaymentLink, setShouldShowPaymentLink] = useState(false);
  const [shouldShowQr, setShouldShowQr] = useState(false);
  const [vatExists, setVatExists] = useState(false);

  const business = useSelector((state) => state.business);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (Number(business?.account_settings?.invoice_settings?.vat_percentage) >= 0) {
      setVatExists(true)
    }
  }, [business])
  useEffect(() => {
    if (isLoading) renderLoadingAlert();
    else Swal.close();
  }, [isLoading]);

  useEffect(() => {
    if (selectedProduct) {
      let product = products.data.find((product) => product.id === selectedProduct);
      console.log("productt", product);

      let vatRate = currency(product.rate_of_vat)
        .divide(100)
        .format({ symbol: "" });

      let vatAmount = currency(product.unit_price)
        .multiply(vatRate)
        .format({ symbol: "" });
      console.log("vat amounttt", vatRate, vatAmount);

      let subtotalAmount = currency(product.unit_price)
        .add(vatAmount)
        .format({ symbol: "" });

      setProductAmount(subtotalAmount);
      setProductDetails(product);
      console.log("subtotalAmount", subtotalAmount);

      // let taxRate = currency("1")
      //   .add(vatRate)
      //   .format({ symbol: "" });
      // let amountWithoutTax = currency(product.unit_price)
      //   .divide(taxRate)
      //   .format({ symbol: "" });

      // let vatAmount = currency(product.unit_price)
      //   .subtract(amountWithoutTax)
      //   .format({ symbol: "" });
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (user.team_id && user.team_type === "Business") {
      dispatch(getCustomers({}));
      dispatch(getAllProducts({}));
    }

    // eslint-disable-next-line
  }, [user]);

  const formData = (data) => {
    let fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === "bank_statement" && value.item(0) !== null) {
        data[key] = value.item(0);
      }
    }
    for (var key in data) {
      fd.append(key, data[key]);
    }
    return fd;
  };

  const onSubmit = async (data) => {
    //TODO: userhasmembership check!//
    if (true) {
      try {
        let vatPercentage = business?.account_settings?.invoice_settings?.vat_percentage;
        if (!vatExists && !selectedProduct) {
          setErrors({ general: "Please specify Vat percentage" });
          return false;
        }

        if (selectedCustomer && customerOption === "selectCustomer") {
          let customer = customers.data.find((c) => c.id === selectedCustomer);
          if (customer.email) setCustomerHasEmail(true);
          data.customer_id = selectedCustomer;
          data.customer_name = customer.name;
        } else {
          data.isAnonymous = true;
        }
        if (data.amount_to_be_paid && !selectedProduct) {
          data.amount_to_be_paid = data.amount_to_be_paid.replaceAll(",", "");
        }
        console.log("daataaa", selectedProduct, productOption)
        if (selectedProduct && productOption === "selectProduct") {
          data.product_id = selectedProduct
        }
        // setOpen(false);
        setIsLoading(true);
        // let fd = await formData(data);
        let response = await axios.post(`${API_BASE}/v1/invoice/fast`, data);

        if (response.data.invoice_data) setInvoice(response?.data?.invoice_data);

        if (response?.data?.invoice_id) {
          Swal.close();
          setQRPaymentLink(`${UI_API_BASE}/checkout?id=${response?.data?.invoice_id}`);
          setIsLoading(false);
          setOpenQRCodeDialog(true);
          setOpen(false);
          setSelectedProduct("");
          setSelectedCustomer("");
          setProductAmount(0);
          setCustomerOption("");
          setProductOption("");
        }
      } catch (error) {
        setIsLoading(false);
        // setOpenQRCodeDialog(false);
        setErrors({ general: error.response.data.message })
        console.log("error", error);
      }
    } else {
      setErrors({ general: "Please buy membership " });
    }
  };

  const renderLoadingAlert = () => {
    Swal.fire({
      title: "Loading...",
      html: "Please wait...",

      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const VatPercentageComponent = () => {
    return (
      <>
        {!vatExists ? (
          <>
            <Typography>
              <Link
                onClick={() => {
                  setOpen(false);
                  history.push("/dashboard/settings?vat_percentage=true");
                }}
              >
                Please click here
              </Link>{" "}
              to determine the vat percentage
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              Vat Percentage is: <strong>{business?.account_settings?.invoice_settings?.vat_percentage}%</strong>
            </Typography>
          </>
        )}
      </>
    );
  };

  const handleCustomerOption = (e) => {
    let val = e.target.value;
    setCustomerOption(val);
  };

  const handleProductOption = (e) => {
    let val = e.target.value;
    setProductOption(val);
  };

  const renderCustomerSelection = () => {
    return (
      <form>
        <div style={{ marginTop: "30px", marginBottom: "18px" }}>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}></strong>
          </Typography>

          <Grid container>
            <Grid item xs={6}>
              <input
                id="selectCustomer"
                name="businessType"
                value="selectCustomer"
                checked={customerOption === "selectCustomer"}
                type="radio"
                onChange={(e) => handleCustomerOption(e)}
              />
              <label for="selectCustomer">Select from customers</label>
            </Grid>
            <Grid item xs={6}>
              <input
                id="anonymCustomer"
                name="businessType"
                value="anonymCustomer"
                checked={customerOption === "anonymCustomer"}
                type="radio"
                onChange={(e) => handleCustomerOption(e)}
              />
              <label for="anonymCustomer">Use customer as anonymous</label>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  };

  const renderProductSelection = () => {
    return (
      <form>
        <div style={{ marginTop: "30px", marginBottom: "18px" }}>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}></strong>
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              {" "}
              <input
                id="selectProduct"
                name="businessType"
                value="selectProduct"
                checked={productOption === "selectProduct"}
                type="radio"
                onChange={(e) => handleProductOption(e)}
              />
              <label for="selectProduct">Select from products</label>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <input id="onlyAmount" name="businessType" value="onlyAmount" checked={productOption === "onlyAmount"} type="radio" onChange={(e) => handleProductOption(e)} />
              <label for="onlyAmount">Enter only amount</label>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  };

  const renderCustomersList = () => {
    if (loading) {
      return [{ value: "null", label: "Loading..." }];
    }
    if (customers?.data && customers.data.length > 0) {
      let list = customers.data.map((cust) => {
        return { value: cust.id, label: cust.name };
      });
      return list;
    } else {
      return [{ value: "null", label: "No customers" }];
    }
  };

  const renderProductsList = () => {
    if (loading) {
      return [{ value: "null", label: "Loading..." }];
    }
    if (products?.data && products.data.length > 0) {
      console.log("products", products, products.data)
      let list = products.data.map((product) => {
        return { value: product.id, label: product.product_name };
      });
      return list;
    } else {
      return [{ value: "null", label: "No products" }];
    }
  };

  const createCustomerButton = () => {
    return (
      <Typography style={{ marginLeft: 70, color: "red" }}>
        Add New Customer
        <Tooltip title="Create Customer">
          <IconButton onClick={() => setCreateCustomerDialogOpen(true)}>
            <GroupAddIcon />
          </IconButton>
        </Tooltip>
      </Typography>
    );
  };

  const customerDropdown = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <DropdownComponent
            required
            id="customer"
            name="customer"
            label="Select a Customer"
            items={renderCustomersList()}
            error={errors.customer ? true : false}
            helperText={errors.customer ?? ""}
            onChange={(e) => {
              setSelectedCustomer(e.target.value);
            }}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          {createCustomerButton()}
        </Grid>
      </Grid>
    );
  };

  const createProductButton = () => {
    return (
      <Typography style={{ marginLeft: 70, color: "red" }}>
        Add New Product
        <Tooltip title="Create Product">
          <IconButton onClick={() => setCreateProductDialogOpen(true)}>
            <GroupAddIcon />
          </IconButton>
        </Tooltip>
      </Typography>
    );
  };

  const productDropDown = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <DropdownComponent
            required
            id="product"
            name="product"
            label="Select a Product"
            items={renderProductsList()}
            error={errors.product ? true : false}
            helperText={errors.product ?? ""}
            onChange={(e) => {
              setSelectedProduct(e.target.value);
            }}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          {createProductButton()}
        </Grid>
      </Grid>
    );
  };

  const onCreateNewCustomer = async (data) => {
    const valid = await verifySchema(customerSchema, data, clearErrors, setErrors);
    if (valid) {
      setCreateCustomerDialogOpen(false);
      dispatch(addCustomer(data));
    }
  };

  const onCreateNewProduct = async (data) => {
    data.currency = "GBP";
    const valid = await verifySchema(productSchema, data, clearErrors, setErrors);
    if (valid) {
      setCreateProductDialogOpen(false);
      dispatch(addProduct(data));
    }
  };

  const renderCheckBoxes = () => {
    return (
      <div style={{ width: "300px" }}>
        <FormControlLabel
          control={<Checkbox id="showQr" name="showQr" checked={shouldShowQr} onChange={() => setShouldShowQr(!shouldShowQr)} color="primary" />}
          label={<div>Show QR</div>}
        />

        <FormControlLabel
          control={
            <Checkbox
              id="showPaymentLink"
              name="showPaymentLink"
              checked={shouldShowPaymentLink}
              onChange={() => setShouldShowPaymentLink(!shouldShowPaymentLink)}
              color="primary"
            />
          }
          label={<div>Show payment link</div>}
        />
      </div>
    );
  };
  return (
    <DialogComponent open={open} setOpen={setOpen} title="Create Fast Invoice" withActions={false}>
      {renderCustomerSelection()}

      {customerOption === "selectCustomer" && customerDropdown()}

      {renderProductSelection()}
      {productOption === "selectProduct" && productDropDown()}
      {productOption === "onlyAmount" && (
        <div style={{ marginBottom: "30px", marginTop: "10px", marginLeft: "5px" }}>
          <VatPercentageComponent />
        </div>
      )}

      <div style={{ marginTop: "20px" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            margin="dense"
            id="issued_date"
            name="issued_date"
            label="Issue Date"
            value={selectedIssuDate}
            openTo="year"
            format="yyyy-MM-dd"
            size="small"
            views={["year", "month", "date"]}
            error={errors.issued_date ? true : false}
            helperText={errors.issued_date ?? false}
            inputRef={register}
            onChange={(date) => setSelectedIssueDate(date)}
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            margin="dense"
            inputVariant="outlined"
            id="due_date"
            name="due_date"
            label="Due Date"
            value={selectedDueDate}
            openTo="year"
            format="yyyy-MM-dd"
            size="small"
            views={["year", "month", "date"]}
            error={errors.due_date ? true : false}
            helperText={errors.due_date ?? false}
            inputRef={register}
            onChange={(date) => setSelectedDueDate(date)}
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>

        {productOption === "onlyAmount" && (
          <TextField
            margin="dense"
            variant="outlined"
            required
            autoFocus
            id="amount_to_be_paid"
            name="amount_to_be_paid"
            label="Amount (VAT Incl)"
            error={errors.amount_to_be_paid ? true : false}
            helperText={errors.amount_to_be_paid ?? ""}
            fullWidth
            inputRef={register}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        )}

        {productOption === "selectProduct" && productAmount && (
          <Typography variant="h6" style={{ marginTop: "10px", marginLeft: "5px" }}>
            Amount:
            <strong> {productAmount}</strong>
          </Typography>
        )}
      </div>

      <br />
      {/* {renderCheckBoxes()} */}
      <Button variant="outlined" disabled={isLoading} color="primary" fullWidth style={{ marginTop: "20px", marginBottom: "30px" }} onClick={handleSubmit(onSubmit)}>
        {isLoading ? "Loading" : "Generate QR Code and payment link"}
      </Button>
      <CustomerDialog
        open={createCustomerDialogOpen}
        setOpen={setCreateCustomerDialogOpen}
        title="Create new customer"
        description="Create new customer to start receiving payments."
        onSubmit={onCreateNewCustomer}
        business={business}
        errors={errors}
      />
      <ProductDialog
        open={createProductDialog}
        setOpen={setCreateProductDialogOpen}
        title="Create Product"
        description="Create a new product."
        onSubmit={onCreateNewProduct}
        errors={errors}
      />
    </DialogComponent>
  );
};

export default FastInvoiceDialog;
