import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Texfield from "@material-ui/core/TextField";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneInputComponent from "../../../Components/PhoneInputComponent";
import { useUiContext } from "../../../Contexts/UiContext";
import { addFinanceAccount, addNpcPromotion, addPromotion, getVerifiedBusinesses } from "../../../store/actions/adminActions";
import verifySchema from "../../../util/yup/verifySchema";
import { addFinanceAccountSchema, addressSchema } from "../../../util/yup/yupSchemas";
import { useAdminStyles } from "../AdminStyles";
import DropdownComponent from "../../../Components/DropdownComponent";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory } from "react-router-dom";
import { TextField, TextareaAutosize, Typography, IconButton, Tooltip, Checkbox, FormControlLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { v1 as uuidv1 } from "uuid";
import { setErrors } from "../../../store/actions/uiActions";

const AddPromotion = () => {
  const { register } = useForm();

  const { errors, sidebarOpen } = useUiContext();
  const dispatch = useDispatch();

  const classes = useAdminStyles();
  const [bankToken, setBankToken] = useState(0);
  const [paymentToken, setPaymentToken] = useState(0);
  const [validDays, setValidDays] = useState(0);
  const [promotionCode, setPromotionCode] = useState("");
  const [description, setDescription] = useState("");

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [features, setFeatures] = useState([]);
  const [informations, setInformations] = useState([]);

  const [newFeature, setNewFature] = useState();
  const [newInformation, setNewInformation] = useState();
  const [isRegisterGift, setIsRegisterGift] = useState(false);

  const inputEl = React.useRef(null);
  const inputElInfo = React.useRef(null);

  const handleAddFeature = () => {
    const feature = { id: uuidv1(), name: newFeature };
    setFeatures([...features, feature]);
    inputEl.current.value = "";
  };

  const handleAddInformation = () => {
    const information = { id: uuidv1(), name: newInformation };
    setInformations([...informations, information]);
    inputElInfo.current.value = "";
  };

  const handleDeleteFeature = (id) => {
    setFeatures(features.filter((fe) => fe.id !== id));
  };

  const handleDeleteInformation = (id) => {
    setInformations(informations.filter((inf) => inf.id !== id));
  };

  const handleSubmit = () => {
    let data = {
      bank_token: bankToken,
      payment_token: paymentToken,
      valid_days: validDays,
      active_until: selectedDate.toISOString().split("T")[0],
      promotion_informations: informations.map((i) => i.name),
      promotion_features: features.map((f) => f.name),
      isRegisterGift: isRegisterGift,
      isMembership: true,
      description: description,
      promotion_code: promotionCode,
    };

    if (bankToken <= 0) {
      dispatch(setErrors({ general: "Bank token must be greater than 0" }));
    } else if (paymentToken <= 0) {
      dispatch(setErrors({ general: "Payment token must be greater than 0" }));
    } else if (validDays <= 0) {
      dispatch(setErrors({ general: "Valid days must be greater than 0" }));
    } else if (description === "") {
      dispatch(setErrors({ general: "Please enter description" }));
    } else if (promotionCode === "") {
      dispatch(setErrors({ general: "Please enter promotion code" }));
    } else if (features.length <= 0) {
      dispatch(setErrors({ general: "Please enter at least one feature" }));
    } else if (informations.length <= 0) {
      dispatch(setErrors({ general: "Please enter at least one information" }));
    } else {
      dispatch(addPromotion(data));
    }
    console.log("data", data);
  };

  useEffect(() => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(newDate.getFullYear() + 1);
    setSelectedDate(newDate);
  }, []);
  const renderAddFeatures = () => {
    return (
      <>
        <Typography style={{ fontWeight: "bold", fontSize: "20px", marginTop: "25px" }}>Features:</Typography>
        {features &&
          features.map((f) => {
            return (
              <>
                <Typography>
                  {f.name}{" "}
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDeleteFeature(f.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>{" "}
                </Typography>
              </>
            );
          })}
        <Grid container>
          <Grid xs={12} lg={8}>
            <TextField
              margin="dense"
              variant="outlined"
              required
              autoFocus
              id="feature"
              name="feature"
              label="Type a feature"
              fullWidth
              inputRef={inputEl}
              onChange={(e) => {
                setNewFature(e.target.value);
              }}
            />
          </Grid>
          <Grid xs={12} lg={3} style={{ marginTop: "10px", marginLeft: "10px" }}>
            <Button fullWidth variant="outlined" color="primary" onClick={() => handleAddFeature()}>
              Add feature
            </Button>
          </Grid>
        </Grid>

        <br />
      </>
    );
  };
  const renderAddInformations = () => {
    return (
      <>
        <Typography style={{ fontWeight: "bold", fontSize: "20px", marginTop: "25px" }}>Informations:</Typography>
        {informations &&
          informations.map((f) => {
            return (
              <>
                <Typography>
                  {f.name}{" "}
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDeleteInformation(f.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>{" "}
                </Typography>
              </>
            );
          })}
        <Grid container>
          <Grid item xs={12} lg={8}>
            <TextField
              margin="dense"
              variant="outlined"
              required
              autoFocus
              id="information"
              name="information"
              label="Type a information"
              fullWidth
              inputRef={inputElInfo}
              onChange={(e) => {
                setNewInformation(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} style={{ marginTop: "10px", marginLeft: "10px" }}>
            <Button fullWidth variant="outlined" color="primary" onClick={() => handleAddInformation()}>
              Add information
            </Button>
          </Grid>
        </Grid>

        <br />
      </>
    );
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen,
        })}
      >
        <div className={classes.root}>
          <FormControl style={{ display: "flex" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  id="promotion_code"
                  name="promotion_code"
                  label="Promotion Code"
                  value={promotionCode}
                  onChange={(e) => setPromotionCode(e.target.value)}
                  inputRef={register}
                  error={errors.email ? true : false}
                  helperText={errors.email ?? ""}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  id="description"
                  name="description"
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  inputRef={register}
                  error={errors.email ? true : false}
                  helperText={errors.email ?? ""}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      type: "number",
                    },
                  }}
                  value={paymentToken}
                  onChange={(e) => setPaymentToken(e.target.value)}
                  id="payment_token"
                  name="payment_token"
                  label="Payment Token"
                  inputRef={register}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      type: "number",
                    },
                  }}
                  value={bankToken}
                  onChange={(e) => setBankToken(e.target.value)}
                  id="bank_token"
                  name="bank_token"
                  label="Bank Token"
                  inputRef={register}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  margin="dense"
                  id="active_until"
                  name="active_until"
                  label="Expire Date"
                  value={selectedDate}
                  openTo="year"
                  format="yyyy-MM-dd"
                  size="small"
                  views={["year", "month", "date"]}
                  onChange={(date) => setSelectedDate(date)}
                  fullWidth
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  id="valid_days"
                  name="valid_days"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      type: "number",
                    },
                  }}
                  value={validDays}
                  onChange={(e) => setValidDays(e.target.value)}
                  label="Valid Days"
                  inputRef={register}
                  error={errors.email ? true : false}
                  helperText={errors.email ?? ""}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={<Checkbox id="isRegisterGift" name="isRegisterGift" checked={isRegisterGift} onChange={() => setIsRegisterGift(!isRegisterGift)} color="primary" />}
                  label={
                    <div>
                      <span style={{ fontSize: "18px" }}>Default Gift (If user doesn't enter promotion code while registering this promotion will be assigned)</span>
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {renderAddFeatures()}
              </Grid>
              <Grid item xs={12}>
                {renderAddInformations()}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button fullWidth style={{ marginBottom: "15px" }} variant="contained" color="secondary" onClick={() => handleSubmit()}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </div>
      </main>
    </MuiPickersUtilsProvider>
  );
};

export default AddPromotion;
