import { Button, Grid, Link, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import BasePage from "../../Components/BasePage";
import { useUiContext } from "./../../Contexts/UiContext";
import checkIfHasActiveMembership from "./../../util/permissions/checkIfHasActiveMembership";
import BusinessFinancialInformationsDialog from "./BusinessFinancialInformationsDialog";
import InvoiceSettings from "./InvoiceSettingsDialog";
import NotificationSettingsDialog from "./NotificationSettingsDialog";

const Settings = ({ business, user, updatePreferences }) => {
  const { errors, setErrors } = useUiContext();
  const history = useHistory();
  let location = useLocation();

  const [notificationsDialog, setNotificationsDialogOpen] = useState(false);

  const [openFinancialInformationsDialog, setOpenFinancialInformationsDialog] = useState(false);
  const [openInvoiceSettingsDialog, setOpenInvoiceSettingsDialog] = useState(false);

  const dispatch = useDispatch();

  const [afterRender, setAfterRender] = useState(false); // internal state

  let [userHasMembership, setUserHasMembership] = useState(null);

  useEffect(() => {
    async function checkMembership() {
      let hasMembership = await checkIfHasActiveMembership(business);
      setUserHasMembership(hasMembership);
    }
    checkMembership();
    // eslint-disable-next-line
  }, [business]);

  useEffect(() => {
    setAfterRender(true); // (1) will be called after DOM rendered
  }, []); //
  let vat_percentage = new URLSearchParams(location.search).get("vat_percentage");

  useEffect(() => {
    if (vat_percentage) {
      setOpenInvoiceSettingsDialog(true);
    }
  }, [vat_percentage]);

  const renderPackageInfo = () => {
    return (
      <>
        {business && business?.payeda_package?.payment_token && (
          <>
            <Typography>
              Payment Token Balance:
              {parseFloat(business?.payeda_package?.payment_token?.Balance) / parseFloat(business?.payeda_package?.commission_rate)}
            </Typography>
            <Typography>
              Total Purchased Payment Token:
              {parseFloat(business?.payeda_package?.payment_token?.Credit) / parseFloat(business?.payeda_package?.commission_rate)}
            </Typography>
            <Typography>
              Total Payment Token Spent:
              {parseFloat(business?.payeda_package?.payment_token?.Debit) / parseFloat(business?.payeda_package?.commission_rate)}
            </Typography>
          </>
        )}

        <br />

        {business && business?.payeda_package?.transfer_money_token && (
          <>
            <Typography>
              Transfer Money Token Balance:
              {parseFloat(business?.payeda_package?.transfer_money_token?.Balance) / parseFloat(business?.payeda_package?.commission_rate)}
            </Typography>
            <Typography>
              Total Purchased Transfer Money Token:
              {parseFloat(business?.payeda_package?.transfer_money_token?.Credit) / parseFloat(business?.payeda_package?.commission_rate)}
            </Typography>
            <Typography>
              Total Transfer Money Token Spent:
              {parseFloat(business?.payeda_package?.transfer_money_token?.Debit) / parseFloat(business?.payeda_package?.commission_rate)}
            </Typography>
          </>
        )}
        <br />
        {business && business?.payeda_package?.bank_token && (
          <>
            <Typography>Bank Token Balance: {business.payeda_package?.bank_token?.Balance}</Typography>
            <Typography>Total Purchased Bank Token: {business.payeda_package?.bank_token?.Credit}</Typography>
            <Typography>Total Token Spent: {business.payeda_package?.bank_token?.Debit || 0}</Typography>
          </>
        )}
      </>
    );
  };

  const renderSettingPreferences = () => {
    return (
      <Paper style={{ width: "100%", padding: "25px" }}>
        {/* <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            My Account
          </Typography>
        </Grid> */}
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          {business?.account_settings?.invoice_settings?.isManual && user.team_type === "Business" && business.canReceivePayment && (
            <Grid item xs={12}>
              <Button color="primary" onClick={() => setOpenInvoiceSettingsDialog(true)}>
                Update Vat Percentage{" "}
              </Button>
            </Grid>
          )}
          {business.canReceivePayment && (
            <Grid item xs={12}>
              <Button color="primary" onClick={() => history.push("/dashboard/financial-informations")}>
                Update Financial Settings
              </Button>
            </Grid>
          )}
          {/* {business && business?.payeda_package && (
            <Grid item xs={4}>
              {business && renderPackageInfo()}
            </Grid>
          )} */}
        </Grid>
      </Paper>
    );
  };

  return (
    <BasePage navigation="Dashboard/Settings" business={business} canView={true}>
      {renderSettingPreferences()}
      <NotificationSettingsDialog
        open={notificationsDialog}
        setOpen={setNotificationsDialogOpen}
        title="Create new customer"
        errors={errors}
        updatePreferences={updatePreferences}
        external={business}
      />

      <BusinessFinancialInformationsDialog
        dispatch={dispatch}
        setOpen={setOpenFinancialInformationsDialog}
        open={openFinancialInformationsDialog}
        errors={errors}
        business={business}
      />
      <InvoiceSettings open={openInvoiceSettingsDialog} setOpen={setOpenInvoiceSettingsDialog} external={business} setErrors={setErrors} history={history} />
    </BasePage>
  );
};

export default Settings;
