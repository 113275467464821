import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { getPaymentLimits } from "../../../store/actions/adminActions";
import PaymentLimitDialog from "./PaymentLimitDialog";

const SystemSettings = ({ denyBusiness, business }) => {
    const dispatch = useDispatch();

    const [openPaymentLimitDialog, setOpenPaymentLimitDialog] = useState(false);
    const paymentLimits = useSelector((selector) => selector.admin.paymentLimitSettings)
    useEffect(() => {
        dispatch(getPaymentLimits());
    }, []);


    return (
        <BasePage navigation="Dashboard/System Settings" business={business} canView={true}>
            <Button variant="outlined" color="primary" onClick={() => setOpenPaymentLimitDialog(true)}>Set Payment Limits</Button>
            <br />
            <PaymentLimitDialog open={openPaymentLimitDialog} setOpen={setOpenPaymentLimitDialog} paymentLimits={paymentLimits} />
            {/* <br />
            <Button variant="outlined" color="secondary">Set Daily Total Limit For Personal Account</Button>
            <br />
            <br />
            <Button variant="outlined" color="primary">Set At Once Total Limit For Business Account</Button>
            <br />
            <br />
            <Button variant="outlined" color="secondary">Set At One Total Limit For Personal Account</Button> */}

        </BasePage >
    );
};

export default SystemSettings;
