import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMergedAccounts } from "../../../../store/actions/businessActions";
import canPerformAction from "../../../../util/permissions/canPerformAction";
import BasePage from "./../../../../Components/BasePage";
import AddButton from "./../../../../Components/shared/AddButton";
import TableComponent from "./../../../../Components/Table/TableComponent";

const searchOptions = [{ default: true, id: "invitation_code_lower", label: "Invitation Code" }];
const headCells = [
  { id: "business_name", label: "Name" },
  { id: "business_email", label: "Email" },
  { id: "business_phone", label: "Phone" },
  // { id: "is_joined", label: "Joined"}
];
const MergedAccountTable = ({ business, user, history }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  let permissions = user.permissions;


  const onMergeNewClick = () => {
    history.push("/dashboard/add-supplier");
  };

  const renderToolbar = () => {
    return (
      <>
        {canPerformAction("suppliers", permissions, "create") && (
          <div>
            <AddButton text="Add New Supplier" onClick={onMergeNewClick} />
          </div>
        )}
      </>
    );
  };

  const mergedAccts = useSelector((state) => state.business.mergedAccts);
  const isLoading = useSelector((state) => state.business.mergedLoading);
  const isLastPage = useSelector((state) => state.lastPage);
  console.log("mgerdAccts", mergedAccts)
  return (
    <BasePage navigation="Dashboard/MergeAccounts" business={business} canView={user?.permissions?.supplier?.view}>
      <TableComponent
        toolbar={renderToolbar()}
        data={mergedAccts?.data || []}
        selected={selected}
        headCells={headCells}
        setSelected={setSelected}
        searchOptions={searchOptions}
        isLoading={isLoading}
        isLastPage={isLastPage}
        getAllData={getMergedAccounts}
        searchBarText="Search customers"
        shouldGetData={true}
        numberOfPages={mergedAccts?.number_of_page || 0}
      />
    </BasePage>
  );
};

export default MergedAccountTable;
