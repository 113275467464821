import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PDFDownloadLink } from "@react-pdf/renderer";

import BasePage from "../../../Components/BasePage";
import TableComponent from "../../../Components/Table/TableComponent";
import TableToolbarComponent from "../../../Components/Table/TableToolbarComponent";
import { downloadIncomingReceipts, downloadOutgoingReceipts, downloadReceipts, getIncomingReceipts, getOutgoingReceipts, getReceiptPDF } from "../../../store/actions/invoiceActions";
import ReceiptPDF from "./ReceiptPDF";
import ButtonBase from "../../../Components/shared/ButtonBase";
import { Button } from "@material-ui/core";
import DataFilterDialogByDate from "../../../Components/Dialogs/DataFilterDialogByDate";

const incomingSearchOptions = [
  { default: true, id: "total_amount", label: "Total Amount" },
  { default: true, id: "beneficiary_name_lower", label: "Payee Name" },
];

const outgoingSearchOptions = [
  { default: true, id: "total_amount", label: "Total Amount" },
  { default: true, id: "remitter_name_lower", label: "Payer Name" },
  { default: true, id: "remitter_name_lower", label: "remitter_erp_lower" },
];
const headCells = [
  // { id: "created_at", label: "Payment Date" },
  { id: "amount", label: "Total Amount" },
  { id: "payee_name", label: "Paid To" },
  { id: "payer_name", label: "Paid By" },
];

const ReceiptsTable = ({ business }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const receipts = useSelector((state) => state.invoice);

  let receiptType;
  let url = window.location.pathname.split("/")[2];
  const user = useSelector((state) => state.user);

  const [afterRender, setAfterRender] = useState(false);
  const [openReceiptDownloadDialog, setOpenReceiptDownloadDialog] = useState(false);

  const permission = url === "incoming-receipts" ? user?.permissions?.incomingInvoice?.viewReceipt : user?.permissions?.outgoingInvoice?.viewReceipt;

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (afterRender) {
  //     if (url === "incoming-receipts") dispatch(getIncomingReceipts({}));
  //     else dispatch(getOutgoingReceipts({}));
  //   }
  //   // eslint-disable-next-line
  // }, [afterRender]);

  const applyReceiptDownloadFilter = (startDate, endDate) => {
    let data = {
      start_date: startDate,
      end_date: endDate,
    }
    console.log("receipt", data)
    if (url === "incoming-receipts") {
      dispatch(downloadIncomingReceipts(data));
    } else {
      dispatch(downloadOutgoingReceipts(data));

    }
  }
  const renderToolbar = () => {
    return (
      <TableToolbarComponent header="Receipts" numSelected={selected.length}>
        {selected && selected.length === 1 && <ButtonBase tooltip="GET PDF" text="PDF" backColor="#00AFA1" textColor="white" onClick={() => dispatch(getReceiptPDF(selected[0].simp_id))} fontSize="400" />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenReceiptDownloadDialog(true);
          }}
          style={{
            borderRadius: "30px",
            marginLeft: "40px",
            marginTop: "20px",
            backgroundColor: "orange"
          }}
        >
          Download Receipts
        </Button>

        <DataFilterDialogByDate open={openReceiptDownloadDialog} setOpen={setOpenReceiptDownloadDialog} title="Select date range to download receipts" onSubmit={applyReceiptDownloadFilter} />
      </TableToolbarComponent>
    );
  };
  const [data, setData] = useState([])

  useEffect(() => {
    if (url === "incoming-receipts") {
      setData(receipts?.incomingReceipts)
    } else {
      setData(receipts?.outgoingReceipts)

    }
  }, [receipts.incomingReceipts, receipts.outgoingReceipts])
  return (
    <BasePage navigation="Dashboard/Receipts" business={business} canView={permission}>
      <TableComponent
        toolbar={renderToolbar()}
        data={data?.data || []}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        searchOptions={url === "incoming-receipts" ? incomingSearchOptions : outgoingSearchOptions}
        searchbarText="Search Receipts"
        getAllData={url === "incoming-receipts" ? getIncomingReceipts : getOutgoingReceipts}
        shouldGetData={true}
        isLoading={receipts.isLoading}
        numberOfPages={data?.number_of_page || 0}
      />
    </BasePage>
  );
};
export default ReceiptsTable;
