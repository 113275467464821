// eslint-disable-next-line

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Assessment from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import GroupIcon from "@material-ui/icons/Group";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import StarIcon from "@material-ui/icons/Star";
import CloudIcon from "@material-ui/icons/Cloud";
import { BatteryUnknownSharp, SettingsInputComponent } from "@material-ui/icons";
//TODO needs translation

export const BUSINESS_ITEMS = [
  { text: "DashBoard", icon: <Assessment />, link: "/dashboard" },
  {
    text: "Incoming Invoices",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/incoming-invoices",
  },
  {
    text: "Outgoing Invoices",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/outgoing-invoices",
  },
  {
    text: "Customers",
    icon: <GroupIcon />,
    link: "/dashboard/customers",
  },
  {
    text: "Suppliers",
    icon: <GroupIcon />,
    link: "/dashboard/suppliers",
  },
  {
    text: "Products",
    icon: <ShoppingCartOutlinedIcon />,
    link: "/dashboard/products",
    permissionType: "product",
    permission: "view",
  },
  {
    text: "Account",
    icon: <AccountBoxIcon />,
    subMenus: [
      {
        text: "Documents",
        icon: <AssignmentIcon />,
        link: "/dashboard/my-documents",
      },

      {
        text: "Bank Account",
        icon: <CreditCardIcon />,
        link: "/dashboard/bank-accounts",
        permissionType: "bankAccount",
        permission: "view",
      },
    ],
  },

  {
    text: "Buy Plan",
    icon: <StarIcon />,
    link: "/dashboard/buy-plans",
  },
];
export const CONNECT_ERP_CLOUD = [
  {
    text: "Connect Erp Cloud",
    icon: <CloudIcon />,
    link: "/dashboard/connect-erp-cloud-app",
  },
];

const DASHBOARD_ITEM = [
  {
    text: "DashBoard",
    icon: <Assessment />,
    link: "/dashboard",
  },
];
const ADMIN_ITEMS = [
  // {
  //   text: "All Users",
  //   icon: <GroupIcon />,
  //   link: "/dashboard/user-accounts",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },
  {
    text: "Pending Approval",
    icon: <GroupIcon />,
    link: "/dashboard/pending-business-table",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "Verified Businesses",
    icon: <GroupIcon />,
    link: "/dashboard/verified-business-table",
    // permissionType: "customer",
    // permission: "view",
  },

  // {
  //   text: "Businesses Packages",
  //   icon: <GroupIcon />,
  //   link: "/dashboard/businesses-packages",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },
  // {
  //   text: "Add Finance Account",
  //   icon: <GroupIcon />,
  //   link: "/dashboard/add-finance-account",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },

  // {
  //   text: "Transactions",
  //   icon: <GroupIcon />,
  //   link: "/dashboard/all-transactions",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },
  // {
  //   text: "Incomplete Payments",
  //   icon: <GroupIcon />,
  //   link: "/dashboard/incomplete-payments",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },
  // {
  //   text: "Transactions By User",
  //   icon: <GroupIcon />,
  //   link: "/dashboard/all-transactions-by-user",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },
  {
    text: "Packages",
    icon: <GroupIcon />,
    link: "/dashboard/packages",
    // permissionType: "customer",
    // permission: "view",
  },
  // {
  //   text: "Bank Account Details",
  //   icon: <GroupIcon />,
  //   link: "/dashboard/business-bank-accounts",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },
  {
    text: "Add Npc Promotions",
    icon: <SettingsInputComponent />,
    link: "/dashboard/add-npc-promotion",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "Npc Promotions",
    icon: <SettingsInputComponent />,
    link: "/dashboard/npc-promotions",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "Add Promotion",
    link: "/dashboard/add-promotion",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "System Settings",
    icon: <SettingsInputComponent />,
    link: "/dashboard/system-settings",
    // permissionType: "customer",
    // permission: "view",
  },
  // {
  //   text: "Bank Account Change Req",
  //   icon: <BatteryUnknownSharp />,
  //   link: "/dashboard/change-requests",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },
];
const sidebarItems = (user) => {
  let dashboardType = localStorage.getItem("dashboardType");
  let items = [];

  console.log("user", user);
  let sidebarItems = user.sideBarItems;

  console.log("disebar", sidebarItems);
  if (user.team_type === "Admin") {
    items = ADMIN_ITEMS;
  }

  // if (user.team_type === "Finance" || user.team_type === "Finance") {
  //   items = sibebar;
  // }

  if (user.team_type === "Business" || user.team_type === "Personal") {
    console.log("it is here");
    if (dashboardType === "payedaBank") {
      console.log("should be here");
      items = sidebarItems[0].bankDashboard;
    } else if (dashboardType === "payedaInvoice") {
      items = sidebarItems[0].invoiceDasboard;
    }
  }

  // if (user.team_type === "Business" && dashboardType === "payedaFinancing") {
  //   items.push(...items, ...(sibebar[0]?.financingDashboard || []));
  // }

  items.map((item) => {
    if (item.icon === "group") item.icon = <GroupIcon />;
    if (item.icon === "receipt") item.icon = <ReceiptOutlinedIcon />;
    if (item.icon === "star") item.icon = <StarIcon />;
    if (item.icon === "shop") item.icon = <ShoppingCartOutlinedIcon />;
    if (item.icon === "assignment") item.icon = <AssignmentIcon />;
    if (item.icon === "credit") item.icon = <CreditCardIcon />;
    if (item.icon === "dashboard") item.icon = <Assessment />;
    if (item.icon === "cloud") item.icon = <CloudIcon />;
    if (item.icon === "account") item.icon = <AccountBoxIcon />;
  });

  console.log("items", items);

  return items;
};

export default sidebarItems;
