import { Button, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";
import { getBankStatement, getCompanyStatement, getIdFrontPage, getIdRearPage, getLogo, getUploadedFileStatuses, handleUploadFile } from '../../../store/actions/businessActions';
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const documentTypes = [
  { value: "logo", label: "LOGO" },

  { value: "id_rear_page", label: "ID Rear Page" },
  { value: "id_front_page", label: "ID Front Page" },
  { value: "company_statement", label: "Company Statement" },
  { value: "bank_statement", label: "Bank Statement" }
];

const BusinessFilesDialog = ({ open, setOpen, title, description, selected }) => {
  const dispatch = useDispatch()
  const [loadingFileType, setLoadingFileType] = useState([]);

  const handleDownloadDocuments = async (fileType) => {
    let teamType = "0";
    let teamId = selected.id;

    if (fileType === "id_rear_page") {
      dispatch(getIdRearPage(teamId, teamType));

    } else if (fileType === "id_front_page") {
      dispatch(getIdFrontPage(teamId, teamType))
    } else if (fileType === "company_statement") {
      dispatch(getCompanyStatement(teamId, teamType))

    } else if (fileType === "bank_statement") {
      console.log("runs")
      dispatch(getBankStatement(teamId, teamType))

    } else if (fileType === "logo") {
      dispatch(getLogo(teamId, teamType))

    }
  }

  const renderDocuments = () => {
    return (
      <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA", cursor: "pointer" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" onClick={() => alert("hey")}>
              <strong>Documents</strong>
            </Typography>
          </Grid>

          {documentTypes.map((doc) => {
            return (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Button onClick={() => handleDownloadDocuments(doc.value)} style={{ cursor: "pointer", marginTop: "10px" }} fullWidth>
                    Download {doc.label} {loadingFileType.includes(doc.value) ? <small>Loading...</small> : ""}
                  </Button>
                </Grid>
              </>
            )
          })}
          <br />
        </Grid >
      </Alert >

    );
  };
  return (
    <DialogComponent open={open} setOpen={setOpen} title={title} description={description} withActions={false}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          {renderDocuments()}
        </Grid>
        {/* <Typography variant="h6" style={{ marginTop: "10px" }}>
          Directors:
        </Typography>
        {renderDirectorDocuments()} */}
        {/* <Typography variant="h6" style={{ marginTop: "10px" }}>
          Shareholders:
        </Typography>
        {renderShareholderDocuments()} */}
        {/* <Grid xs={12}>
          <Typography variant="h6" style={{ marginTop: "10px" }}>
            Company Files:
          </Typography>
        </Grid>

        {renderCompanyFiles()} */}
      </Grid>
      {/* <Grid container spacing={3} justify="left" alignItems="left">
        <Grid item xs={6}>
          Board Decision:
        </Grid>
        {boardDecision === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(boardDecision)}
        <Grid item xs={6}>
          Front side of ID
        </Grid>
        {idFront === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(idFront)}

        <Grid item xs={6}>
          Rear side of ID
        </Grid>
        {idRear === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(idRear)}

        <Grid item xs={6}>
          Tax signboard
        </Grid>
        {taxSignboard === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(taxSignboard)}

        <Grid item xs={6}>
          signature Circular
        </Grid>
        {signatureCircular === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(signatureCircular)}
      </Grid> */}
    </DialogComponent>
  );
};

export default BusinessFilesDialog;
