import React from "react";

import TableComponent from "../../../Components/Table/TableComponent";

const headCells = [
  { id: "business_name", label: "Name" },
  { id: "business_model", label: "Business Model" },
  { id: "business_type", label: "Business Type" },
  { id: "admin_email", label: " Admin Email" },
];

const PendingApprovalBusinessesTable = ({ businessesToApprove, getAllData, selected, setSelected, toolbar, numberOfPages }) => {
  return (
    <TableComponent
      toolbar={toolbar}
      data={businessesToApprove}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
      getAllData={getAllData}
      shouldView={true}
      shouldGetData={true}
      numberOfPages={numberOfPages}
    />
  );
};

export default PendingApprovalBusinessesTable;
