import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Paper, TableContainer, Table, TextField, Grid } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import { TablePagination } from "@material-ui/core";

import TableHeaderComponent from "./TableHeaderComponents";
import TablePaginationComponent from "./TablePaginationComponent";
import TableBodyComponent from "./TableBodyComponent";
import { useTableStyles } from "./TableStyles";
import Pagination from "@material-ui/lab/Pagination";
import { Controller } from "react-hook-form";

const TableComponent = ({
  toolbar,
  footer,
  data: external,
  selected,
  headCells,
  setSelected,
  businessId,
  showDetailsAction,
  isLoading,
  shouldView,
  showPDFAction,
  shouldGetData,
  setShouldGetData,
  handleSearchBar,
  getAllData,
  getDataParams,
  numberOfPages,
  triggerGetData,
}) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [afterRender, setAfterRender] = useState(false);

  const handleGetData = () => {
    if (shouldGetData) {
      let params = { ...getDataParams, page: page + 1, perPage: rowsPerPage, search: search };
      dispatch(getAllData(params));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setData(external);
  }, [external]);

  useEffect(() => {
    setAfterRender(true);
  }, []);

  useEffect(() => {
    handleGetData();
  }, [afterRender, page, rowsPerPage, search, triggerGetData]);

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (!shouldGetData) {
      const searchText = e.target.value.toLowerCase().trim();

      const filtered = external.filter((x) => {
        for (const cell in headCells) {
          let field = headCells[cell].id;
          if (typeof x[field] === "string") {
            let match = x[field]
              .toString()
              .toLowerCase()
              .includes(searchText);
            if (match) {
              return match;
            }
          }
        }
      });
      setData(filtered);
    }
  };

  const handleSelectAllClick = (event) => {
    if (data && event.target.checked) {
      setSelected(data);
      return;
    }
    setSelected([]);
  };

  const renderSearchBar = () => {
    return (
      <Paper className={classes.searchBar}>
        <TextField
          label="Search..."
          fullWidth
          variant="outlined"
          className={classes.searchInput}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          type="text"
        />
      </Paper>
    );
  };

  //TODO date filter does not work
  const renderDateFilter = () => {
    return (
      <Grid container spacing={3} style={{ padding: "15px" }}>
        <Grid item xs={12} sm={6}>
          <TextField InputLabelProps={{ shrink: true }} id="birthday" value="date" label="Start Date" type="date" fullWidth={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField InputLabelProps={{ shrink: true }} id="birthday" value="date" label="End Date" type="date" fullWidth={true} />
        </Grid>
      </Grid>
    );
  };

  const renderNoResult = () => {
    return (
      (!data || !(data.length > 0)) && (
        <Typography color="error" variant="body1" style={{ textAlign: "center" }}>
          No result
        </Typography>
      )
    );
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        {toolbar}
        {/* {renderDateFilter()} */}
        <div style={{ marginTop: "30px" }}>{renderSearchBar()}</div>

        <Paper className={classes.paper}>
          <TableContainer>
            <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
              <TableHeaderComponent
                allowSelect={true}
                classes={classes}
                numSelected={selected ? selected.length : 0}
                rowCount={data ? data.length : 0}
                onSelectAllClick={handleSelectAllClick}
                headCells={headCells}
              />
              {isLoading ? (
                <div style={{ paddingLeft: "10px" }}>Loading</div>
              ) : (
                <>
                  <TableBodyComponent
                    data={data}
                    headCells={headCells}
                    selected={selected}
                    setSelected={setSelected}
                    businessId={businessId}
                    showDetailsAction={showDetailsAction}
                    showPDFAction={showPDFAction}
                    classes={classes}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    shouldGetData={shouldGetData}
                  />
                </>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 30]}
            component="div"
            count={shouldGetData ? numberOfPages * rowsPerPage : numberOfPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
          {renderNoResult()}

          {footer}
        </Paper>
      </div>
    </Paper>
  );
};

export default TableComponent;
