import axios from "axios";

import { setSuccess, setErrors, clearErrors, startLoading, clearSuccess } from "./uiActions";
import { API_BASE, UI_API_BASE } from "../env";
import Swal from "sweetalert2";
import { getOutgoingInvoices } from "./invoiceActions";
import { getBusinessDetails } from "./businessActions";
import { getSelfBankAccounts } from "./bankingActions";

export const signupUser = (userData, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/auth/signup`, userData);
    Swal.fire({
      title: "We saved your information successfully!",
      text: "We have sent an email with a confirmation link to your email address.",
      icon: "success",
    });
    dispatch(clearErrors());
    history.push("/signin");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getUser = (userData) => async (dispatch) => {
  try {
    if (userData.email === "" || userData.password === "") {
      dispatch(setErrors({ general: "Please enter email and password" }));
    }
    console.log("apı base", API_BASE);
    var response = await axios.post(`${API_BASE}/auth/signin`, userData);
    dispatch({ type: "USER_DATA_FETCH_SUCESS" });
    // setAuthorizationHeader(response.data.access_token);
    if (response.status !== 401) return { status: response.status, token: response?.data?.access_token };
  } catch (err) {
    if (err.response.status === 401) {
      console.log(err.response.data);
      dispatch(setErrors({ general: err.response.data.message }));
      dispatch({ type: "USER_DATA_FETCH_FAILED" });
      return { status: 401, token: "" };
    }
  }
};

export const deleteAccount = (userData, FBIdToken) => async (dispatch) => {
  try {
    console.log("userdata,", userData);
    if (!userData.email) {
      dispatch(setErrors({ general: "Please enter email" }));
    }
    var response = await axios.post(`${API_BASE}/v1/account/delete-account`, userData, {
      headers: {
        Authorization: "Bearer " + FBIdToken,
      },
    });
    dispatch({ type: "USER_DATA_FETCH_SUCESS" });

    return response.status;
  } catch (err) {
    if (err.response.status === 401) {
      console.log(err.response.data);
      dispatch(setErrors({ general: err.response.data.message }));

      return err.response.status;

      // dispatch({ type: "USER_DATA_FETCH_FAILED" });
    }
  }
};

export const signinUser = (userData, history) => async (dispatch) => {
  try {
    dispatch({ type: "USER_DATA_FETCH_STARTED" });
    console.log(API_BASE);
    var response = await axios.post(`${API_BASE}/auth/signin`, userData);
    setAuthorizationHeader(response.data.access_token);
    dispatch(clearErrors());
    dispatch(clearSuccess());
    dispatch(getUserData());
    history.push("/dashboard");
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      dispatch(setErrors({ general: err.response.data.message }));
      dispatch({ type: "USER_DATA_FETCH_FAILED" });
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const sendContactMail = (data, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/contact`, data);
    dispatch(setSuccess("Your message has been sent! Thanks for reaching us!"));
    dispatch(clearErrors());
    history.push("/");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const sendPasswordResetLink = (data, setOpen) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/auth/forgot-password`, data);
    dispatch(setSuccess("We have sent a link to your email to create a new password"));
    setOpen(false);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const resetPassword = (data, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/auth/reset-password`, data);
    dispatch(setSuccess("We have changed your password successfully"));

    setTimeout(() => {
      window.location.href = `${UI_API_BASE}/signin`;
    }, 1.0 * 1000);

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      dispatch(setErrors({ general: "The Password Reset Link Has Expired" }));

      // console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const sendVerifyEmailLink = (data, setOpen) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/auth/email-verification-link`, data);
    dispatch(setSuccess("We have sent a link to your email to verify your email"));
    setOpen(false);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const verifyEmail = (data, setOpen) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/auth/verify-email`, data);
    dispatch(setSuccess("Email verified successfully"));
    setOpen(false);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const invitationSignup = (userData, history) => async (dispatch) => {
  console.log(userData);
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/auth/signup`, userData);

    Swal.fire({
      title: "We saved your information successfully!",
      text: "We have sent an email with a confirmation link to your email address.",
      icon: "success",
    });
    // dispatch(setSuccess("Successfully created new user."));
    dispatch(clearSuccess());
    dispatch(clearErrors());
    history.push("/signin");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

//TODO this function was not existing before, create appropriate backend and make this work as intended
export const invitationSignin = (userData, history) => async (dispatch) => {
  console.log(userData);
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/sign-up`, userData);
    dispatch(setSuccess("Successfully created new user."));
    dispatch(clearSuccess());
    dispatch(clearErrors());
    history.push("/signin");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const signoutUser = (history) => async (dispatch) => {
  try {
    await localStorage.removeItem("FBIdToken");

    // history.push("/signin");

    Swal.fire({
      html: "Signing out…",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios.get(`${API_BASE}/auth/logout`);
    window.location.href = "/signin";

    Swal.close();

    // dispatch({ type: "SET_USER"});
    // dispatch({ type: "BUSINESS_DATA_FETCH_SUCCESS" });
    dispatch(clearErrors());
    dispatch({ type: "HIDE_SIDEBAR" });
    dispatch(setSuccess("Signed out."));
    dispatch(clearErrors());
    dispatch(clearSuccess());

    // dispatch({ type: "SET_USER" });
    // dispatch({ type: "SET" });
    dispatch({ type: "SET_UNAUTHENTICATED" });
    // window.location.reload();
    localStorage.removeItem("dashboardType");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ message: err.response.data.message }));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getSideBarItems = () => async (dispatch) => {
  try {
    let sidebar = await axios.get(`${API_BASE}/v1/account/sidebar`);

    dispatch({
      type: "SIDEBAR_ITEMS",
      payload: sidebar.data?.data,
    });
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getUserData = () => async (dispatch) => {
  try {
    dispatch({ type: "USER_DATA_FETCH_STARTED" });

    let user = await axios.get(`${API_BASE}/v1/account/user`);
    let userDetails = { ...user.data };

    dispatch({ type: "SET_USER", payload: userDetails.data });

    if (userDetails.data.team_id) {
      dispatch(getBusinessDetails());
      // dispatch(getSideBarItems())
      // dispatch(getSelfBankAccounts())
    }
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateUserInformation = (userData) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/update-profile`, userData);
    dispatch(setSuccess("Successfully saved information"));
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

// const getUserGeoLocation = async() => {

//     //creating function to load ip address from the API
//       const res = await axios.get('https://geolocation-db.com/json/')
//       console.log("geoloc", res.data);

// }

const setAuthorizationHeader = async (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);

  axios.defaults.headers.common["Authorization"] = FBIdToken;
};
