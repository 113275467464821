import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import InfoIcon from "@material-ui/icons/Info";
import DataFilterDialogByDate from "../../../Components/Dialogs/DataFilterDialogByDate";

const IncomingInvoiceFilterDialog = ({ open, setOpen, onSubmit, renderSupplierSelection }) => {

    return (
        <DataFilterDialogByDate
            open={open}
            setOpen={setOpen}
            title="Select filters to download invoices"
            onSubmit={onSubmit}
            withActions={true}
        >

            <Grid item xs={12} sm={6} md={6} lg={6}>
                {renderSupplierSelection}
            </Grid>


        </DataFilterDialogByDate>
    );
};

export default IncomingInvoiceFilterDialog;