import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React, { useEffect, useState } from "react";
import { AiOutlineQrcode } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { importOutgoingInvoicesFromErp } from "../../../store/actions/invoiceActions";
import checkIfHasActiveMembership from "../../../util/permissions/checkIfHasActiveMembership";
import DialogComponent from "./../../../Components/Dialogs/DialogComponent";
import TableToolbarComponent from "./../../../Components/Table/TableToolbarComponent";
import canPerformAction from "./../../../util/permissions/canPerformAction";
import { usePermissionsContext } from "./../../../Contexts/PermissionsContext";
import BuyPackageDialog from "../../../Components/Dialogs/BuyPackageDialog";
import { MdDownloadDone, MdOutlineBikeScooter } from "react-icons/md";
import InvoiceFilterDialog from "../../../Components/Dialogs/DataFilterDialogByDate";
import OutgoingInvoiceFilterDialog from "./OutgoingInvoiceFilterDialog";

const OutgoingInvoicesToolbar = ({
  numSelected,
  header,
  dispatch,
  setErrors,
  setDeleteDialogOpen,
  business,
  onInvite,
  renderCustomerSelection,
  renderStatusSelection,
  setOpenImportFromErp,
  handleSubmit,
  register,
  setOpenPaymentQRCode,
  handleRequestPayment,
  selected,
  setSendLinkOption,
  paymentLinkDialogOpen,
  setPaymentLinkDialogOpen,
  handleSendReminder,
  setOpenInvoiceDownloadDialog,
  openInvoiceDownloadDialog,
  applyInvoiceDownloadFilter
}) => {
  let history = useHistory();
  const { permissions } = usePermissionsContext();

  let [userHasMembership, setUserHasMembership] = useState(null);
  const [openBuyPackageDialog, setOpenBuyPackageDialog] = useState(false);
  useEffect(() => {
    async function checkMembership() {
      let hasMembership = await checkIfHasActiveMembership(business);
      setUserHasMembership(hasMembership);
    }
    checkMembership();
    // eslint-disable-next-line
  }, []);

  const handleRadioChange = (e) => {
    let val = e.target.value;
    setSendLinkOption(val);
  };
  const sendReminderComponent = () => {
    return (
      <Button
        onClick={() => handleSendReminder()}
        style={{
          backgroundColor: "#17A148",
          color: "white",
        }}
        variant="contained"
      >
        Send Reminder
      </Button>
    );
  };
  const renderSendPaymentLinkDialog = () => {
    return (
      <DialogComponent
        open={paymentLinkDialogOpen}
        setOpen={setPaymentLinkDialogOpen}
        title="Send to customer"
        onSubmit={handleSubmit((data) => onInvite(data))}
        withActions={true}
      >
        <Grid justify="center" alignItems="center">
          <Grid item xs={12}>
            <h3 style={{ color: "#0275D8", fontSize: "24px" }}>Invoice Payment Address</h3>

            {/**fatura ID'si?? */}
          </Grid>

          <Grid item xs={12}>
            {/* payeda.net/checkout?id=
            {selectedInvoice.id} */}
          </Grid>

          <form>
            <div style={{ fontSize: "18px" }}>
              <input id="withEmail" value="withEmail" name="sendLinktoCustomerOption" type="radio" ref={register} onChange={(e) => handleRadioChange(e)} />
              <label for="makePayment">Send via Email</label>
              <div>
                {numSelected === 1 && (
                  <>
                    <input id="withWhatsApp" value="withWhatsApp" name="sendLinktoCustomerOption" type="radio" ref={register} onChange={(e) => handleRadioChange(e)} />
                    <label for="receivePayment">Send via WhatsApp</label>
                  </>
                )}
              </div>
            </div>
          </form>
        </Grid>
      </DialogComponent>
    );
  };

  const handleOpenSetPaymentLink = () => {
    const isFound = selected.some((inv) => {
      if (inv.statusDetails.status === "Paid") {
        setErrors({
          general: "Please select only unpaid invoices",
        });

        return true;
      }
    });

    if (!isFound) {
      setPaymentLinkDialogOpen(true);
    }
  };
  //TODO: render add or import buttons based on user's platform usage.
  // must be checked if isErp or isManuel options when dipslaying buttons
  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>


        {numSelected > 0 && (
          <>
            {true && (
              <>
                {canPerformAction("outgoinginvoices", permissions, "remove") && (
                  <Tooltip title="Delete">
                    <IconButton onClick={() => setDeleteDialogOpen(true)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {canPerformAction("outgoinginvoices", permissions, "send") && <>{sendReminderComponent()}</>}
          </>
        )}
        {numSelected > 0 && (
          <>
            {canPerformAction("outgoinginvoices", permissions, "send") && (
              <>
                <Tooltip title="Share with customer">
                  <IconButton onClick={() => handleOpenSetPaymentLink()}>
                    <Button style={{ color: "blue" }} variant="outlined">
                      Send Payment Link
                    </Button>
                    {/* <WhatsAppIcon style={{ color: "green" }} /> */}
                  </IconButton>
                </Tooltip>

                <Tooltip title="Scan Qr Code">
                  <IconButton
                    onClick={() => {
                      setOpenPaymentQRCode(true);
                      console.log("workeddd")
                      // dispatch(handleRequestPayment(selected[0]));
                    }}
                  >
                    <AiOutlineQrcode />
                  </IconButton>
                </Tooltip>

                {renderSendPaymentLinkDialog()}
              </>
            )}
          </>
        )}
        {numSelected === 0 && (
          <>
            <Grid container spacing={3}>
              <Grid
                container
                xs={12}
                lg={9}
                md={9}
                spacing={3}
                style={{
                  backgroundColor: "#FEEBF2",
                  borderRadius: "20px",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12} lg={5} md={5}>
                  {renderCustomerSelection}
                </Grid>
                <Grid item xs={12} lg={5} md={5}>
                  {renderStatusSelection}
                </Grid>
              </Grid>

              <Grid item xs={12} lg={3} md={3}>
                {true && (
                  <>
                    {canPerformAction("outgoingInvoices", permissions, "create") && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (!business.isReceivePaymentVerified) {
                              setErrors({ general: "Please verify receive payment bank account" })
                              return false
                            }
                            if (Object.keys(business.payeda_package) <= 0) {
                              setOpenBuyPackageDialog(true);
                            } else {
                              history.push("/dashboard/create-outgoing-invoice")
                            }
                          }}
                          style={{
                            borderRadius: "30px",
                            marginLeft: "40px",
                            marginTop: "20px",
                          }}
                        >
                          Add invoice
                        </Button>
                        <BuyPackageDialog
                          open={openBuyPackageDialog}
                          setOpen={setOpenBuyPackageDialog}
                        />
                      </>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setOpenInvoiceDownloadDialog(true);
                      }}
                      style={{
                        borderRadius: "30px",
                        marginLeft: "40px",
                        marginTop: "20px",
                        backgroundColor: "orange"
                      }}
                    >
                      Download Invoices
                    </Button>
                    <OutgoingInvoiceFilterDialog open={openInvoiceDownloadDialog} setOpen={setOpenInvoiceDownloadDialog} renderCustomerSelection={renderCustomerSelection} renderStatusSelection={renderStatusSelection} onSubmit={applyInvoiceDownloadFilter} />
                  </>
                )}
              </Grid>

            </Grid>
          </>
        )}
      </>
    </TableToolbarComponent >
  );
};

export default OutgoingInvoicesToolbar;
