import { ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { Route, useHistory } from "react-router-dom";
import ViewReceipt from "./pages/Invoice/Receipt/ViewReceipt";

import jwt from "jsonwebtoken";
import React, { memo, useEffect, useState } from "react";
//TODO learn more about MuiThemeProvider and CssBaseline to how to manage it
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import defaultTheme from "../src/Components/theme";
import PasswordResetForm from "../src/pages/LoginSignup/PasswordResetForm";
import AlertNotification from "./Components/AlertNotification";
import Bar from "./Components/Bar";
import PermissionsProvider from "./Contexts/PermissionsContext";
import UiProvider from "./Contexts/UiContext";
import Router from "./router";
import { getPaymentResult } from "./store/actions/bankingActions";
import { getUserData } from "./store/actions/userActions";
import PaymentResult from "./util/banking/PaymentResult";
import ConnectBankAccountResult from "./util/banking/ConnectBankAccountResult";

const selectBusiness = createSelector(
  (state) => ({
    ...state,
  }),
  (state) => state.business
);

const selectUser = createSelector(
  (state) => ({
    ...state,
  }),
  (state) => state.user
);

export const getClientIp = async () => {
  return false;
};

const App = React.memo((props) => {
  const dispatch = useDispatch();
  let location = useLocation();
  let history = useHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    handleSessionManagement();
    // eslint-disable-next-line
  }, []);

  const [isUserPresent, setIsUserPresent] = React.useState(false);

  //creating function to load ip address from the API
  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   setIP(res.data.IPv4);
  // };

  // useEffect(async () => {
  //   //passing getData method to the lifecycle method
  //   // const response = await fetch('https://geolocation-db.com/json/');
  //   // const data = await response.json();
  //  await getClientIp();
  // }, []);

  const handleSessionManagement = async () => {
    let tokenBearer = localStorage.getItem("FBIdToken");
    if (tokenBearer) {
      setIsUserPresent(true);
      let token = tokenBearer.split("Bearer ")[1];
      const decodedToken = jwt.decode(token);

      if (decodedToken.exp * 1000 < Date.now()) {
        console.log("is here");
        dispatch({ type: "SET_UNAUTHENTICATED" });
        localStorage.removeItem("FBIdToken");
        //TODO Show notification as logged out because of session timed out?
        window.location.href = "/signin";
      } else {
        dispatch({ type: "SET_AUTHENTICATED" });
        let result = await getClientIp();
        if (result) {
          axios.defaults.headers.common["x-forwarded-for"] = result;
          axios.defaults.headers.common["ip"] = result;
        }
        axios.defaults.headers.common["Authorization"] = tokenBearer;
        dispatch(getUserData());
      }
    } else {
      // window.location.href = "/signin";
      let route = window.location.href.split("/")[3]?.split("?")[0];
      if (route.includes("dashboard") || route === "") {
        window.location.href = "/signin";
      }

      setIsUserPresent(false);
    }
  };

  const business = useSelector((state) => selectBusiness(state));
  const user = useSelector((state) => selectUser(state));

  const [shouldRenderPaymentResult, setShouldRenderPaymentResult] = useState(false);
  const [shouldRenderResetPasswordForm, setShouldRenderResetPasswordForm] = useState(false);
  const [shouldRenderBankAccountResult, setShouldRenderBankAccountResult] = useState(false);

  const [paymentID, setPaymentID] = useState("");
  const [paymentHasError, setPaymentHasError] = useState(false);
  const [isModeVerify, setIsModeVerify] = useState(false);
  const [currentRoute, setCurrentRote] = useState("");
  var isOnline = navigator.onLine;

  useEffect(() => {
    let dashboardType = localStorage.getItem("dashboardType");
    localStorage.setItem("dashboardType", "payedaInvoice");

    if (!dashboardType) {
      if (user && user.usage_type && user.usage_type.isTransferMoney) {
        localStorage.setItem("dashboardType", "payedaBank");
      } else if (user && user.usage_type && user.usage_type.isInvoicing) {
        localStorage.setItem("dashboardType", "payedaInvoice");
      } else if (user && user?.inviteeInfo?.invitation_code) {
        localStorage.setItem("dashboardType", "payedaInvoice");
      } else {
        localStorage.setItem("dashboardType", "payedaInvoice");
      }
    }
  }, [user]);

  const getPaymentIDFromUrl = (payment_id) => {
    if (payment_id) {
      dispatch(getPaymentResult(payment_id));
      localStorage.setItem("payment_id", payment_id);
    }
  };

  //for payment result
  useEffect(async () => {
    let currentRoute = window.location.href.split("/")[3]?.split("?")[0];
    let payment_id;
    let error;
    setCurrentRote(currentRoute);
    payment_id = new URLSearchParams(location.search).get("payment_id");
    error = new URLSearchParams(location.search).get("error");
    if (payment_id) {
      setPaymentID(payment_id);
    }

    if (currentRoute === "payment-result") {
      setShouldRenderPaymentResult(true);
      if (error) {
        setPaymentHasError(true);
      } else {
        await getPaymentIDFromUrl(payment_id);
      }
    } else if (currentRoute === "connect-bank-account") {
      setShouldRenderBankAccountResult(true);
    } else if (currentRoute === "forgot-password") {
      setShouldRenderResetPasswordForm(true);
      setIsModeVerify(false)

    } else if (currentRoute === "verify-email") {
      setShouldRenderResetPasswordForm(true);
      setIsModeVerify(true)
    }
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <UiProvider>
        <AlertNotification />

        {!isOnline && alert("You are offline, please check your internet connection")}

        {console.log("current", currentRoute)}
        {currentRoute === "view-receipt" ? (
          <>
            <Route path="/view-receipt" render={(props) => <ViewReceipt {...props} />} />
          </>
        ) : (
          <>
            {shouldRenderPaymentResult || shouldRenderResetPasswordForm || shouldRenderBankAccountResult ? (
              <>
                {shouldRenderPaymentResult && <PaymentResult paymentID={paymentID} paymentHasError={paymentHasError} />}
                {shouldRenderResetPasswordForm && <PasswordResetForm isModeVerify={isModeVerify} />}
                {shouldRenderBankAccountResult && <ConnectBankAccountResult />}
              </>
            ) : (
              <>
                {user.isLoading && <Loading loading={user.isLoading} background="transparent" loaderColor="#0CC474" />}

                {(!user || !user.authenticated) && !user.isLoading && (
                  <>
                    <Router user={user} business={business} isUserPresent={isUserPresent} />
                  </>
                )}

                {user && user.authenticated && (
                  <>
                    {!user.isLoading && (
                      <ThemeProvider theme={defaultTheme}>
                        <PermissionsProvider>
                          <Bar user={user} business={business} isUserPresent={isUserPresent} />
                        </PermissionsProvider>
                      </ThemeProvider>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </UiProvider>
    </>
  );
});

export default memo(App);
