import axios from "axios";
import Swal from "sweetalert2";
import { API_BASE } from "../env";
import { getSelfBankAccounts } from "./bankingActions";
import { getCustomers } from "./customerActions";
import { getSuppliers } from "./supplierActions";
import { clearErrors, setErrors, setSuccess, startLoading } from "./uiActions";
import { getSideBarItems, getUserData } from "./userActions";

// axios.defaults.withCredentials = true;

let tokenBearer = localStorage.getItem("FBIdToken");
axios.defaults.headers.common["Authorization"] = tokenBearer;

export const createBusiness = (details, isFinish, shouldShowAwaitForApprovalMessage, history) => async (dispatch) => {
  try {
    console.log("isFinish: " + isFinish, shouldShowAwaitForApprovalMessage)
    let businessDetails = await axios({
      method: "put",
      url: `${API_BASE}/v1/account/team`,
      data: details,

    });

    console.log("details", businessDetails)
    // dispatch({ type: "BUSINESS_CREATE_STARTED" });
    // let businessDetails = await axios({
    //   method: "post",
    //   url: `${API_BASE}/account/create/business`,
    //   data: details,
    //   headers: {
    //     "Content-Type": `multipart/form-data; boundary=${details._boundary}`
    //   }
    // });

    dispatch({ type: "BUSINESS_CREATE_SUCCESS", payload: businessDetails });

    // dispatch(getUserData())
    // dispatch(getBusinessDetails());

    if (isFinish) {
      dispatch(getBusinessDetails());
      dispatch(setSuccess("Successfully created new business."));


      let businessHtml = `<p>
    Please wait for your account approval, we will inform you by e-mail`;
      let customerHtml = "You can now start managing your invoices and make payment securely";
      let html;

      if (shouldShowAwaitForApprovalMessage) html = businessHtml;
      else html = customerHtml;

      Swal.fire({
        title: "We saved your information successfully!",
        html: html,
        icon: "success",
      });
      history.push("/dashboard");
    }

  } catch (err) {
    Swal.close();

    if (err.response) {
      console.log(err.response);
      dispatch({ type: "BUSINESS_CREATE_FAILED" });
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateBusiness = (details, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios({
      method: "post",
      url: `${API_BASE}/update-business`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });
    dispatch(getUserData());
    dispatch(getBusinessDetails());
    dispatch(setSuccess("Successfully updated business information."));
    dispatch(clearErrors());
    dispatch(startLoading());
    history.push("/dashboard/verify-account");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getUploadedFileStatuses = () => async (dispatch) => {

  try {
    let response = await axios.get(`${API_BASE}/v1/account/docs/info`);
    dispatch({
      type: "SET_FILE_STATUSES",
      payload: response.data,
    });
    dispatch(clearErrors());
  } catch (err) {

    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}

export const getIdRearPage = (accountId, accountType) => async (dispatch) => {

  try {
    dispatch({ type: "ID_REAR_LOADING" });

    let response = await axios.get(`${API_BASE}/v1/account/docs?id=` + accountId + "&account_type=" + accountType + "&file_type=" + 3);
    dispatch({
      type: "SET_ID_REAR_PAGE",
      payload: response.data.data,
    });
    window.open(response.data.data, "_blank");

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: "No id rear page file found" }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}
export const getIdFrontPage = (accountId, accountType) => async (dispatch) => {

  try {
    dispatch({ type: "ID_FRONT_LOADING" });

    let response = await axios.get(`${API_BASE}/v1/account/docs?id=` + accountId + "&account_type=" + accountType + "&file_type=" + 2);
    dispatch({
      type: "SET_ID_FRONT_PAGE",
      payload: response.data.data,
    });
    window.open(response.data.data, "_blank");

    dispatch(clearErrors());
  } catch (err) {

    if (err.response) {
      console.log(err.response);
      dispatch({ type: "STOP_LOADING_BUTTON" });
      dispatch(setErrors({ general: "No id front page file found" }));

      // dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}
export const getBankStatement = (accountId, accountType) => async (dispatch) => {

  try {
    dispatch({ type: "BANK_STATEMENT_LOADING" });

    let response = await axios.get(`${API_BASE}/v1/account/docs?id=` + accountId + "&account_type=" + accountType + "&file_type=" + 1);
    dispatch({
      type: "SET_BANK_STATEMENT",
      payload: response.data.data,
    });
    window.open(response.data.data, "_blank");

    dispatch(clearErrors());
  } catch (err) {

    if (err.response) {
      dispatch(setErrors({ general: "No bank statement file found" }));

      console.log(err.response);
      // dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}

export const getLogo = (accountId, accountType) => async (dispatch) => {

  try {
    dispatch({ type: "LOGO_LOAADING" });

    let response = await axios.get(`${API_BASE}/v1/account/docs?id=` + accountId + "&account_type=" + accountType + "&file_type=" + 0);
    dispatch({
      type: "SET_LOGO_LOADING",
      payload: response.data.data,
    });
    window.open(response.data.data, "_blank");

    dispatch(clearErrors());
  } catch (err) {

    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: "No logo file found" }));

      // dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}
export const getCompanyStatement = (accountId, accountType) => async (dispatch) => {

  try {
    dispatch({ type: "COMPANY_STATEMENT_LOADING" });

    let response = await axios.get(`${API_BASE}/v1/account/docs?id=` + accountId + "&account_type=" + accountType + "&file_type=" + 4);
    dispatch({
      type: "SET_COMPANY_STATEMENT",
      payload: response.data.data,
    });
    window.open(response.data.data, "_blank");



    dispatch(clearErrors());
  } catch (err) {

    if (err.response) {
      console.log(err.response);
      dispatch({ type: "STOP_LOADING_BUTTON" });
      dispatch(setErrors({ general: "No company certificate file found" }));

      // dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}

export const handleUploadFile = (details) => async (dispatch) => {
  try {
    dispatch({ type: "START_LOADING_BUTTON" });

    await axios.post(`${API_BASE}/v1/account/docs`, details);
    dispatch(getUploadedFileStatuses())
    setTimeout(() => {
      dispatch(getUploadedFileStatuses())

    }, 5000)
    dispatch(clearErrors());
  } catch (err) {

    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}



// not used
export const getCompanyHouseInformation = (number, isUpdate) => async (dispatch) => {
  try {
    dispatch({ type: "SET_COMPANY_HOUSE_INFORMATION_LOADING" });

    let response = await axios.get(`${API_BASE}/v1/account/getCompanyInformation`, {
      params: {
        companyNumber: number,
        isUpdate: isUpdate,
      },
    });
    console.log("getCompanyHouseInformation", response);
    dispatch({
      type: "SET_COMPANY_HOUSE_INFORMATION",
      payload: response.data.businessData,
    });
    // dispatch(getBusinessDetails());

    dispatch(setSuccess("Successfully verified business"));
    dispatch({ type: "STOP_LOADING_BUTTON" });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch({ type: "SET_COMPANY_HOUSE_INFORMATION_STOP_LOADING" });
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getHMRCInformation = (textFieldValue) => async (dispatch) => {
  try {
    dispatch({ type: "START_LOADING_BUTTON" });

    let response = await axios.get(`${API_BASE}/v1/account/getHMRCInformation`, {
      params: {
        vat_number: textFieldValue,
      },
    });
    console.log("getHMRCInformation", response);
    dispatch({
      type: "SET_HMRC_INFORMATION",
      payload: response.data,
    });
    // dispatch(getBusinessDetails());

    dispatch(setSuccess("Successfully verified tax id"));

    dispatch({ type: "STOP_LOADING_BUTTON" });

    dispatch(clearErrors());
  } catch (err) {
    dispatch({
      type: "SET_HMRC_INFORMATION",
      payload: null,
    });
    if (err.response) {
      console.log(err.response);
      dispatch({ type: "STOP_LOADING_BUTTON" });
      // dispatch(setErrors({ general: err.response.data.message }));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

// not used
export const getBusinessDetails = () => async (dispatch) => {
  try {
    dispatch({ type: "BUSINESS_DATA_FETCH_STARTED" });

    let business = await axios.get(`${API_BASE}/v1/account/team`);
    let businessDetails = { ...business.data };
    console.log("businessDetails", businessDetails);
    if (businessDetails.data.approve === 2 || businessDetails.data.approve === 1) {
      dispatch(getSideBarItems())
      dispatch(getSelfBankAccounts())
    }

    dispatch({ type: "BUSINESS_DATA_FETCH_SUCCESS", payload: businessDetails.data });

  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const updateTeamAccount = (details) => async (dispatch) => {
  try {
    dispatch({ type: "BUSINESS_DATA_FETCH_STARTED" });
    await axios.post(`${API_BASE}/v1/account/update/business`, details);
    dispatch(setSuccess("Successfully updated team account"))
    dispatch(getBusinessDetails());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}

export const getStatistics = (details) => async (dispatch) => {
  try {
    dispatch({ type: "STATISTICS_DATA_FETCH_STARTED" });
    let data = await axios.get(`${API_BASE}/v1/account/statistics`);
    dispatch({ type: "STATISTICS_DATA_FETCH_SUCCESS", payload: data.data });

  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
}
export const getPersonalBusinessDetails = () => async (dispatch) => {
  try {
    let business = await axios.get(`${API_BASE}/business`);
    let businessDetails = { ...business.data };
    console.log("person", businessDetails);
    //TODO butun business bilgilerini "axios.get(`${API_BASE}/business`);" yaptigindaki requestte al ki tekrardan asagidakileri cagirma
    dispatch({ type: "SET_BUSINESS", payload: businessDetails });
    // dispatch(getSuppliers());
    // dispatch(getMembers());
    // dispatch(getAllProducts());
    // dispatch(getIncomingInvoices());
    // dispatch(getIncomingReceipts());
    // dispatch(getNotifications());
    // dispatch(getUnreadNotifications());
    // dispatch(getActivityLogs());
    // dispatch(getSelfBankAccounts());
    // dispatch(getAllFinanceAccounts());  // to list All Bank names in the dropdown
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateLogo = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios({
      method: "post",
      url: `${API_BASE}/update-logo`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully uploaded new logo."));
    //TODO Return new business details in the request above and set it rather than calling the "getBusinessDetails" all over again
    dispatch(getBusinessDetails());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateDocuments = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios({
      method: "post",
      url: `${API_BASE}/update-documents`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully updated documents."));
    //TODO Return new business details in the request above and set it rather than calling the "getBusinessDetails" all over again
    dispatch(getBusinessDetails());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

//TODO What is the difference between this and creating a Personal account in the signup? (if no difference why not one endpoint/function ?)
export const createPerson = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let business = await axios({
      method: "post",
      url: `${API_BASE}/create-person`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });

    let businessData = business.data.data;
    dispatch({ type: "SET_BUSINESS", payload: businessData });
    dispatch(setSuccess("Successfully created personal account."));
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const invitePeople = (details, isSupplier) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/v1/invite`, details);
    dispatch(setSuccess("Successfully invited"));
    if (isSupplier) {
      dispatch(getSuppliers({}));
    } else {
      dispatch(getCustomers({}));
    }
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const mergeAccounts = (details, history) => async (dispatch) => {
  console.log("details");
  try {
    dispatch({ type: "MERGING_STARTED" });
    const success = await axios.post(`${API_BASE}/v1/invite/merge-account`, {
      inviteCode: details,
    });
    if (success.data.success === "success") {
      dispatch({ type: "MERGING_SUCCESS" });
      dispatch(setSuccess("Successfully merged accounts"));
      // dispatch(getMergedAccounts({}));
      // dispatch(getBusinessDetails());
      dispatch(clearErrors());
      // history.push("/dashboard/merged-accounts");
      dispatch({ type: "INVITER_DATA_FETCH_SUCCESS", payload: null });
      history.push("/dashboard/suppliers");
    }
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getInviterInformation = (details) => async (dispatch) => {
  try {
    dispatch({ type: "INVITER_DATA_FETCH_STARTED" });
    let business = await axios.get(`${API_BASE}/v1/invite/${details}`);
    console.log("RESULT", business);
    dispatch({ type: "INVITER_DATA_FETCH_SUCCESS", payload: business.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors({ general: err.response.data.message }));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updatePreferences = (details) => async (dispatch) => {
  try {
    let business = await axios.put(`${API_BASE}/update-preferences`, details);
    let businessDetails = { ...business.data };
    dispatch({ type: "SET_BUSINESS", payload: businessDetails });
    dispatch(setSuccess("Successfully updated"));
    dispatch(getBusinessDetails());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getMergedAccounts = ({ ...params }) => async (dispatch) => {
  try {
    dispatch({ type: "MERGED_ACCOUNTS_DATA_FETCH_STARTED" });

    const success = await axios.get(`${API_BASE}/v1/supplier`, {
      params: {
        perPage: params.perPage ? params.perPage : 10,
        page: params.page ? params.page : 1,
        searchField: params.search ? params.search : ""
      },
    });
    dispatch({
      type: "MERGED_ACCOUNTS_DATA_FETCH_SUCCESS",
      payload: success.data,
    });
    dispatch({ type: "SET_LAST_PAGE", payload: success.data.lastPage });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateFinancialInformations = (details) => async (dispatch) => {
  console.log("details", details);
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/update-financial-information`,
      data: details,
    });
    dispatch(setSuccess("Successfully updated"));

    dispatch(getBusinessDetails());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getBusinessStat = () => async (dispatch) => {
  try {
    let response = await axios({
      method: "get",
      url: `${API_BASE}/business/generalStat`,
    });

    dispatch({ type: "BUSINESS_STATISTICS_SUCCESS", payload: response.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getAllPackages = (dashboardType) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    let response = await axios({
      method: "get",
      url: `${API_BASE}/v1/packages`,
      params: {
        dashboardType: dashboardType,
      },
    });

    dispatch({ type: "BUSINESS_PLANS", payload: response.data });

    // dispatch(setSuccess("Successfully bought package"))

    // dispatch(getBusinessDetails())

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const buyBusinessPlan = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/v1/packages/payment`,
      data: details,
    });
    // dispatch(setSuccess("Successfully bought package"))

    // dispatch(getBusinessDetails())

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getContacts = (Id) => async (dispatch) => {
  try {
    dispatch({ type: "START_FETCHING_CONTACTS" });
    let data = await axios({
      method: "get",
      url: `${API_BASE}/business/get-contacts`,
      params: {
        groupId: Id || null,
      },
    });
    console.log("data", data);
    dispatch({ type: "SET_CONTACTS", payload: data.data.allContacts });
    dispatch({ type: "SET_CONTACT_GROUPS", payload: data.data.allGroups });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const addContact = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/add-contact`,
      data: details,
    });
    dispatch(setSuccess("Successfully added new contact"));
    dispatch(getContacts());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const mailToContact = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/mail-to-contact`,
      data: details,
    });
    dispatch(setSuccess("Successfully sent messages"));
    dispatch(getContacts());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const editContact = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/edit-contact`,
      data: details,
    });
    dispatch(setSuccess("Successfully edited the contact"));
    dispatch(getContacts());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeContact = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/remove-contact`,
      data: details,
    });
    dispatch(setSuccess("Successfully removed the contacts"));
    dispatch(getContacts());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateReceivePaymentAccount = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/update-receive-payment-details`,
      data: details,
    });
    dispatch(setSuccess("Successfully edited receive payment account details"));
    dispatch(getBusinessDetails());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
