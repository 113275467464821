import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { deletePackage, getAllPackages } from "../../../store/actions/packageActions";
import { useUiContext } from "../../../Contexts/UiContext";

import PackageCard from "./PackageCard";
import PackageDialog from "./PackageDialog";
import { getVerifiedBusinesses } from "../../../store/actions/adminActions";
const Packages = () => {
  const { errors, sidebarOpen, clearErrors, setErrors } = useUiContext();

  const dispatch = useDispatch();
  const packages = useSelector((state) => state.package?.allPackages?.data?.packages);

  const [openAddPackageDialog, setOpenAddPackageDialog] = useState(false);
  const [openEditPackageDialog, setOpenEditPackageDialog] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  console.log("packages", packages);
  useEffect(() => {
    dispatch(getAllPackages());
  }, []);

  useEffect(() => {
    if (selectedPackage?.id) {
      dispatch(getVerifiedBusinesses());
    }
  }, [selectedPackage]);

  const handlePackageDelete = (id) => {
    let body = {
      id: id
    }
    console.log("dataaa", body);
    dispatch(deletePackage(body))
  }
  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        style={{ marginBottom: "15px" }}
        onClick={() => setOpenAddPackageDialog(true)}
      >
        Add Package
      </Button>

      <PackageDialog
        open={openAddPackageDialog}
        setOpen={setOpenAddPackageDialog}
        setErrors={setErrors}
        isEdit={false}
      />

      <PackageDialog
        open={openEditPackageDialog}
        setOpen={setOpenEditPackageDialog}
        setErrors={setErrors}
        isEdit={true}
        selectedPackage={selectedPackage}
      />
      <Grid container spacing={3}>
        {packages &&
          packages?.map((pk) => {
            return (
              <Grid item xs={4} md={4}>
                {
                  <PackageCard
                    data={pk}
                    name={pk.package_name}
                    setOpen={setOpenEditPackageDialog}
                    setSelectedPackage={setSelectedPackage}
                    selectedPackage={selectedPackage}
                    handleDelete={handlePackageDelete}
                  />
                }
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default Packages;
