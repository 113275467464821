import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { deletePackage, getAllPackages } from "../../../store/actions/packageActions";
import { useUiContext } from "../../../Contexts/UiContext";
import { Avatar, Card, CardContent, CardMedia, makeStyles, Typography, TextField } from "@material-ui/core";

import { getNpcPRomotions, getSupplier, getVerifiedBusinesses } from "../../../store/actions/adminActions";

const NpcPromotionsList = () => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.admin?.promotions?.data);

  useEffect(() => {
    dispatch(getNpcPRomotions());
  }, []);

  const handlePackageDelete = (id) => {
    let body = {
      id: id,
    };
    console.log("dataaa", body);
    dispatch(deletePackage(body));
  };
  const useStyles = makeStyles((theme) => ({
    text: {
      margin: theme.spacing(0, 0, 0.5),
      //color: theme.palette.secondary.contrastText,
    },
    avatar: {
      verticalAlign: "middle",
      marginRight: theme.spacing(0.5),
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      margin: theme.spacing(2, 2, 0),
    },
    card: {
      borderRadius: 15,
      maxWidth: "500x",
      minWidth: "500x",
      backgroundColor: theme.palette.background.card,
    },
    cardContent: {
      padding: theme.spacing(2, 0, 0, 0),
    },
  }));
  const classes = useStyles();

  const getSupplierInfo = (supplierId) => {
    //TODO: complete

    dispatch(getSupplier(supplierId));
  };

  const getUsageHistory = (supplierId) => {
    //TODO: complete
  };
  const handleEdit = (supplierId) => {
    //TODO: complete
  };
  const handleDelete = (supplierId) => {
    //TODO: complete
  };
  return (
    <>
      <Grid container spacing={3}>
        {packages &&
          packages?.map((pk) => {
            return (
              <Grid item xs={6} md={6} key={pk.id}>
                <Card variant="outlined" className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography className={classes.text} color="textSecondary" variant="subtitle1" align="right" style={{ fontSize: "12px" }}></Typography>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px 15px" }}>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
                        Promotion Code:
                      </Typography>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px" }}>
                        {pk.promotion_code}
                      </Typography>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px" }}>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
                        Supplier Gift Token:
                      </Typography>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px" }}>
                        {pk.supplier_gift_payment_token}
                      </Typography>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px" }}>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
                        Customer Gift Token:
                      </Typography>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px" }}>
                        {pk.customer_gift_payment_token}
                      </Typography>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px" }}>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
                        Active Until:
                      </Typography>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px" }}>
                        {pk.active_until}
                      </Typography>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px" }}>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
                        Max number of usage:
                      </Typography>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px" }}>
                        {pk.max_number_of_usage}
                      </Typography>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px 15px" }}>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
                        Total usage until now:
                      </Typography>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px" }}>
                        {pk.number_of_usage}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px 15px" }}>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
                        Status:
                      </Typography>
                      <Typography className={classes.text} color="textSecondary" variant="h6" style={{ fontSize: "15px" }}>
                        {pk.isActive ? "Active" : "Inactive"}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px 15px", paddingRight: "5px" }}>
                      <Button style={{ width: "200px", height: "50px" }} color="primary" variant="outlined" onClick={() => handlePackageDelete(pk.id)}>
                        Supplier information
                      </Button>
                      <Button
                        style={{ width: "150px", height: "50px", backgroundColor: "green", color: "white" }}
                        color="primary"
                        variant="outlined"
                        onClick={() => getSupplierInfo(pk.owner_team_id)}
                      >
                        Usage History
                      </Button>
                      <Button
                        style={{ width: "200px", height: "50px", backgroundColor: "orange", color: "white" }}
                        color="primary"
                        variant="outlined"
                        onClick={() => handlePackageDelete(pk.id)}
                      >
                        Edit
                      </Button>

                      <Button style={{ width: "200px", height: "50px" }} color="secondary" variant="outlined" onClick={() => handlePackageDelete(pk.id)}>
                        Delete
                      </Button>
                    </div>

                    {/* Rest of the fields */}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default NpcPromotionsList;
