import React, { useState } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";

import DialogComponent from "./DialogComponent";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DataFilterDialogByDate = ({ open, setOpen, onSubmit, isLoading, title, children }) => {
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setselectedEndDate] = useState(new Date());

    const onDialogSubmit = async () => {
        const startDate = await convertDate(selectedStartDate);
        const endDate = await convertDate(selectedEndDate);

        onSubmit(startDate, endDate)
    }

    const convertDate = (date) => {
        let dateString = date.toISOString().split('T')[0];
        var dateParts = dateString.split("-");
        // mm/dd/yyy
        let formatted = dateParts[1] + '/' + dateParts[2] + '/' + dateParts[0];
        return formatted;
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <DialogComponent
                open={open}
                setOpen={setOpen}
                title={title}
                onSubmit={onDialogSubmit}
                withActions={true}
                submitButtonText={isLoading ? "Loading" : "Submit"}
                isDisabled={isLoading ? true : false}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>

                        <KeyboardDatePicker
                            inputVariant="outlined"
                            margin="dense"
                            id="start_date"
                            name="start_date"
                            label="Start Date"
                            value={selectedStartDate}
                            openTo="year"
                            format="yyyy-MM-dd"
                            size="small"
                            views={["year", "month", "date"]}

                            onChange={(date) => {
                                setSelectedStartDate(date)
                            }}
                            fullWidth
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>

                        <KeyboardDatePicker
                            margin="dense"
                            inputVariant="outlined"
                            id="end_date"
                            name="end_date"
                            label="End Date"
                            value={selectedEndDate}
                            openTo="year"
                            format="yyyy-MM-dd"
                            size="small"
                            views={["year", "month", "date"]}

                            onChange={(date) => {
                                setselectedEndDate(date)
                            }}
                            fullWidth
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </Grid>


                    {children}

                </Grid>

            </DialogComponent >
        </MuiPickersUtilsProvider>

    );
};

export default DataFilterDialogByDate;
