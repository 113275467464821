import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({
    component: Component,
    authenticated,
    isUserPresent,
    ...rest
}) => {
    return (
        <Route {...rest}

            render={(props) =>
                (isUserPresent || authenticated) ? (
                    <Component {...rest} {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/signin",
                            state: {
                                from: props.location,
                            },
                        }}
                    />
                )
            }
        />
    );
};


export default ProtectedRoute;
