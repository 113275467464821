import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Texfield from "@material-ui/core/TextField";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneInputComponent from "../../../Components/PhoneInputComponent";
import { useUiContext } from "../../../Contexts/UiContext";
import { addFinanceAccount, addNpcPromotion, getVerifiedBusinesses } from "../../../store/actions/adminActions";
import verifySchema from "../../../util/yup/verifySchema";
import { addFinanceAccountSchema, addressSchema } from "../../../util/yup/yupSchemas";
import { useAdminStyles } from "../AdminStyles";
import DropdownComponent from "../../../Components/DropdownComponent";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory } from "react-router-dom";

const AddNpcPromotion = () => {
  const { register, control, handleSubmit } = useForm();
  const history = useHistory();

  const dispatch = useDispatch();
  const { errors, sidebarOpen, clearErrors, setErrors } = useUiContext();
  const verifiedBusinesses = useSelector((state) => state.admin.verifiedBusinesses);

  const classes = useAdminStyles();
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [customerGift, setCustomerGift] = useState(0);
  const [supplierGift, setSupplierGift] = useState(0);
  const [maxUsage, setMaxUsage] = useState(0);
  const [promotionCode, setPromotionCode] = useState("");

  const [selectedDate, setSelectedDate] = useState(new Date());

  const onSubmit = async () => {
    let data = {
      promotion_code: promotionCode,
      owner_team_id: selectedSupplier,
      supplier_gift_token: parseInt(supplierGift),
      customer_gift_token: parseInt(customerGift),
      max_number_of_usage: parseInt(maxUsage),
      active_until: selectedDate.toISOString().split("T")[0],
    };
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (value === undefined || value === null || value === "") {
          console.log("fill all the fiends");
          return;
        }
      }
    }
    dispatch(addNpcPromotion(data, history));
  };

  const getSuppliersList = () => {
    let lists = [];
    if (verifiedBusinesses.data && verifiedBusinesses.data.length > 0) {
      verifiedBusinesses.data &&
        verifiedBusinesses.data.forEach((cust) => {
          lists.push({ value: cust.id, label: cust.business_name });
        });
    }
    return lists;
  };

  useEffect(() => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(newDate.getFullYear() + 1);
    setSelectedDate(newDate);
    dispatch(getVerifiedBusinesses({ perPage: 1000, page: 1 }));
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen,
        })}
      >
        <div className={classes.root}>
          <FormControl style={{ display: "flex" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DropdownComponent
                  required
                  fullWidth
                  id="selectedInformation"
                  name="selectedInformation"
                  label="Select Supplier"
                  items={getSuppliersList()}
                  onChange={(e) => {
                    setSelectedSupplier(e.target.value);
                  }}
                  error={errors.selectedInformation ? true : false}
                  helperText={errors.selectedInformation ?? ""}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  id="promotion_code"
                  name="promotion_code"
                  label="Promotion Code"
                  value={promotionCode}
                  onChange={(e) => setPromotionCode(e.target.value)}
                  inputRef={register}
                  error={errors.email ? true : false}
                  helperText={errors.email ?? ""}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      type: "number",
                    },
                  }}
                  value={supplierGift}
                  onChange={(e) => setSupplierGift(e.target.value)}
                  id="supplier_gift_token"
                  name="supplier_gift_token"
                  label="Supplier Gift Token"
                  inputRef={register}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      type: "number",
                    },
                  }}
                  value={customerGift}
                  onChange={(e) => setCustomerGift(e.target.value)}
                  id="customer_gift_token"
                  name="customer_gift_token"
                  label="Customer Gift Token"
                  inputRef={register}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  margin="dense"
                  id="active_until"
                  name="active_until"
                  label="Expire Date"
                  value={selectedDate}
                  openTo="year"
                  format="yyyy-MM-dd"
                  size="small"
                  views={["year", "month", "date"]}
                  onChange={(date) => setSelectedDate(date)}
                  fullWidth
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Texfield
                  required
                  id="max_number_of_usage"
                  name="max_number_of_usage"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      type: "number",
                    },
                  }}
                  value={maxUsage}
                  onChange={(e) => setMaxUsage(e.target.value)}
                  label="Max Usage"
                  inputRef={register}
                  error={errors.email ? true : false}
                  helperText={errors.email ?? ""}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button fullWidth style={{ marginBottom: "15px" }} variant="contained" color="secondary" onClick={() => onSubmit()}>
                  Add Npc Promotion
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </div>
      </main>
    </MuiPickersUtilsProvider>
  );
};

export default AddNpcPromotion;
