import { Avatar, Card, CardContent, CardMedia, makeStyles, Typography, Button, TextField } from "@material-ui/core";
import React from "react";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { getCustomersBySearchQuery } from "../../../store/actions/customerActions";
import { getVerifiedBusinesses } from "../../../store/actions/adminActions";
import { assignPackageToCustomer } from "../../../store/actions/packageActions";

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(0, 0, 0.5),
    //color: theme.palette.secondary.contrastText,
  },
  avatar: {
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: theme.spacing(2, 2, 0),
  },
  card: {
    borderRadius: 15,
    maxWidth: "300px",
    minWidth: "300px",
    backgroundColor: theme.palette.background.card,
  },
  cardContent: {
    padding: theme.spacing(2, 0, 0, 0),
  },
}));

export default function PackageCard({
  data,
  name,
  features,
  fee,
  type,
  bankToken,
  transferToken,
  paymentToken,
  comissionRate,
  setOpen,
  setSelectedPackage,
  selectedPackage,
  handleDelete,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openSearchCustomerDialog, setOpenSearchCustomerDialog] = useState(false);
  const [customerToSearch, setCustomerToSearch] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const customers = useSelector((state) => state.customer.customers);

  // useEffect(() => {
  //   if (customerToSearch?.length > 3) {
  //     dispatch(getCustomersBySearchQuery(customerToSearch));
  //   }
  // }, [customerToSearch]);

  const verifiedBusinesses = useSelector((state) => state.admin.verifiedBusinesses);

  const admin = useSelector((state) => state.admin);
  const [gettingBusinesses, setGettingBusinesses] = useState(false);

  const handlePackageAssign = () => {
    let dataToSend = {};
    if (selectedCustomer && selectedPackage) {
      dataToSend.customer_id = selectedCustomer.id;
      dataToSend.package_id = selectedPackage.id;

      dispatch(assignPackageToCustomer(dataToSend));
      setOpenSearchCustomerDialog(false);
    }
  };
  const renderSearchCustomer = () => {
    return (
      <DialogComponent open={openSearchCustomerDialog} setOpen={setOpenSearchCustomerDialog} title="Find a customer to assign a package" description="" withActions={false}>
        <Autocomplete
          id="combo-box-demo"
          options={verifiedBusinesses}
          getOptionLabel={(option) => option.name + "-" + option.user_email + " - " + option.account_type}
          onChange={(event, newValue) => {
            setSelectedCustomer(newValue);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for customer"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setCustomerToSearch(e.target.value);
              }}
            />
          )}
        />
        <br />
        <Button color="secondary" variant="outlined" onClick={() => handlePackageAssign()}>
          Set Package for customer
        </Button>
      </DialogComponent>
    );
  };
  return (
    <Card variant="outlined" className={classes.card} style={{ display: "inline-block" }}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.text} color="textSecondary" variant="subtitle1" align="right" style={{ fontSize: "12px" }}>
          {data.packageAddingOption === "offer" && (
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setOpenSearchCustomerDialog(true);
                setSelectedPackage(data);
              }}
            >
              Assign to Customer
            </Button>
          )}

          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setOpen(true);
              setSelectedPackage(data);
            }}
          >
            Edit
          </Button>

          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              handleDelete(data._id);
            }}
          >
            Delete
          </Button>
        </Typography>{" "}
        <Typography className={classes.text} color="textSecondary" variant="h6" align="center" style={{ fontSize: "15px" }}>
          {data.package_name} ({data.package_type})
        </Typography>
        <Typography className={classes.text} color="textSecondary" variant="subtitle1" align="center" style={{ fontSize: "20px", color: "black" }}>
          {" "}
          GBP {data.package_fee}
        </Typography>
        {verifiedBusinesses && renderSearchCustomer()}
      </CardContent>
    </Card>
  );
}
