import { Grid, Link, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import userEvent from "@testing-library/user-event";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { Alert, AlertTitle } from "@material-ui/lab";
import { BUSINESS_MODELS, BUSINESS_TYPES } from "../../../util/constants/companyInformation";
import { useState } from "react";
import { getBankStatement, getCompanyStatement, getIdFrontPage, getIdRearPage, getLogo, getUploadedFileStatuses, handleUploadFile } from '../../../store/actions/businessActions';

const documentTypes = [
  { value: "logo", label: "LOGO" },

  { value: "id_rear_page", label: "ID Rear Page" },
  { value: "id_front_page", label: "ID Front Page" },
  { value: "company_statement", label: "Company Statement" },
  { value: "bank_statement", label: "Bank Statement" }
];

const BusinessDocuments = ({ business }) => {
  const dispatch = useDispatch()
  const [loadingFileType, setLoadingFileType] = useState([]);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUploadedFileStatuses())
  }, [])


  const handleDownloadDocuments = (fileType) => {
    let teamType = user.team_type === "Business" ? 0 : 1;
    let teamId = user.team_id;

    if (fileType === "id_rear_page") {
      dispatch(getIdRearPage(teamId, teamType))
    } else if (fileType === "id_front_page") {
      dispatch(getIdFrontPage(teamId, teamType))
    } else if (fileType === "company_statement") {
      dispatch(getCompanyStatement(teamId, teamType))

    } else if (fileType === "bank_statement") {
      console.log("runs")
      dispatch(getBankStatement(teamId, teamType))

    } else if (fileType === "logo") {
      dispatch(getLogo(teamId, teamType))

    }
  }

  const handleGetFiles = (value) => {
    if (business[value]) {
      console.log("downloading")
    } else {
      setLoadingFileType([...loadingFileType, value])
      console.log("requesting from server")
    }
  }

  // TODO: remove loading file array when requested from server

  const renderDocuments = () => {
    return (
      <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA", cursor: "pointer" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" onClick={() => alert("hey")}>
              <strong>Documents</strong>
            </Typography>
          </Grid>

          {documentTypes.map((doc) => {
            return (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography onClick={() => handleDownloadDocuments(doc.value)} style={{ cursor: "pointer" }}>
                    Download {doc.label} {loadingFileType.includes(doc.value) ? <small>Loading...</small> : ""}
                  </Typography>
                </Grid>
              </>
            )
          })}
          <br />
        </Grid >
      </Alert >

    );
  };

  const renderAccountInformation = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
            <Typography variant="h6">
              <strong>General Information</strong>
            </Typography>
            <br />
            <Typography>
              <strong>Company Registration Number:</strong> {business?.company_house_id}
            </Typography>
            <br />
            <Typography>
              <strong>Vat Number:</strong> {business?.vat_number}
            </Typography>
            <br />

            <Typography>
              <strong>Unique Tax Number:</strong> {business?.tax_id}
            </Typography>
            <br />

            <Typography>
              <strong>Phone: </strong> {business?.business_phone}
            </Typography>{" "}
            <br />
            <Typography>
              <strong>Email: </strong> {business?.business_email}
            </Typography>{" "}
            <br />
            <Typography>
              <strong>Address: </strong> {business?.address?.address_line_1 || ""} {business?.address?.address_line_2 || ""}
            </Typography>{" "}
            <br />
            <Typography>
              <strong>Business Type: </strong> {BUSINESS_TYPES.find((type) => type.value === business?.business_type)?.label}
            </Typography>{" "}
            <br />
            <Typography>
              <strong>Business Model: </strong> {BUSINESS_MODELS.find((type) => type.value === business?.business_model)?.label}
            </Typography>{" "}
            <br />
          </Alert>
        </Grid>
      </Grid>
    );
  };

  const renderUsageType = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
            <Typography variant="h6">
              <strong>Platform usage type</strong>
            </Typography>

            {user.team_type === "Business" && (business.awaitingReceivePaymentApproval || business.canReceivePayment) && (
              <>
                <Typography>
                  <strong>Invoicing Type: </strong> {business.account_settings.invoice_settings.isErp ? "Cloud Erp" : "Manual"}
                </Typography>
                <Typography>
                  <strong>Can Receive Payment: </strong>{business.awaitingReceivePaymentApproval && <small>("Awaiting Approval")</small>} {business.canReceivePayment ? <DoneIcon style={{ color: "green" }} /> : <ClearIcon style={{ color: "red" }} />}
                </Typography>

              </>
            )}
            <Typography>
              <strong>Can Transfer Money: </strong> {business.awaitingTransferMoneyApproval && <small>("Awaiting Approval")</small>} {business.canTransferMoney ? <DoneIcon style={{ color: "green" }} /> : <ClearIcon style={{ color: "red" }} />}
            </Typography>

            <Typography>
              <strong>Can Connect Bank Accounts: </strong> {business.awaitingTransferMoneyApproval && <small>("Awaiting Approval")</small>} {business.canConnectBankAccounts ? <DoneIcon style={{ color: "green" }} /> : <ClearIcon style={{ color: "red" }} />}
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    )
  }

  const renderReceivePaymentDetails = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
            <Typography variant="h6">
              <strong>Receive Payment Details</strong>
            </Typography>
            {business.banking && business.banking.length > 0 && (
              <>
                <Typography>
                  <strong>Account Number: </strong> {business?.banking[0].account_number}
                </Typography>
                <Typography>
                  <strong>Sort Code: </strong> {business?.banking[0].sort_code}
                </Typography>
              </>
            )}
          </Alert>
        </Grid>
      </Grid>
    )
  }
  return (
    <BasePage navigation="Dashboard/Documents" business={business} canView={user?.permissions?.document?.view} >
      {renderAccountInformation()}
      {renderDocuments()}
      {renderUsageType()}
      {business.banking && business.banking.length > 0 && renderReceivePaymentDetails()}
    </BasePage >
  );
};

export default BusinessDocuments;
