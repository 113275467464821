
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { UI_API_BASE, API_BASE } from "./../../store/env";

const ViewInvoice = () => {

  const [loading, setLoading] = useState(false);
  const location = useLocation();


  useEffect(() => {
    var id = new URLSearchParams(location.search).get("id");

    window.location.href = `${API_BASE}/v1/invoice/generate/` +
      id;
  }, [])

  return (
    <>
      {loading && <h4>Loading...</h4>}


    </>
  );
};

export default ViewInvoice;
