import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";

import DialogComponent from "../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../Components/DropdownComponent";
import NumberFormatCustom from "../../Components/NumberFormatCustom";

function ProductDialog({
  open,
  setOpen,
  title,
  description,
  errors,
  onSubmit,
  defaultValues,
}) {
  const { register, control, handleSubmit, reset, setValue } = useForm();
  // setValue("currency", defaultValues.currency)

  useEffect(() => {
    if (defaultValues) {
      setValue("currency", "GBP");
    }

    reset(defaultValues);
    // eslint-disable-next-line
  }, [defaultValues, reset]);

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit(onSubmit)}
      withActions={true}
    >
      <TextField
        required
        autoFocus
        margin="dense"
        id="product_name"
        name="product_name"
        label="Product/Servis Name"
        error={errors.product_name ? true : false}
        helperText={errors.product_name ?? ""}
        fullWidth
        inputRef={register}
      />
      <TextField
        required
        margin="dense"
        id="unit_price"
        name="unit_price"
        label="Unit Price"
        error={errors.unit_price ? true : false}
        helperText={errors.unit_price ?? ""}
        fullWidth
        inputRef={register}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
      <TextField
        required
        margin="dense"
        id="vat_percentage"
        name="vat_percentage"
        label="Rate of Vat(%)"
        error={errors.vat_percentage ? true : false}
        helperText={errors.vat_percentage ?? ""}
        type="number"
        fullWidth
        inputRef={register}
      />

      <DropdownComponent
        required
        id="currency"
        name="currency"
        label="Currency"
        defaultValue={"GBP"}
        items={[{ value: "GBP", label: "GBP" }]}
        error={errors.currency ? true : false}
        helperText={errors.currency ?? ""}
        control={control}
      />
      <TextField
        required
        margin="dense"
        id="category"
        name="category"
        label="Category"
        error={errors.category ? true : false}
        helperText={errors.category ?? ""}
        fullWidth
        inputRef={register}
      />
    </DialogComponent>
  );
}

export default ProductDialog;
