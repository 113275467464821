import axios from "axios";
import { setSuccess, setErrors, clearErrors } from "./uiActions";
import { API_BASE } from "../env";

export const addProduct = (details) => async (dispatch) => {
  try {
    let products = await axios.post(`${API_BASE}/v1/product`, details);
    dispatch({ type: "PRODUCT_DATA_FETCH_SUCCESS", payload: products.data.allProducts });
    dispatch(getAllProducts({}))
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors({ general: err.response.data.message }));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllProducts = ({ ...params }) => async (dispatch) => {
  try {
    dispatch({ type: "PRODUCT_DATA_FETCH_STARTED" });

    let product = await axios.get(`${API_BASE}/v1/product`, {
      params: {
        perPage: params.perPage ? params.perPage : 10,
        page: params.page ? params.page : 1,
        searchField: params.search ? params.search : ""
      },
    });

    dispatch({ type: "PRODUCT_DATA_FETCH_SUCCESS", payload: product.data });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const editProduct = (details) => async (dispatch) => {
  try {
    let products = await axios.put(`${API_BASE}/product`, details);
    dispatch({ type: "PRODUCT_DATA_FETCH_SUCCESS", payload: products.data.allProducts });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully edited the product."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeProducts = (products) => async (dispatch) => {
  try {
    let business = await axios.post(`${API_BASE}/v1/product/delete`, products);
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully removed product(s)."));

    dispatch(getAllProducts({}))
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
