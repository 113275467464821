import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE } from "../../store/env";

import axios from "axios";
import "./banking.css";
import { FaCheckCircle, FaSpinner } from "react-icons/fa"; // FaSpinner eklendi
import { Button } from "@material-ui/core";
import { FaExclamationCircle } from "react-icons/fa";
import { getPaymentResult } from "../../store/actions/bankingActions";

const PaymentResult = ({ paymentID, paymentHasError }) => {
  const dispatch = useDispatch();

  const [afterRender, setAfterRender] = useState(false); // internal state
  const [isReceiptReady, setIsReceiptReady] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  let paymentLoading = useSelector((state) => state.banking.paymentLoading);
  let result = useSelector((state) => state.banking);
  console.log("resultttt", result);

  const getResultOnPaymentID = () => {
    if (!paymentLoading) {
      dispatch({ type: "PAYMENT_RESULT_LOADING" });
      setRetryCount((prevRetryCount) => prevRetryCount + 1);
    }
  };

  const checkIfReceiptExists = async () => {
    try {
      let result = await axios(`${API_BASE}/v1/receipt/generate/` + paymentID);

      if (result.status === 200) {
        setIsReceiptReady(true);
      } else setIsReceiptReady(false);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (!isReceiptReady) {
      checkIfReceiptExists();
      dispatch(getPaymentResult(paymentID));

      const interval = setInterval(() => {
        dispatch(getPaymentResult(paymentID));

        checkIfReceiptExists();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [isReceiptReady, retryCount]);

  useEffect(() => {
    setAfterRender(true);
  }, []);

  return (
    <div className="container">
      <div className="square">
        {/* {paymentLoading && "Loading.."} */}
        {afterRender && result?.paymentResponse && !paymentLoading && (
          <>
            {result?.paymentResponse.status === "executed" && (
              <div className="icon">
                <FaCheckCircle className="success-icon" color="green" />
              </div>
            )}
            {result?.paymentResponse.status === "failed" && (
              <div className="icon">
                <FaExclamationCircle className="error-icon" color="red" />
              </div>
            )}
            {!(result?.paymentResponse.status === "executed" || result?.paymentResponse.status === "failed") && (
              <div className="icon">
                <FaSpinner className="loading-icon" color="yellow" />
              </div>
            )}

            <h1 className="title">Payment Result</h1>

            {result?.paymentResponse.status === "failed" && (
              <>
                <p className="content">Payment failed. Please try again later.</p>
                <div className="buttons">
                  <Button color="primary" variant="outlined" onClick={() => getResultOnPaymentID()}>
                    Retry
                  </Button>
                </div>
              </>
            )}
            {!(result?.paymentResponse.status === "executed" || result?.paymentResponse.status === "failed") && (
              <>
                <p className="content">Payment is in progress...</p>
              </>
            )}
            {result?.paymentResponse.status === "executed" && (
              <>
                <p className="content">
                  Payment order has been placed successfully. You can follow the result from our console
                  <br />
                  <br />
                  {isReceiptReady ? (
                    <a style={{ textAlign: "center" }} href={`${API_BASE}/v1/receipt/generate/${paymentID}`}>
                      Download Receipt
                    </a>
                  ) : (
                    " Preparing Receipt.."
                  )}
                </p>

                <br />
              </>
            )}

            {isReceiptReady && (
              <div style={{ textAlign: "center" }}>
                <br />
                <a href="payeda://payeda.dashboard.com">Go To Console</a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentResult;
