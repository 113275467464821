import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyHouseInformation, getHMRCInformation } from "../../../store/actions/businessActions";
import { API_BASE } from "../../../store/env";

//TODO needs translations
function VerifySoleTraderWithVat({
  setErrors,
  business,
  registrationOption,
  setRegistrationOption,
  textFieldName,
  textFieldLabel,
  setTextFieldValue,
  textFieldValue,
  setFormData,
  formData
}) {
  const dispatch = useDispatch();
  const loadingButton = useSelector((state) => state.ui.loadingButton);

  const [isDisabled, setIsDisabled] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [isTextFieldDisabled, setIsTextFieldDisabled] = useState(false)
  const [hrmcError, setHmrcError] = useState(false);
  const [hmrcResult, setHmrcResult] = useState({});

  const [hmrText, setIsHmrcLoading] = useState(false);
  const handleRadioChange = (e) => {
    if (business?.passport_id) {
      setRegistrationOption("passport_id")
    } else if (business?.utr_number) {
      setRegistrationOption("utr_number");
    } else if (business?.vat_number) {
      setRegistrationOption("vat_number");
    } else if (business?.national_insurance_number) {
      setRegistrationOption("national_insurance_number");
    } else {
      let val = e.target.value;
      setRegistrationOption(val);
    }

    setFormData({ ...formData, registrationOption: e.target.value });

  };
  useEffect(() => {
    if (!registrationOption) {
      setRegistrationOption("vat_number")
      setFormData({ ...formData, registrationOption: "vat_number" });

    }
  }, [])

  useEffect(() => {
    if (business?.passport_id) {
      setRegistrationOption("passport_id")
      setTextFieldValue(business?.passport_id)
      setIsDisabled(true)
    } else if (business?.utr_number) {
      setRegistrationOption("utr_number");
      setTextFieldValue(business?.utr_number)
      setIsDisabled(true)

    } else if (business?.vat_number) {
      setRegistrationOption("vat_number");
      setTextFieldValue(business?.vat_number)
      setIsDisabled(true)

    } else if (business?.national_insurance_number) {
      setTextFieldValue(business?.national_insurance_number)
      setRegistrationOption("national_insurance_number");
      setIsDisabled(true)

    }
  }, [business]);



  const checkForTaxId = () => {
    if (!textFieldValue) {
      setErrors({ general: `${textFieldLabel}` });
      return false;
    } else if (textFieldValue.length > 20) {
      setErrors({ general: "Maximum length is 20" });
      return false;
    }
    dispatch({
      type: "SET_HMRC_INFORMATION",
      payload: {},
    });

    setTextFieldValue(textFieldValue);
    if (textFieldName == "vat_number") {
      dispatch(getHMRCInformation(textFieldValue));

    }


  };

  useEffect(() => {
    if (registrationOption === "vat_number" && textFieldValue) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      setSearchTimeout(
        setTimeout(() => {
          getHMRCInformation(textFieldValue)
        }, 1000)
      );
    }

  }, [textFieldValue])

  const getHMRCInformation = async (textFieldValue) => {
    try {
      setIsHmrcLoading(true)
      setHmrcResult({})
      let response = await axios.get(`${API_BASE}/v1/account/getHMRCInformation`, {
        params: {
          vat_number: textFieldValue,
        },
      });

      setHmrcResult(response.data)
      setHmrcError(false)
      setIsHmrcLoading(false)


    } catch (error) {
      setHmrcError(true)
      setIsHmrcLoading(false)
    }
  }

  const displayHmrcResult = () => {
    return (
      <>
        <Typography>
          Company Name:
          <span style={{ color: "green" }}>{hmrcResult.target.name}</span>
        </Typography>

        <Typography>
          Address:{" "}
          <span style={{ color: "green" }}>
            {hmrcResult?.target?.address?.line1} {hmrcResult?.target?.address?.line2}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "green" }}></span>
        </Typography>
        <Typography>
          Postal Code: <span style={{ color: "green" }}>{hmrcResult?.target?.address?.postcode} </span>
        </Typography>
      </>
    );
  };

  const renderChooseVatOrUtrSelection = () => {
    return (
      <form>
        <div>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}>Crate account with: </strong>
          </Typography>
          <div>
            <input id="vat_number" name="businessType" value="vat_number" checked={registrationOption === "vat_number"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="vat_number">Vat registration number</label>
          </div>
          {/* {registrationOption === "vat_number" && renderTextField()} */}
          {/* <div>
            <input id="passport_id" name="businessType" value="passport_id" checked={registrationOption === "passport_id"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="passport_id">Passport Number</label>
          </div> */}

          {/* {registrationOption === "passport_id" && renderTextField()} */}
          {/* <div>
            <input
              id="national_insurance_number"
              name="businessType"
              value="national_insurance_number"
              checked={registrationOption === "national_insurance_number"}
              type="radio"
              onChange={(e) => handleRadioChange(e)}
            />
            <label for="national_insurance_number">National Insurance Number</label>
          </div> */}
          {/* {registrationOption === "national_insurance_number" && renderTextField()} */}

          <div>
            <input id="utr_number" name="businessType" value="utr_number" checked={registrationOption === "utr_number"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="utr_number">TAX ID</label>
          </div>
          {/* {registrationOption === "utr_number" && renderTextField()} */}

        </div>
      </form>
    );
  };

  const renderTextField = () => {
    return (
      <div style={{ marginTop: "30px" }}>
        <TextField
          required
          variant="outlined"
          id={textFieldName}
          name={textFieldName}
          label={textFieldLabel}
          type="text"
          InputLabelProps={{ shrink: true }}
          value={formData[textFieldName]}

          onChange={(e) => { setTextFieldValue(e.target.value); setFormData({ ...formData, [textFieldName]: e.target.value }) }}
          inputProps={{ maxLength: 20 }}
          disabled={isTextFieldDisabled}
          fullWidth
        />

        {!hrmcError && hmrcResult?.target && <Typography style={{ color: "green" }}>Verified Vat Number</Typography>}

        {/* {!hrmcError && !hmrcResult?.target && hmrText && <Typography>Getting information</Typography>} */}
        {/* {hrmcError && registrationOption === "vat_number" && <Typography style={{ color: "red" }}>Could not verify</Typography>} */}



      </div>
    )
  }
  return (
    <form >
      <Grid container spacing={2} style={{ marginTop: "30px" }}>
        <Grid item xs={12} lg={12} md={12}>
          {renderChooseVatOrUtrSelection()}
        </Grid>

        <Grid item xs={12} lg={5} md={5}>
          {renderTextField()}
        </Grid>
        <Grid item xs={12} lg={6} md={6}>
          {!hrmcError && hmrcResult?.target && displayHmrcResult()}
        </Grid>
      </Grid>
    </form>
  );
}

export default VerifySoleTraderWithVat;
