import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DropdownComponent from "../../../Components/DropdownComponent";
import { usePermissionsContext } from "../../../Contexts/PermissionsContext";
import { useUiContext } from "../../../Contexts/UiContext";
import { getSelfBankAccounts, getUserRemainingBalance } from "../../../store/actions/bankingActions";
import { getMergedAccounts } from "../../../store/actions/businessActions";
import { approveInvoice, downloadInvoices, getIncomingInvoices, removeIncomingInvoices } from "../../../store/actions/invoiceActions";
import InvoicesListComponent from "../InvoicesListComponent";
import IncomingInvoicesFooter from "./IncomingInvoicesFooter";
import IncomingInvoicesToolbar from "./IncomingInvoicesToolbar";
import MakePaymentDialog from "../MakePaymentDialog";
import BasePage from "../../../Components/BasePage";
import { getSuppliers } from "../../../store/actions/supplierActions";
import { createSelector } from "reselect";

const statuses = [
  {
    value: "Awaiting Payment",
    label: "Awaiting Payment",
  },
  {
    value: "Pending Approval",
    label: "Pending Approval",
  },
  { value: "Paid", label: "Paid" },
];
const selectSuppliers = createSelector(
  (state) => ({
    ...state,
  }),
  (state) => state.supplier
);
const IncomingInvoices = ({ businessId, finances, availableInstitutions, makePayment }) => {
  const { setErrors, errors, clearErrors } = useUiContext();
  const { control } = useForm();

  const user = useSelector((state) => state.user);
  let business = useSelector((state) => state.business);

  const dispatch = useDispatch();

  const { permissions } = usePermissionsContext();
  const [selected, setSelected] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState("all_suppliers");
  const [selectedStatus, setSelectedStatus] = useState("Awaiting Payment");

  const [currentPage, setCurrentPage] = useState(1);
  const [shouldPaginateRedis, setShouldPaginateRedis] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [prevInterval, setPrevInternal] = useState(null);
  const [shouldGetData, setShouldGetData] = useState(true);
  const [concatData, setConcatData] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);
  const [makePaymentDialogOpen, setMakePaymentDialogOpen] = useState(false);
  const [afterRender, setAfterRender] = useState(false);
  const [triggerGetData, setTriggerGetData] = useState(0);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const suppliers = useSelector((state) => selectSuppliers(state));

  const incomingInvoices = useSelector((state) => state.invoice.incomingInvoices);


  const isLoading = useSelector((state) => state.invoice.isLoading);
  const mergedAccounts = useSelector((state) => state.business.mergedAccts);


  useEffect(() => {
    if (makePaymentDialogOpen) {
      for (let i = 0; i <= prevInterval; i++) {
        clearInterval(i);
      }
    }
  }, [makePaymentDialogOpen]);


  const getSupplierList = () => {
    let lists = [];
    lists.push({ value: "all_suppliers", label: "All Suppliers" });
    if (suppliers?.suppliers?.data && suppliers?.suppliers?.data.length > 0) {
      suppliers &&
        suppliers.suppliers.data.forEach((sup) => {
          lists.push({ value: sup.id, label: sup.business_name });
        });
    }
    return lists;
  };

  useEffect(() => {
    dispatch(getSuppliers({}));
  }, [])

  const applyInvoiceDownloadFilter = (startDate, endDate) => {
    let data = {
      start_date: startDate,
      end_date: endDate,
      supplier_id: selectedSupplier === "all_suppliers" ? null : selectedSupplier,
      invoice_type: 1,
    }
    console.log(data);
    dispatch(downloadInvoices(data))
  }
  const renderSupplierSelectionDropbox = () => {
    return (
      <>
        <DropdownComponent
          required
          fullWidth
          id="selectedInformation"
          name="selectedInformation"
          label="Select a suppplier"
          items={getSupplierList()}
          onChange={(e) => {
            setSelectedSupplier(e.target.value);
            setTriggerGetData(triggerGetData + 1);

          }}
          error={errors.selectedInformation ? true : false}
          helperText={errors.selectedInformation ?? ""}
          control={control}
          defaultValue={selectedSupplier}
        />
      </>
    );
  };

  const renderStatusSelectionDropbox = () => {
    return (
      <>
        <DropdownComponent
          required
          fullWidth
          id="selectedStatus"
          name="selectedStatus"
          label="Select a status"
          items={statuses}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
            setTriggerGetData(triggerGetData + 1);

          }}
          error={errors.selectedStatus ? true : false}
          helperText={errors.selectedStatus ?? ""}
          control={control}
          defaultValue="Awaiting Payment"
        />
      </>
    );
  };

  const renderTableToolbar = () => {
    return (
      <IncomingInvoicesToolbar
        numSelected={selected ? selected.length : 0}
        header="Incoming Invoices"
        setDeleteDialogOpen={setDeleteDialogOpen}
        selectedInvoice={selected}
        suppliers={suppliers}
        permissions={permissions}
        setSelectedInvoices={setSelected}
        errors={errors}
        setErrors={setErrors}
        clearErrors={clearErrors}
        availableInstitutions={availableInstitutions}
        makePayment={makePayment}
        renderSupplierSelection={renderSupplierSelectionDropbox()}
        renderStatusSelection={renderStatusSelectionDropbox()}
        getIncomingInvoices={getIncomingInvoices}
        dispatch={dispatch}
        setCurrentPage={setCurrentPage}
        makePaymentDialogOpen={makePaymentDialogOpen}
        setMakePaymentDialogOpen={setMakePaymentDialogOpen}
        setOpenFilterDialog={setOpenFilterDialog}
        openFilterDialog={openFilterDialog}
        applyInvoiceDownloadFilter={applyInvoiceDownloadFilter}
      />
    );
  };

  const renderFooter = () => {
    return (
      <IncomingInvoicesFooter
        selected={selected}
        setSelected={setSelected}
        businessId={businessId}
        permissions={permissions}
        setErrors={setErrors}
        updateInvoiceStatus={approveInvoice}
        selectedSupplier={selectedSupplier && selectedSupplier.split("/")[1]}
        finances={finances}
        setDownloadPDF={setDownloadPDF}
        downloadPDF={downloadPDF}
        dispatch={dispatch}
      />
    );
  };

  const renderList = () => {
    return (
      <>
        <InvoicesListComponent
          toolbar={renderTableToolbar()}
          title="incoming"
          invoices={incomingInvoices?.data || []}
          header="Incoming Invoices"
          businessId={businessId}
          selectedFieldId={selectedSupplier}
          removeInvoices={removeIncomingInvoices}
          footer={renderFooter()}
          selected={selected}
          setSelected={setSelected}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          getAllData={getIncomingInvoices}
          dispatch={dispatch}
          setErrors={setErrors}
          shouldView={permissions?.incomingInvoice?.view}
          shouldGetData={true}
          makePaymentDialogOpen={makePaymentDialogOpen}
          setMakePaymentDialogOpen={setMakePaymentDialogOpen}
          triggerGetData={triggerGetData}
          getDataParams={{ selectedStatus: selectedStatus, selectedSupplier: selectedSupplier }}


        />
      </>
    );
  };

  return (
    <BasePage navigation="Dashboard/Incoming Invoices" business={business} canView={user?.permissions?.incomingInvoice?.view}>
      {renderList()}

      {makePaymentDialogOpen && (
        <MakePaymentDialog
          open={makePaymentDialogOpen}
          setOpen={setMakePaymentDialogOpen}
          selectedInvoices={selected}
          suppliers={suppliers}
          errors={errors}
          setErrors={setErrors}
          clearErrors={clearErrors}
          availableInstitutions={availableInstitutions}
          submitPayment={makePayment}
        //   // handleSubmit={handlePaymentSubmit}
        //   // handleCancel={() => setPaymentDialogOpen(false)}
        />
      )}
    </BasePage>
  );
};

export default IncomingInvoices;
