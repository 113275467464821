import React from "react";
import { TableBody, TableRow, TableCell, Checkbox, Button, Link } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { MdOutlinePending } from "react-icons/md";

const TableBodyComponent = ({ data, headCells, classes, page, rowsPerPage, selected, setSelected, businessId, showDetailsAction, showPDFAction, shouldGetData }) => {
  const isSelected = (data) => {
    return selected.filter((x) => x.id === data.id).length > 0;
  };

  const handleRowSelect = (event, data) => {
    const selectedIndex = selected.map((item) => item.id).indexOf(data.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const renderTableCells = (row) => {
    let cells = [];
    headCells.forEach((header) => {
      for (const [key, value] of Object.entries(row)) {
        if (header.id === key) {
          //special case for when taxIdNo isn't provided but erp_no is

          if (key === "request_ids") {
            let numberOfInvoices = row.request_ids.length;
            cells.push(<TableCell>{numberOfInvoices}</TableCell>);
            continue;
          }
          if (key === "taxIdNo" && !value && !!row.erp_no) {
            cells.push(<TableCell>{row.erp_no}</TableCell>);
            continue;
          }

          //special case for rendering bool value depending on who added the invoice
          else if (key === "addedBy") {
            if (row.addedBy === businessId) cells.push(<TableCell style={{ color: "green", fontWeight: "bold" }}>YES</TableCell>);
            else {
              cells.push(<TableCell style={{ color: "red", fontWeight: "bold" }}>NO</TableCell>);
            }
            continue;
          } else if (key === "editDate" || key === "dueDate") {
            cells.push(<TableCell>{value}</TableCell>);
            continue;
          } else if (key === "created_at") {
            const timeStampDate = value;
            const dateInMillis = timeStampDate._seconds * 1000;
            var date = new Date(dateInMillis).toDateString();
            cells.push(<TableCell>{date}</TableCell>);
            continue;
          } else if (key === "createdAt") {
            var date = new Date(value);
            console.log("normal date", date);
            var localDate = date.toLocaleString();
            console.log("local dateee", localDate);
            cells.push(<TableCell>{localDate}</TableCell>);
            continue;
          } else if (key === "bid_opens_at" || key === "bid_closes_at") {
            new Date(row?.bid_opens_at).toDateString();
            cells.push(<TableCell>{new Date(row?.bid_opens_at || row?.bid_closes_at).toDateString()}</TableCell>);
            continue;
          } else if (key === "timestamp") {
            const timeStampDate = value;
            var date = new Date(timeStampDate).toUTCString();
            cells.push(<TableCell>{date}</TableCell>);
            continue;
          } else if (key === "message_sent_at") {
            const timeStampDate = value;
            if (!value) {
              cells.push(<TableCell>Not sent</TableCell>);
              continue;
            }
            const dateInMillis = timeStampDate._seconds * 1000;
            var date = new Date(dateInMillis).toDateString();
            cells.push(
              <TableCell>
                {date && (
                  <>
                    {date}
                    <br />
                  </>
                )}
                {!value && <>Not sent</>}
              </TableCell>
            );
            continue;
          } else if (key === "last_status") {
            cells.push(
              <TableCell>
                {value === "Submitted" && (
                  <>
                    <MdOutlinePending size={25} color="#FFC600" />
                    Pending
                  </>
                )}
                {value === "Executed" && (
                  <>
                    <CheckCircleIcon size={25} style={{ color: "#2CA62A" }} />
                    Done
                  </>
                )}

                {value === "New" && <>New</>}
              </TableCell>
            );
            continue;
          } else if (key === "bank_statement") {
            cells.push(
              <TableCell>
                {" "}
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(value);
                  }}
                >
                  Download the bank statement
                </Link>
              </TableCell>
            );
            continue;
          }

          if (header.id === "isActive") {
            if (!value) {
              cells.push(<TableCell>Not Active</TableCell>);
            } else {
              cells.push(<TableCell>Active</TableCell>);
            }
          }

          if (header.id === "invite_information") {
            let inviteCode = value.invitation_code;
            cells.push(
              <TableCell>
                {inviteCode ? "Invited" : "Not invited"}
                <br />
                <strong>{inviteCode && inviteCode}</strong>
                <br></br>
                <small>{inviteCode && value.isActive && "(Not Joined)"}</small>
              </TableCell>
            );
            continue;
          } else if (header.id === "invite_code" && !value) {
            cells.push(<TableCell>Not Invited</TableCell>);
            continue;
          } else if (key === "serial") {
            if (row.manual === true) {
              cells.push(
                <TableCell>
                  <CheckCircleIcon color="secondary" /> {value}
                </TableCell>
              );
            } else {
              cells.push(<TableCell>{value}</TableCell>);
            }
            continue;
          } else if (key === "read") {
            if (row.read === true) {
              cells.push(
                <TableCell>
                  <CheckCircleIcon color="secondary" />
                </TableCell>
              );
            } else {
              cells.push(
                <TableCell>
                  <CancelIcon color="primary" />
                </TableCell>
              );
            }
            continue;
          } else if (key == "factoring_status") {
            if (row.factoring_status === "Factoring Requested") {
              cells.push(
                <>
                  <TableCell>
                    <>
                      Factoring Requested
                      <br />
                      Bid Opens at: {new Date(row?.bid_opens_at).toDateString()}
                      <br />
                      Bid Closes at : {new Date(row?.bid_closes_at).toDateString()}
                    </>
                  </TableCell>
                </>
              );
            } else if (row.factoring_status === "Offer Accepted") {
              cells.push(
                <>
                  <TableCell>
                    <>
                      Offer Accepted <br />
                      Accepted at: {new Date(row?.updatedAt).toDateString()}
                    </>
                  </TableCell>
                </>
              );
            } else {
              cells.push(<TableCell>{value}</TableCell>);
            }
            continue;
          }

          cells.push(<TableCell>{value}</TableCell>);
        }
      }

      if (typeof row.invoice_lines === "string") {
        row.invoice_lines = [];
      }

      if (header.id === "allStatusDetails") {
        let statusDet = row.statusDetails;
        let paidAt = row.paid_at;
        let receiptId = statusDet.receipt_id;
        console.log("received status", row);
        cells.push(
          <>
            <TableCell>
              {statusDet.status}
              <br />
              {receiptId && "Receipt Ref: " + receiptId}
            </TableCell>
          </>
        );
      }
      // if (header.id === "statusDet") {
      //   let statusDet = row.statusDet;
      //   cells.push(
      //     <>
      //       <TableCell>
      //         {statusDet === "Factoring Requested" ? (
      //           <>
      //             {" "}
      //             <br />
      //             Bid Opens at: {row.bid_open_date}
      //             <br />
      //             Bid Closes at : {row.bid_close_date}
      //           </>
      //         ) : (
      //           statusDet
      //         )}
      //       </TableCell>
      //     </>
      //   );
      // }
      const hasDetails = row.invoice_image;
      if (header.id === "invoiceDetails") {
        cells.push(
          <>
            <TableCell>
              <Button onClick={() => showDetailsAction(row)}>Show</Button>
            </TableCell>
          </>
        );
      } else if (header.id === "invoiceDetails" && !hasDetails) {
        cells.push(
          <>
            <TableCell style={{ color: "red" }}>No Image</TableCell>
          </>
        );
      }
    });
    return cells;
  };

  return (
    <>
      <TableBody>
        {data &&
          data.length > 0 &&
          (!shouldGetData ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map((row, index) => {
            const isItemSelected = isSelected(row);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={index} selected={isItemSelected}>
                <TableCell padding="checkbox">
                  <Checkbox onClick={(event) => handleRowSelect(event, row)} checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                </TableCell>
                {renderTableCells(row).map((cell) => cell)}
              </TableRow>
            );
          })}
      </TableBody>
    </>
  );
};

export default TableBodyComponent;
