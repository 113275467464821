import React, { useState, useEffect } from "react";
import { Button, Divider, Paper, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { makePaymentRequest } from "../../store/actions/bankingActions";
import { useUiContext } from "./../../Contexts/UiContext";
import { API_BASE } from "./../../store/env";
import { useCheckoutStyles } from "./InvoiceStyles";

const Checkout = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
  });
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { register, setValue, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const { errors, setErrors } = useUiContext();
  const classes = useCheckoutStyles();
  const location = useLocation();

  const handlePayment = () => {
    if (customerData.name === "" || customerData.email === "") {
      setErrorMessage("Please enter your fullname and email address");
      return;
    } else {
      let dataToSend = {
        user: {
          name: customerData.name,
          email: customerData.email,
        },
        invoices: [invoiceDetails.id],
      };
      setErrorMessage("");
      console.log("dataToSend", dataToSend);
      dispatch(makePaymentRequest(dataToSend));
    }
  };
  const getInvoicePdf = async () => {
    try {
      var id = new URLSearchParams(location.search).get("id");

      let request = await axios.get(`${API_BASE}/v1/invoice/generate/${id}`);
    } catch (e) {
      setHasError(true);
      setErrors({ general: "Wrong link submitted" });
    }
  };
  const getInvoiceDetails = async (invoiceId) => {
    try {
      setLoading(true);
      let request = await axios.get(`${API_BASE}/v1/single-invoice/${invoiceId}`);
      if (request.status === 200) {
        let invoice = request.data;
        setInvoiceDetails({ ...invoice.data, id: invoiceId });
        setCustomerData({ name: invoice?.data?.customer_name || "", email: invoice?.data?.customer_email || "" });
        return invoice;
      } else if (request.status === 400) {
        setHasError(true);
        setErrors({ general: "Invoice is already paid" });
        return null;
      } else {
        setHasError(true);
        setErrors({ general: "Wrong link submitted" });
        return null;
      }
    } catch (e) {
      setHasError(true);
      setErrors({ general: "Wrong link submitted" });
    }
  };

  useEffect(() => {
    setLoading(true);

    var id = new URLSearchParams(location.search).get("id");
    setInvoiceId(id);
    (async () => {
      if (id) {
        var invoice = await getInvoiceDetails(id);
      }
    })();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (invoiceDetails) {
      setLoading(false);
    }
  }, [invoiceDetails]);

  let paymentRequest = useSelector((state) => state.banking.paymentRequest);

  useEffect(() => {
    dispatch({
      type: "PAYMENT_REQUEST_FETCH_SUCCESS",
      payload: {},
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paymentRequest) {
      if (paymentRequest.payment_uri) {
        setTimeout(function() {
          window.location.href = paymentRequest.payment_uri;
        }, 1000);
      }
    }
  }, [paymentRequest]);

  return loading ? (
    "loading"
  ) : (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={5}>
        {hasError ? (
          <Typography variant="h4">Error while making a payment</Typography>
        ) : (
          <>
            <Typography variant="h4">Make a Payment</Typography>
            <br />
            <Typography variant="subtitle2">Invoice Id: {invoiceDetails && invoiceDetails.id}</Typography>
            <br />
            <small>You are paying to: </small>
            <Typography variant="overline" component="p">
              {invoiceDetails?.supplier_name}
            </Typography>
            <br />

            <a style={{ textAlign: "center" }} target="_blank" rel="noopener noreferrer" href={`${API_BASE}/v1/invoice/generate/${invoiceId}`}>
              Click to see invoice details
            </a>
            <br />
            <br />

            <Divider />
            <Typography variant="h6" style={{ marginTop: "15px" }}>
              Total to pay: GBP {invoiceDetails && invoiceDetails.amount}
            </Typography>
            <br />
            {errorMessage !== "" && (
              <>
                <Typography variant="overline" component="p" color="secondary">
                  {errorMessage}
                </Typography>
              </>
            )}
            <TextField
              label="Name"
              variant="outlined"
              value={customerData.name}
              onChange={(e) => setCustomerData({ ...customerData, name: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              variant="outlined"
              value={customerData.email}
              onChange={(e) => setCustomerData({ ...customerData, email: e.target.value })}
              fullWidth
              margin="normal"
            />
            <br />
            <Button onClick={handlePayment} variant="contained" color="primary" fullWidth={true} disabled={hasError}>
              Pay
            </Button>
          </>
        )}
      </Paper>
    </div>
  );
};

export default Checkout;
