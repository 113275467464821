import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { getPendingApprovalBusinesses, verifyBusiness } from "../../../store/actions/adminActions";
import BusinessesToolbar from "./BusinessesToolbar";
import BusinessFilesDialog from "./BusinessFilesDialog";
import PendingApprovalBusinessesTable from "./PendingApprovalBusinessesTable";

const PendingApprovalBusinesses = ({ business, history }) => {
  const pendingApprovalBusinesses = useSelector((state) => state.admin.pendingApprovalBusinesses);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [businessFilesDialogOpen, setBusinessFilesDialogOpen] = useState(false);



  const handleVerifyBusiness = (e) => {
    if (selected.length === 1) {
      var details = { account_id: selected[0].id, team_type: "Business" };
      dispatch(verifyBusiness(details, history));
      setSelected([]);
    }

  };

  const renderToolbar = () => {
    return (
      <BusinessesToolbar
        numSelected={selected ? selected.length : 0}
        header="Pending Approval Businesses"
        setBusinessFilesDialogOpen={setBusinessFilesDialogOpen}
        selected={selected[0]}
        handleVerifyBusiness={handleVerifyBusiness}
      />
    );
  };

  return (
    <BasePage navigation="Dashboard/Pending-Approval-Businesses" business={business} canView={true}>
      <div>
        <PendingApprovalBusinessesTable
          toolbar={renderToolbar()}
          businessesToApprove={pendingApprovalBusinesses.data}
          selected={selected}
          setSelected={setSelected}
          getAllData={getPendingApprovalBusinesses}
          numberOfPages={pendingApprovalBusinesses?.number_of_page || 0}

        />

        {selected.length > 0 && (
          <BusinessFilesDialog open={businessFilesDialogOpen} selected={selected[0]} setSelected={setSelected} setOpen={setBusinessFilesDialogOpen} business={selected[0]} />
        )}
      </div>
    </BasePage>
  );
};

export default PendingApprovalBusinesses;
